import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SearchList({
  path = "/vachana",
  id = "",
  title = "",
  content = "",
  author = "",
  totalVachanas = 0,
  image = "",
  className = "lg:w-[500px] w-[3/4]",
  toRefer = "vachanas",
  searchTerm = "",
  children,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const commonContext = t("common");

  // Function to highlight search terms
//   const highlightText = (text, term) => {
//     if (!term) return text;
//     const regex = new RegExp(`(${term})`, "gi");
//     return text.split(regex).map((part, index) =>
    
//       regex.test(part) ? (
//         <span key={index} className="bg-Secoundary bg-opacity-30 font-bold">
//           {part}
//         </span>
//       ) : (
//         part
//       )
//     );
//   };
const highlightText = (text, term) => {
    if (!term) return text;   
  
    const regex = new RegExp(`(${term})`, "gi");
    const termPosition = text.search(regex);
    const tempTextStart = parseInt(termPosition/100)*100
    const tempLen = text.slice(tempTextStart).length
    
    const textStartAt = tempLen>100? tempTextStart: (tempTextStart - 100 + tempLen )>0? tempTextStart - 100 + tempLen:0
        
    return `${textStartAt?'...':''}${text.slice(textStartAt).split(' ').slice(textStartAt?1:0).join(' ')}`.split(regex).map((part, index) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <span
          key={index}
          className="bg-Secoundary bg-opacity-30 font-bold text-Primary"
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };
  

  return (
    <div className={`flex ${className}`}>
      <div
        onClick={() => {
          navigate(`${path}/${id}?ref=${toRefer}`);
        }}
        className="flex flex-col gap-3 w-full max-sm:w-full cursor-pointer"
      >
        {/* Highlighted and truncated content */}
        <div className="line-clamp-3 font-dmSans text-base font-normal">
          {highlightText(content, searchTerm)}
        </div>

        <div className="flex items-center justify-between">
          <ul className="flex flex-row items-center gap-2">
            <li className="flex justify-center items-center gap-x-2">
              <img
                src={image}
                alt={author}
                className="w-6 h-6 bg-Surface rounded-full"
              />
              <h3 className="font-dmSans font-medium text-sm">{author}</h3>
            </li>
            <li className="font-dmSans font-medium text-sm min-w-24">
              {`${totalVachanas} ${commonContext.vachanas}`}{" "}
            </li>
          </ul>
        </div>
      </div>
      {children}
    </div>
  );
}

export default SearchList;
