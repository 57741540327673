import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import Selection from "../views/Selection";
import Vachanas from "../views/Vachanas";
import Login from "../views/Login";
import Vachana from "../views/Vachana";
import Error404 from "../views/404";
import Profile from "../views/Profile";
import Sharanas from "../views/Sharanas";
import Sharana from "../views/Sharana";
import SearchScreen from "../views/SearchScreen";
import Meditation from "../views/Meditation";
import MeditationActivity from "../views/MeditationActivity";
import SuccessScreen from "../views/SuccessScreen";
import LineChart from "../components/LineChart";
import MeditationStats from "../views/MeditationStats";
import Music from "../views/Music";
import Home from "../views/Home";
import PickSharanas from "../views/PickSharanas";
import VachanaAudio from "../views/VachanaAudio";
import EditProfile from "../views/EditProfile";
import Music24x7 from "../views/Music24x7";
import Bookmarked from "../views/Bookmarked";
import CreatePlayList from "../views/CreatePlayList";
import PlayList from "../views/PlayList";
import "react-toastify/dist/ReactToastify.css"; // Import CSS
import Dictionary from "../views/Dictionary";
import Landing from "../views/Landing";
import ProtectedRoute from "./ProtectedRoute";
import GBHeader from "../components/GBHeader";
import LanguageChange from "../components/LanguageChange";
import useUserStore from "../store/UserStore";
import { useTranslation } from "react-i18next";
import useCommonStore from "../store/CommonStore";
import Settings from "../components/settings";
import SearchMusic from "../components/SearchMusic";
import ComingSoon from "../views/comingSoon";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    useUserStore((state) => state.isAuthenticated)
  );
  const auth = getAuth();

  useEffect(() => {
    const handleOnline = () => console.log("online");
    const handleOffline = () => console.log("offline");

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });
  }, [auth]);

  const UserformCheck = () => {
    const { user } = useUserStore();
    const { i18n } = useTranslation();
    const { getAllLanguages } = useCommonStore((state) => state);

    useEffect(() => {
      getAllLanguages();
      if (user?.primary_language) {
        i18n.changeLanguage(user?.primary_language);
      }
    }, []);

    if (user?.primary_language) {
      return <GBHeader component={<Home />} />;
    } else {
      return <Selection />;
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: isAuthenticated ? <UserformCheck /> : <Login />,
      index: true,
    },
    {
      element: <ProtectedRoute isAuthenticated={isAuthenticated} />,
      children: [
        { path: "home", element: <GBHeader component={<Home />} /> },
        {
          path: "languageChange",
          element: <GBHeader component={ <LanguageChange />} />,
        },
        { path: "vachanas", element: <GBHeader component={<Vachanas />} /> },
        {
          path: "editProfile",
          element: <GBHeader component={<EditProfile />} />,
        },
        { path: "vachana/:id", element: <GBHeader component={<Vachana />} /> },
        { path: "sharanas", element: <GBHeader component={<Sharanas />} /> },
        {
          path: "pickSharanas",
          element: <PickSharanas />,
        },
        { path: "sharana/:id", element: <GBHeader component={<Sharana />} /> },
        { path: "search", element: <GBHeader component={<SearchScreen />} /> },
        {
          path: "meditation",
          element: <GBHeader component={<ComingSoon />} />,
        },
        {
          path: "meditationActivity",
          element: <GBHeader component={<MeditationActivity />} />,
        },
        {
          path: "success",
          element: <GBHeader component={<SuccessScreen />} />,
        },
        {
          path: "stats",
          element: <GBHeader component={<ComingSoon />} />,
        },
        { path: "music", element: <GBHeader component={<Music />} /> },
        { path: "musicSearch", element: <GBHeader component={<SearchMusic/>} /> },
        {
          path: "audioVachana",
          element: <GBHeader component={<VachanaAudio />} />,
        },
        { path: "music24x7", element: <GBHeader component={<Music24x7 />} /> },
        {
          path: "bookmarked",
          element: <GBHeader component={<Bookmarked />} />,
        },
        {
          path: "createPlaylist",
          element: <GBHeader component={<CreatePlayList />} />,
        },
        { path: "playlist", element: <GBHeader component={<PlayList />} /> },
        {
          path: "dictionary",
          element: <GBHeader component={<Dictionary />} />,
        },
        { path: "test", element: <GBHeader component={<LineChart />} /> },
        { path: "settings", element: <GBHeader component={<Settings />} /> },
        { path: "profile", element: <GBHeader component={<Profile />} /> },
      ],
    },
    { path: "*", element: <Error404 /> },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
