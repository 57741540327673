import React from 'react'
import GBInput from '../components/GBInput';
import GBButton from '../components/GBButton';

function CreatePlayList() {
    const handlerChange = (event) => {
        console.log(event.target.value);
        
    }
  return (
    <div className='flex flex-col gap-y-3 absolute sm:static bg-Surface h-full w-full'>
      <div className='p-8 py-4 gap-4 flex flex-col w-full'>               
          <h1 className='text-2xl leading-[38px] font-denton font-medium'>{
          // sharanasObj.A[0].author
          'Give playlist name'
          }</h1>                                
      </div>
      <div className='px-7 flex-grow'>

        <GBInput className='w-full px-[22px] py-4 bg-white rounded-full focus:ring-1 focus:ring-Primary shadow-md' placeholder='Name' name='name' handleChange={handlerChange}/>
      </div>
      <div className='flex justify-between w-full p-8 '>
        <GBButton value='' className='px-4 py-[14px] bg-Surface rounded-full text-Primary font-dmSans font-medium text-sm'>
            Cancel
        </GBButton>
        <GBButton value='' className='py-4 px-12 bg-Primary rounded-full text-Surface font-dmSans font-medium text-sm'>
            Create
        </GBButton>

      </div>
    </div>
  )
}

export default CreatePlayList;
