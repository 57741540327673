import React, { useState } from "react";
import { db } from "../Firebase";
import { collection, getDocs } from "firebase/firestore";
import { IoSearch, IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import logo1 from "../assets/images/svg/logo1.svg";
import useAudioStore from "../store/audioStore";
import { IoClose } from "react-icons/io5";

const SearchMusic = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const navigate = useNavigate();
  const {
    getAllAlbums,
    getAudiosByType,
    setCurrentAudio,
    updateAudioState,
    setIsPlaying,
    setSongList
  } = useAudioStore()
  const isPlaying = useAudioStore((state) => state.isPlaying);
  const playbackAudio = useAudioStore((state) => state.currentAudio);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);

    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      const albumsRef = collection(db, "albums");
      const albumsSnapshot = await getDocs(albumsRef);

      let matchedResults = [];

      // Loop through each album's audios
      for (const albumDoc of albumsSnapshot.docs) {
        const audiosRef = collection(db, `albums/${albumDoc.id}/audios`);
        const audioSnapshot = await getDocs(audiosRef);

        audioSnapshot.docs.forEach((audioDoc) => {
          const audioData = audioDoc.data();

          // Search logic
          if (
            audioData?.name?.toLowerCase().includes(query.toLowerCase()) ||
            audioData?.artist?.toLowerCase().includes(query.toLowerCase())
          ) {
            matchedResults.push({
              id: audioDoc.id,
              ...audioData,
            });
          }
        });
      }

      setSearchResults(matchedResults);
    } catch (error) {
      console.error("Error fetching data from Firebase:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setSearchResults([]);
    setSearchInitiated(false);
  };

  const handleSongClick = (item) => {
    if (playbackAudio?.id === item.id) {
      setCurrentAudio(item);
      setIsPlaying(!isPlaying);
    } else {
      setCurrentAudio(item);
      setIsPlaying(true);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh)] lg:w-[496px] overflow-y-auto scrollbar-hide">
      {/* Search Bar UI */}
      <div className="pb-4 px-2 flex-1">
        <div className="flex pt-4 items-center gap-0.5 mb-6 bg-Surface  border-b border-gray-200 pb-2">
          <button
            onClick={handleBack}
            className="p-2 rounded-full hover:bg-gray-100"
          >
            <IoArrowBack className="text-2xl text-[#0B6062]" />
          </button>
          <div className="relative flex-1 justify-between">
            <input
              type="text"
              placeholder="Search for songs or artist"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchInitiated(true);
                  handleSearch(searchQuery);
                }
              }}
              className="w-full p-3 pl-12 rounded-3xl border text-sm border-gray-300 focus:outline-none focus:border-[#0B6062]"
            />
            <IoSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
            {searchInitiated && (
              <IoClose
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl cursor-pointer"
                onClick={handleClearSearch}
              />
            )}
          </div>
        </div>
        <div className="ps-4">
          {!searchInitiated ? (
            <h3 className="text-xs font-bold font-dmSans">Please input test to search</h3>
          ) : (
            <>
              <h3 className="text-xs font-bold font-dmSans">RESULTS FOR YOU</h3>
              <h2 className="text-xl font-medium font-denton ">{searchResults.length} Musics</h2>
            </>
          )}
        </div>

        <div className="space-y-4 lg:pb-20 t-6 overflow-auto h-[calc(100vh-160px)] scrollbar-hide">
          {loading ? (
            <p className="text-center text-gray-500">Loading...</p>
          ) : searchResults.length > 0 ? (
            searchResults.map((song) => (
              <div
                key={song.id}
                onClick={() => handleSongClick(song)}
                className="flex items-center p-4 bg-surface  cursor-pointer hover:bg-gray-50 border-b border-gray-200"
              >
                <img
                  src={song.albumArt || logo1}
                  alt={song.name}
                  className="h-10 w-10 mr-4 rounded-md"
                />
                <div className="flex-1 min-w-0">
                  <h3 className="font-medium truncate">{song.name}</h3>
                  <p className="text-sm text-gray-600 truncate">{song.artist}</p>
                </div>
              </div>
            ))
          ) : (
            searchInitiated && (
              <p className="text-center text-gray-500">No songs found</p>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchMusic;
