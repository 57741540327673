import React from 'react';
import { map } from 'lodash';
import ImageCard from '../components/ImageCard';

function Activities({data}) {
  return (
    // <div className='flex justify-start flex-wrap w-fit gap-3 sm:gap-6 '>
    <div className='grid gap-3 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 sm:gap-4 md:gap-5 lg:gap-6 2xl:w-fit' >
        {
            map(data, (activity, index)=> <ImageCard key={index} {...activity} path='/meditationActivity' />)
        }
    </div>
  )
}

export default Activities
