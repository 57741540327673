import React from "react";
import { ArrowSquareRightIcon } from "../assets/icons/CustomIcons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SharanaList({ id, author, totalVachanas, image }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const commonContext = t("common");

  return (
    <div
      onClick={() => {
        navigate(`/sharana/${id}`);
      }}
      className="flex flex-col gap-2.5 max-w-[500px] w-full"
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-x-2.5">
          <img
            src={image}
            alt={author}
            className="w-9 h-9 bg-Surface rounded-full"
          />
          <div className="flex flex-col gap-x-2">
            <h3 className="font-denton font-medium text-base">
              <span className="uppercase">{author[0]}</span>
              {author.slice(1)}
            </h3>
            <p className="font-dmSans font-medium text-xs">{`${totalVachanas} ${commonContext.vachanas}`}</p>
          </div>
        </div>

        <div>
          <ArrowSquareRightIcon />
        </div>
      </div>
    </div>
  );
}

export default SharanaList;
