import React from "react";
import GBLabel from "./GBLabel";
import GBButton from "./GBButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

function TimeScore({ handleNextClick, handleBackClick }) {
  const { t } = useTranslation();
  const commonContext = t("common");
  const score = "100%";
  const mostOfScore = "25%";

  return (
    <>
      <div className="flex flex-col gap-y-24 h-full justify-center items-center">
        <div className="flex flex-col gap-2">
          <GBLabel
            {...{
              label: `Great!`,
              desc: "That’s 4 times more reading than most people.",
              styleLabel: "text-2xl font-medium font-denton w-full",
              styleDesc: "",
            }}
          />
        </div>
        <div className="flex gap-x-10 h-60 justify-center items-end">
          <div style={{ height: score }}>
            <GBButton
              {...{
                isSelected: true,
                className: `w-20 rounded-xl shadow-md h-full`,
              }}
            ></GBButton>
            <div className="flex justify-center pt-1">
              <p className="text-sm font-dmSans font-medium">You</p>
            </div>
          </div>
          <div style={{ height: mostOfScore }}>
            <GBButton
              {...{
                isSelected: false,
                className: `w-20 rounded-xl shadow-md bg-white h-full`,
              }}
            ></GBButton>
            <div className="flex justify-center pt-1">
              <p className="text-sm font-dmSans font-medium">Most People</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between">
        <GBButton
          className="flex items-center justify-center text-Primary border border-Primary px-2 py-1 rounded-full gap-1 max-sm:w-[120px] w-[149px] h-[52px]"
          handleClick={handleBackClick}
        >
          <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
            <FaArrowRightLong className="mr-1 rotate-180" />{" "}
            {commonContext.back}
          </span>
        </GBButton>

        <GBButton
          className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full gap-1 w-[149px] h-[52px]"
          handleClick={handleNextClick}
        >
          <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
            {commonContext.next} <FaArrowRightLong className="ml-1" />
          </span>
        </GBButton>
      </div>
    </>
  );
}

export default TimeScore;
