import React from 'react'
import GBButton from '../components/GBButton';
import successIcon from '../assets/images/json/successIcon.json';
import Lottie from 'lottie-react';

function SuccessScreen() {
  return (
    <div className='flex flex-col items-center text-center h-screen sm:h-full w-full bg-Surface pt-24 pb-8 gap-y-3' >
        <div className='flex items-center flex-col gap-y-9'>
            <div>
                <Lottie 
                animationData={successIcon}
                loop={true}
                style={{ width: 200, height: 200 }}
                autoPlay={true} 
                />
            </div>
            <div className='font-denton font-medium text-2xl'>
                <h2>Great!</h2>
                <h3>Session completed</h3>
            </div>
        </div>
        <div className='font-dmSans text-base tracking-[-4%]'>
            <p>Meditated time : <span>5m</span></p>
        </div>
        <div className='flex-grow sm:flex-grow-0'></div>
        <div className='flex gap-1.5 items-end max-w-[380px]  sm:max-w-[212px] w-full sm:h-28'>
            {/* {handleClick =() => {}, isSelected=false, title='', value='', className=''} */}
            <GBButton className='bg-Primary text-Surface p-4 rounded-full w-full '>
                Continue
            </GBButton>
        </div>
      
    </div>
  )
}

export default SuccessScreen;
