import React, { useState } from "react";
import closeIcon from "../assets/images/svg/closeIcon.svg";
import LanguageTool from "../components/LanguageTool";
import expandIcon from "../assets/images/svg/expandIcon.svg";
import useAuthStore from "../store/UserStore";
import useCommonStore from "../store/CommonStore";
import FontSizeSelection from "../components/FontSizeSelection";
import useVachanasStore from "../store/VachanasStore";

// import PrimaryLangDrawer from '../components/PrimaryLangDrawer';
const Drawer = ({
  drawerAction,
  isOpen = false,
  closeHandler,
  kannadaVachana,
}) => {
  const { user } = useAuthStore();
  const { languages } = useCommonStore();
  const [defaultLang, setDefaultLang] = useState(user?.primary_language);
  const [isDrawerExpand, setIsDrawerExpand] = useState(false);
  const { textSize } = useVachanasStore();
  const fontSizeClasses = {
    Small: "text-base",
    Medium: "text-xl",
    Large: "text-2xl",
  };
  return (
    <div className={`absolute z-50 ${isOpen ? "sm:w-[420px]" : "hidden"}`}>
      <div
        className={`fixed sm:top-12 bottom-0 max-sm:w-full max-sm:left-0 right-0 w-[412px] h-fix bg-white shadow-lg transform transition-transform duration-300 ${
          isOpen
            ? "sm:translate-x-0 max-sm:translate-y-0"
            : "sm:translate-x-full max-sm:translate-y-full"
        }`}
      >
        <div className="max-sm:w-screen px-4 py-6">
          <div>
            {drawerAction === "LANGUAGETOOL" ? (
              <>
                <div className=" flex justify-between pb-5">
                  <div className="flex gap-x-2.5 p-[7px]">
                    <img
                      onClick={closeHandler}
                      src={closeIcon}
                      alt="closeBtn"
                    />
                  </div>
                  <div className="flex gap-x-3 ">
                    <select
                      className={`text-Primary border-2 ${fontSizeClasses[textSize.size]} border-Primary w-fit rounded-full px-3`}
                      value={defaultLang}
                      onChange={(e) => setDefaultLang(e.target.value)}
                    >
                      {[
                        ...user?.secondary_languages,
                        user?.primary_language,
                      ].map((lang) => (
                        <option key={lang} value={lang}>
                          {languages.find((l) => l.i18n === lang)?.title}
                        </option>
                      ))}
                    </select>
                    <img
                      className="sm:hidden"
                      src={expandIcon}
                      onClick={() => setIsDrawerExpand((pre) => !pre)}
                      alt="expand icon"
                    />
                  </div>
                </div>
                <LanguageTool
                  isDrawerExpand={isDrawerExpand}
                  defaultLang={defaultLang}
                  kannadaVachana={kannadaVachana}
                />
              </>
            ) : null}
            {drawerAction === "FONTSIZE" ? (
              <FontSizeSelection closeHandler={closeHandler} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
