import React from 'react'

function GBLabel({ label='', desc='', styleLabel= '', styleDesc= ''}) {
  return (
    <>
      <h2 className={styleLabel}>
        {label}
      </h2>
      {desc? <p className={styleDesc}>
        {desc}
      </p> :null}
    </>
  )
}

export default GBLabel
