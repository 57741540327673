import { map } from "lodash";
import React, { useEffect, useState } from "react";
import GBButton from "./GBButton";
import GBLabel from "./GBLabel";
import { useTranslation } from "react-i18next";
import fontSizeIcon from "../assets/images/svg/fontSize.svg";
import useVachanasStore from "../store/VachanasStore";
import { IoClose } from "react-icons/io5";

function FontSizeSelection({ closeHandler }) {
  const { textSize, setTextSize } = useVachanasStore((state) => state);
  
  const { t, i18n } = useTranslation();

  const commonContext = t("common");

  const selectHandler = (size) => {
    setTextSize({
      size,
    });
  };

  const onSubmit = () => {
    setTextSize({
      preSize: textSize.size,
      size: textSize.size,
    });
    closeHandler();
  };
  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <GBLabel
            label="Select text size"
            styleLabel="text-[22px] font-normal font-denton leading-[33px]"
          />
          <IoClose
            size={25}
            onClick={closeHandler}
            className="cursor-pointer"
          />
        </div>
        <div className="flex gap-4">
          {map(
            [
              {
                title: "Small",
                size: "w-3.5",
              },
              {
                title: "Medium",
                size: "w-[18px]",
              },
              {
                title: "Large",
                size: "w-6",
              },
            ],
            (item, index) => (
              <GBButton
                key={index}
                className="flex flex-col items-center justify-center px-[22px] py-6 rounded-xl gap-2 w-28 h-28 shadow-md"
                isSelected={item.title === textSize.size}
                value={item.title}
                handleClick={selectHandler}
              >
                <div className="flex justify-center items-center w-6 h-6">
                  <img src={fontSizeIcon} className={item.size} alt="" />
                </div>
                <span className="font-dmSans font-medium text-sm ">
                  {item.title}
                </span>
              </GBButton>
            )
          )}
        </div>
      </div>
      <div className="flex w-full justify-end">
        <GBButton
          className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full gap-1 w-[149px] h-[52px]"
          handleClick={onSubmit}
        >
          <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
            {commonContext.apply}
          </span>
        </GBButton>
      </div>
    </div>
  );
}

export default FontSizeSelection;
