import React, { useEffect, useRef, useState } from "react";
import GBLabel from "../components/GBLabel";
import VachanaList from "../components/VachanaList";
import logo from "../assets/images/png/Ellipse.png";
import { filter, isEmpty, map } from "lodash";
import LetterSelector from "../components/LetterSelector";
import SharanaList from "../components/SharanaList";
import closeIcon from "../assets/images/svg/closeIcon.svg";

import {
  BackArrowIcon,
  SearchIcon,
  ShareIcon,
} from "../assets/icons/CustomIcons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GBSearch from "../components/GBSearch";
import { useTranslation } from "react-i18next";
import VachanaRightArrow from "../components/VachanaRightArrow";
import useVachanasStore from "../store/VachanasStore";
import { use } from "i18next";
import useSharanasStore from "../store/SharanasStore";
import GBShare from "../components/GBShare";

function Sharana() {
  const { id } = useParams();
  const { t } = useTranslation();
  const commonContext = t("common");
  const language = useTranslation().i18n.language;
  const { getVachanasBySharanaId, vachanas } = useSharanasStore();
  const [selectedItem, setSelectedItem] = useState("A");
  const [isSeachShow, setIsSearchShow] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(commonContext.vachana);
  const [searchText, setSearchText] = useState('')
  const [filterData, setFilterData] = useState({})
  const itemRefs = useRef({});
  const navigate = useNavigate();

  // const { vachanas } = useVachanasStore((state) => state);
  const handleClick = (e) => {
    setSelectedItem(e.target.textContent);
  };

  const handleFilter = (searchData) => {
    setSearchText(searchData.vTxt)
  }

  useEffect(() => {
    getVachanasBySharanaId(id);
  }, [language]);

  useEffect(() => {
    const result = {}
    let count = 0
    map(vachanas?.data, (value, key)=> {
      const keyData = filter(vachanas.data[key], (vachana) => isEmpty(searchText) || vachana.transliterated.txt.includes(searchText))
      if(!isEmpty(keyData)){
        result[key] = keyData
        count += keyData.length
      }
    }
    )
    setFilterData({
      data: result,
      char: Object.keys(result).sort(),
      count
    })
    
  },[searchText])

  useEffect(() => {
    // Scroll the selected character into view
    const timer = setTimeout(() => {

      if (selectedItem && itemRefs.current[selectedItem]) {
        itemRefs.current[selectedItem].scrollIntoView({
          behavior: "smooth",
          block: 'start',
          inline: "start",
        });
      }
    }, 0);

    return () => clearTimeout(timer)
  }, [selectedItem]);
  const handleBack = () => {
    navigate(-1); // This navigates back to the previous page
  };
  return (
    <div className="flex flex-col gap-8 relative overflow-y-scroll scrollbar-hide h-screen sm:max-h-screen-3rem overflow-x-hidden">

          <div className="sticky top-0 z-10 gap-[26px] flex flex-col w-full pt-6 bg-Surface">
            <div className="flex gap-x-2 sm:gap-x-3 items-center">
              <div className="hidden max-sm:block px-2 gap-1.5 max-sm:absolute" onClick={handleBack}>
                <BackArrowIcon />
              </div>
              <div className="flex flex-col sm:flex-row gap-4 sm:justify-between items-center w-full sm:items-start">
                <div className="sm:w-[42px] w-9 sm:h-[42px] h-9">
                  <img src={vachanas.author?.image || logo} alt="" className="w-full h-auto" />
                </div>
                <div className="flex-1 flex flex-col gap-1 text-center sm:text-left">
                  <h2 className="sm:text-xl text-base font-denton">
                    {vachanas?.author?.name || "GuruBasvsnna"}
                  </h2>
                  <p className="font-dmSans text-xs">
                    <span>{vachanas?.author?.vachanas}</span>
                  </p>
                </div>
              </div>
              <div className="flex gap-1.5 max-sm:absolute right-8 sm:hidden">
                <GBShare />
              </div>
            </div>

            {/*  */}
          </div>
          <div className="sticky top-[120px] sm:top-[72px] z-10">
            {/* change top-[-1px] */}
            <div className="relative bg-Surface">
              <div className="flex justify-between place-items-center pt-4 px-8">
                <div className="flex px-2 gap-4">
                  {[commonContext.vachana,
                    //  commonContext.about
                  ].map(
                    (menuItem, index) => (
                      <button
                        className={`px-2 pt-4 font-dmSans text-sm font-medium cursor-pointer border-b-2 transition-colors ${menuItem === selectedMenu ? "border-[#0B6062]" : "border-[#fbfaf3]"
                          }`}
                        key={index}
                        onClick={() =>setSelectedMenu(menuItem)}
                      >
                        {menuItem}
                      </button>
                    )
                  )}
                </div>
              {!isSeachShow && <div
                  className="flex gap-x-1  h-full  items-center"
                  onClick={() => setIsSearchShow(true)}
                >
                  <SearchIcon height={16} width={16} />{" "}
                  <p className="font-dmSans text-sm font-normal items-start">
                    {commonContext.search}
                  </p>
                </div>}
              </div>
              {isSeachShow && (
                <div className="flex px-8 py-2.5 bg-white border-t-2 items-center">
                  <GBSearch value={searchText} onEnter={handleFilter} className="flex gap-x-3 h-full items-center w-full sm:w-40" />
                  <button
                    onClick={() => {
                      setIsSearchShow(false)
                      setSearchText('')
                    }}
                    className="w-8 h-8"
                  >
                    <img src={closeIcon} alt="close" />
                  </button>
                </div>
              )}
            </div>

            <LetterSelector
              data={filterData?.char||[]}
              handleClick={handleClick}
              selectedChar={selectedItem}
              className="px-8 py-2.5 border-y-2 bg-white h-14 sm:w-screen-13rem w-screen"
            />
          </div>
      <div className="flex flex-col gap-8 px-8">

        {isEmpty(searchText)?null: <div className="flex flex-col pb-0 gap-y-2">
          <p className="font-dmSans text-[10px] font-bold leading-4">
            {commonContext.resultsForYou}
          </p>
          <p className="font-denton text-xl font-medium">{`${filterData?.count} ${commonContext.vachanas}`}</p>
        </div>}
          
          {map(filterData?.char||[], (chr, key) => (//vachanas?.char
          <div
            key={key + "_vachanas"}
            className=" flex w-full max-sm:flex-col pt-0 gap-x-14 max-sm:relative"
          >
            <div
              id={`vachanas_${chr}`}
              className={`relative max-sm:sticky ${isSeachShow ? "max-sm:top-[282px]" : "max-sm:top-56"
                } max-sm:bg-Surface pt-[-20px]`}
            >
              <h2
                ref={(el) => (itemRefs.current[chr] = el)}
                className={`text-[32px] font-medium font-denton text-GoldenBrown sm:sticky ${isSeachShow ? "top-64" : "top-52"
                  }`}
              >
                {chr}
              </h2>
            </div>
            <div className="pt-8 px-4 max-sm:px-2">
              {filterData.data[chr].map((vachanaItem, index) => (
                <div key={index}>
                  {index ? <hr className="my-6 text-2xl" /> : null}
                  {/* <VachanaList key={index} {...vachanaItem} path={"/vachana"} > */}
                  <VachanaList
                    key={index}
                    {...{
                      title: ``,
                      id: vachanaItem.id,
                      content: vachanaItem.transliterated.txt,
                      author: vachanaItem.author.name,
                      totalVachanas: vachanaItem.author.vachanas,
                      image: vachanaItem.author.image || logo,
                      toRefer: 'sharanas'
                    }}
                    path={"/vachana"}
                  />
                  <div className="flex justify-end mt-[-25px]">
                    <VachanaRightArrow path="/vachana" id={vachanaItem.title} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="sticky bottom-0 bg-gradient-to-t from-Surface h-[76px] w-full"></div>
    </div>
  );
}

export default Sharana;
