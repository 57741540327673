import React from "react";
import { useTranslation } from "react-i18next";
import { CiShare2 } from "react-icons/ci";

const ShareButton = () => {
  const { t } = useTranslation();
  const common = t("common");
  const handleShare = async () => {
    const shareData = {
      // title: `${common.todayVachana}`,
      text: `🙏 ${common.todayVachana} 🙏 `,
      url: window.location.href.includes("ref=search")
        ? window.location.href.split("?")[0]
        : window.location.href, // Current page URL
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
        console.log("Content shared successfully");
      } else {
        alert("Sharing not supported on this device.");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  return <CiShare2 onClick={handleShare} style={{ fontSize: "1.5rem" }} />;
};

export default ShareButton;
