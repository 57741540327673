import React, { useEffect, useState } from "react";
import VachanaList from "../components/VachanaList";
import logo from "../assets/images/png/Ellipse.png";
import RemoveIcon from "../assets/icons/remove.png";
import { map } from "lodash";
import useAuthStore from "../store/UserStore";
import { getAllBlogPins, removeFromBookmark } from "../services/apis";

function Bookmarked() {
  const [bookmarkData, setBookmarkData] = useState([]);
  const { user, getUser } = useAuthStore();

  useEffect(() => {
    const fetchData = async () => {
      await getUser(user.id);
      setBookmarkData(await getAllBlogPins(user.id));
    };
    fetchData();
  }, [getUser, user.id]);

  const handleRemoveBookmark = async (id) => {
    await removeFromBookmark(user.id, id);
    const updatedPins = await getAllBlogPins(user.id);
    setBookmarkData(updatedPins);
  };
  return (
    <div className="flex flex-col gap-2 py-4 w-full">
      {map(bookmarkData, (vachana, index) => (
        <div key={index} className="flex flex-col gap-6">
          {index ? <hr className="w-full" /> : null}
          <VachanaList
            key={index}
            id={vachana.id}
            content={vachana.transliterated.txt}
            author={vachana.author.name}
            totalVachanas={vachana.author.vachanas}
            image={vachana.author.image || logo}
            path={"/vachana"}
          />
          <div
            className="relative flex justify-end mt-[-60px] mr-[-15px] cursor-pointer"
          >
            <img src={RemoveIcon} alt="remove bookmark"
              onClick={() => handleRemoveBookmark(vachana.id)}
              className="w-14" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Bookmarked;
