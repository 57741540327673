import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GBInput from "../components/GBInput";
import selectArrowIcon from "../assets/images/svg/select-arrow-up.svg";
import GBButton from "./GBButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Country, State, City } from "country-state-city";
import useAuthStore from "../store/UserStore";
import { use } from "i18next";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address"),
  dob: Yup.date()
    .max(new Date(), "Date of birth cannot be in the future")
    .required("Date of birth is required"),
  gender: Yup.string()
    .oneOf(["Male", "Female", "Others"], "Invalid gender")
    .required("Gender is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  country: Yup.string().required("Country is required"),
});

const Details = ({ handleBackClick, handleFormData, title }) => {
  const { user } = useAuthStore((state) => state);
  const { t } = useTranslation();
  const commonContext = t("common");

  const [selectedCountry, setSelectedCountry] = useState(user?.country || "");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (user?.country && user?.state) {
      setCities(City.getCitiesOfState(user?.country, user.state));
    }
  }, [user?.country, user?.state]);

  function getCountriesWithIndiaFirst() {
    const countries = Country.getAllCountries();
    const india = countries.find((country) => country.isoCode === "IN");
    if (india) {
      return [india, ...countries.filter((country) => country.isoCode !== "IN")];
    }
    return countries;
  }
  const formik = useFormik({
    initialValues: {
      email: user?.email || "",
      name: user?.name || "",
      dob: user?.dob || "",
      gender: user?.gender || "",
      country: user?.country || "",
      state: user?.state || "",
      city: user?.city || "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleFormData(values);
    },
  });

  const handleCountryChange = (e) => {
    const countryIsoCode = e.target.value;
    setSelectedCountry(countryIsoCode);
    formik.setFieldValue("country", countryIsoCode);
    formik.setFieldValue("state", "");
    formik.setFieldValue("city", "");
    setCities([]); // Reset cities when country changes
  };

  const handleStateChange = (e) => {
    const stateIsoCode = e.target.value;
    setCities(City.getCitiesOfState(selectedCountry, stateIsoCode));
    formik.setFieldValue("state", stateIsoCode);
    formik.setFieldValue("city", "");
  };

  const handleCityChange = (e) => {
    formik.setFieldValue("city", e.target.value);
  };

  const handleNext = async () => {
    formik.setTouched({
      name: true,
      email: true,
      dob: true,
      gender: true,
      country: true,
      state: true,
      city: true,
    });
    const isValid = await formik.validateForm();
    if (Object.keys(isValid).length === 0) {
      formik.handleSubmit();
    }
  };

  let countries = getCountriesWithIndiaFirst();


  return (
    <div className="flex flex-col justify-between h-full">
        {/* <div className="flex flex-col gap-y-8  h-full sm:w-96"> */}
        <div className="flex flex-col justify-between w-full h-full ">
        <h1 className="font-medium text-2xl leading-9 font-denton">
          {title || "Enter"} your details
        </h1>
        <form
          onSubmit={formik.handleSubmit}
          className="details-form grid grid-flow-row auto-rows-max gap-[22px] h-[calc(100%-100px)] w-full max-w-md overflow-auto scrollbar-hide p-1"
        >
          <GBInput
            name="name"
            placeholder="Name"
            handleChange={formik.handleChange}
            value={formik.values.name}
            className={`w-full h-[66px] p-3 text-xs ${formik.values.name
                ? "text-[#5E5C5A]"
                : "placeholder-Disabled"
              } bg-white rounded-full focus:ring-1 focus:ring-Primary shadow-sm `}
          />
          {formik.touched.name && formik.errors.name && (
            <div className="error text-Error px-2">
              {formik.errors.name}
            </div>
          )}

          <GBInput
            name="email"
            placeholder="Email"
            handleChange={formik.handleChange}
            value={formik.values.email}
            className={`w-full h-[66px] p-3 text-xs ${formik.values.email ? "text-[#5E5C5A]" : "placeholder-Disabled"
              } bg-white rounded-full focus:ring-1 focus:ring-Primary shadow-sm`}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="error text-Error px-2">{formik.errors.email}</div>
          )}
          <GBInput
            type="month"
            name="dob"
            placeholder="Date of Birth"
            handleChange={formik.handleChange}
            value={formik.values.dob}
            className={`w-full h-[66px] p-3 text-xs uppercase${formik.values.dob ? "text-[#5E5C5A]" : "text-Disabled"
              } bg-white rounded-full focus:ring-1 focus:ring-Primary shadow-sm`}
          />
          {formik.touched.dob && formik.errors.dob && (
            <div className="error text-Error px-2">{formik.errors.dob}</div>
          )}
          <select
            name="gender"
            onChange={formik.handleChange}
            value={formik.values.gender}
            style={{
              backgroundImage: `url(${selectArrowIcon})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 22px center",
            }}
            className={`w-full p-3 h-[66px] ps-5 text-xs ${formik.values.gender ? "text-[#5E5C5A]" : "text-Disabled"
              } bg-white rounded-full appearance-none outline-none focus:ring-1 focus:ring-Primary shadow-sm`}
          >
            <option value="" disabled>
              Select Gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Others">Others</option>
          </select>
          {formik.touched.gender && formik.errors.gender && (
            <div className="error text-Error px-2">
              {formik.errors.gender}
            </div>
          )}

          <select
            name="country"
            onChange={handleCountryChange}
            value={formik.values.country}
            style={{
              backgroundImage: `url(${selectArrowIcon})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 22px center",
            }}
            className={`w-full p-3 text-xs h-[66px] ps-5 ${formik.values.country ? "text-[#5E5C5A]" : "text-Disabled"
              } bg-white rounded-full appearance-none outline-none focus:ring-1 focus:ring-Primary shadow-sm`}
          >
            <option value="" disabled>
              Select Country
            </option>
            {countries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </select>
          {formik.touched.country && formik.errors.country && (
            <div className="error text-Error px-2">
              {formik.errors.country}
            </div>
          )}

          <select
            name="state"
            onChange={handleStateChange}
            value={formik.values.state}
            style={{
              backgroundImage: `url(${selectArrowIcon})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 22px center",
            }}
            className={`w-full p-3 text-xs h-[66px] ps-5 ${formik.values.state ? "text-[#5E5C5A]" : "text-Disabled"
              } bg-white rounded-full appearance-none outline-none focus:ring-1 focus:ring-Primary shadow-sm`}
          >
            <option value="" disabled>
              Select State
            </option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </select>
          {formik.touched.state && formik.errors.state && (
            <div className="error text-Error px-2">{formik.errors.state}</div>
          )}

          <select
            name="city"
            onChange={handleCityChange}
            value={formik.values.city}
            style={{
              backgroundImage: `url(${selectArrowIcon})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 22px center",
            }}
            className={`w-full p-3 text-xs h-[66px] ps-5 ${formik.values.city ? "text-[#5E5C5A]" : "text-Disabled"
              } bg-white rounded-full appearance-none outline-none focus:ring-1 focus:ring-Primary shadow-sm`}
          >
            <option value="" disabled>
              Select City
            </option>
            {cities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
          {formik.touched.city && formik.errors.city && (
            <div className="error text-Error px-2">{formik.errors.city}</div>
          )}
        </form>
      
        <div className="flex w-full justify-between">
          {title === "Edit" ? (
            <GBButton
              className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full w-[149px] h-[52px]"
              handleClick={handleBackClick}
            >
              <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
                {commonContext.cancel}
              </span>
            </GBButton>
          ) : (
            <GBButton
              className="flex items-center justify-center text-Primary border border-Primary px-2 py-1 rounded-full gap-1 max-sm:w-[120px] w-[149px] h-[52px]"
              handleClick={handleBackClick}
            >
              <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
                <FaArrowRightLong className="mr-1 rotate-180" />{" "}
                {commonContext.back}
              </span>
            </GBButton>
          )}

          <GBButton
            className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full gap-1 w-[149px] h-[52px]"
            handleClick={handleNext}
          >
            <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
              {commonContext.save} <FaArrowRightLong className="ml-1" />
            </span>
          </GBButton>
        </div>
        </div>
    </div>
  );
};

export default Details;
