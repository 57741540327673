import React, { useEffect, useState } from 'react'
import useCommonStore from '../store/CommonStore';
import { useTranslation } from 'react-i18next';
import Sanscript from 'sanscript';

function GBInput({className='', placeholder='', name='', type='text', handleKeyPress=()=>{}, handleChange=()=>{}, value=''}) {
      const [val, setVal] = useState('')
      const [txt, setTxt] = useState('')
      const [selectedLanguage, setSelectedLanguage] = useState(null);

      const { languages } = useCommonStore((state) => state);
      const { t, i18n } = useTranslation();
      const changeHandler = (event) => {
            const inputText = event.target.value;    
            const isEnText = 'abcdefghijklmnopqrstuvwxyz'.includes(inputText.trim().slice(-1)||''.toLowerCase())
            
            let vText = '';
            if (i18n.language.includes('en')) {      
              vText = inputText
              setTxt(inputText)
            } else if (val.length < inputText.length && isEnText) {  // Check if text is increasing in length (i.e., typing a new character)    
              // Update the search text progressively
              setTxt(prev => prev + inputText.slice(val.length));
                
              // Perform transliteration from ITRANS to Kannada (not Devanagari)
              vText = Sanscript.t(txt + inputText.slice(val.length), 'itrans', selectedLanguage.script).replace(/्/g, ''); // 'kn' for Kannada
            } else if (val.slice(0,-1) === inputText) {
              vText = Sanscript.t(txt.slice(0,-1), 'itrans', selectedLanguage.script).replace(/्/g, '');
              setTxt( pre => pre.slice(0,-1))
            }else {
              // For text reduction (backspace or delete)
              setTxt(Sanscript.t(inputText, selectedLanguage.script, 'itrans'))
              vText = inputText
            }  
            // Update state with the translated text
            setVal(vText);
            changeHandler(vText)
          };

          useEffect(() => {
            setSelectedLanguage(languages.find(language => language.i18n === i18n.language))
        }, [i18n, i18n.language]);
    return (
        
          <input
           className={`border-0  focus:ring-0 h-[50px] focus:outline-none text-sm placeholder-opacity-100 px-6 ${className}`}
           type={type}
           name={name}
           value={value}
           onKeyDown={handleKeyPress}
           onChange={handleChange}
           placeholder={placeholder}
        />
          
        
      )
}

export default GBInput
