import React, { useState } from "react";
import PrimaryLanguage from "../components/PrimaryLanguage";
import SecondaryLanguage from "../components/SecondaryLanguage";
import TimeSelection from "../components/TimeSelection";
import TimeScore from "../components/TimeScore";
import Details from "../components/Details";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../store/UserStore";

function Selection() {
  const { createUser } = useAuthStore((state) => state);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [pageIndex, setPageIndex] = useState(0);

  const handleNextClick = () => {
    setPageIndex((preState) => preState + 1);
    if (pageIndex === 4) {
      setFormData({});
      navigate("/vachanas");
    }
  };
  const handleBackClick = () => {
    setPageIndex((preState) => preState - 1);
  };

  const handleFormData = (data) => {
    setFormData({ ...formData, ...data });
    if (pageIndex === 4) {
      createUser({ ...formData, ...data });
    }
  };
  return (
    <div className="px-4">
      {pageIndex === 0 ? (
        <div className="flex items-end justify-center h-screen py-8">
          <div className="flex flex-col gap-2 max-w-md">
            <PrimaryLanguage
              handleNextClick={handleNextClick}
              handleFormData={handleFormData}
            />
          </div>
        </div>
      ) : null}
      {pageIndex === 1 ? (
        <div className="flex items-end justify-center h-screen py-8">
          <div className="flex flex-col gap-2 max-w-md">
            <SecondaryLanguage
              handleNextClick={handleNextClick}
              handleBackClick={handleBackClick}
              handleFormData={handleFormData}
            />
          </div>
        </div>
      ) : null}
      {pageIndex === 2 ? (
        <div className="flex items-end justify-center h-screen py-8">
          <div className="flex flex-col gap-2 max-w-md">
            <TimeSelection
              handleNextClick={handleNextClick}
              handleBackClick={handleBackClick}
              handleFormData={handleFormData}
            />
          </div>
        </div>
      ) : null}
      {pageIndex === 3 ? (
        <div className="flex  justify-center h-screen py-8">
          <div className="flex flex-col gap-12 bg-surface w-full max-w-lg relative">
            <TimeScore
              handleNextClick={handleNextClick}
              handleBackClick={handleBackClick}
              handleFormData={handleFormData}
            />
          </div>
        </div>
      ) : null}
      {pageIndex === 4 ? (
        <div className="flex items-end justify-center h-screen py-8">
          <div className="flex flex-col gap-2 bg-surface w-full max-w-lg relative">
            <Details
              handleNextClick={handleNextClick}
              handleBackClick={handleBackClick}
              handleFormData={handleFormData}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Selection;
