import React, { useEffect, useState } from "react";
import GBLabel from "./GBLabel";
import LanguageSection from "./LanguageSection";
import { useTranslation } from "react-i18next";
import GBButton from "./GBButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../store/UserStore";

function PrimaryLanguage() {
  const { user, updateUser } = useAuthStore((state) => state);
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(user?.primary_language);
  const [nextScreen, setNextScreen] = useState(false);
  const [selectedLangArr, setSelectedLangArr] = useState(
    user?.secondary_languages || []
  );
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();
  const primaryContext = t("primaryLanguage");
  const scondaryContext = t("secondaryLanguage");

  const commonContext = t("common");

  const changeLanguage = (value) => {
    setSelectedLang(value);
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
    document.documentElement.lang = i18n.language;
  }, [i18n, i18n.language]);

  const handleClick = () => {
    if (selectedLangArr.length === 0) {
      setErrorText("At least one language should be selected");
      return;
    } else if (selectedLangArr.length > 0) {
      updateUser(user.id, {
        primary_language: selectedLang,
        secondary_languages: selectedLangArr.filter(
          (lang) => lang !== selectedLang
        ),
      });
      navigate("/home");
    }
  };
  const handleSecondaryLanguage = (value) => {
    if (selectedLangArr.includes(value)) {
      setSelectedLangArr((preState) =>
        preState.filter((lang) => lang !== value)
      );
    } else if (selectedLangArr.length < 0) {
      setSelectedLangArr([...selectedLangArr, value]);
      setErrorText(`At least one language should be selected`);
    } else {
      setSelectedLangArr([...selectedLangArr, value]);
      setErrorText("");
    }
  };
  const handleCancel = () => {
    if (nextScreen) {
      setNextScreen(false);
    } else {
      i18n.changeLanguage(user?.primary_language);
      navigate(-1);
    }
  };

  return (
    <div className="flex fixed flex-col justify-end h-[calc(100vh-72px)] max-sm:h-screen max-xl:px-4 px-[350px] width-[800px] max-sm:py-6 gap-9">
      <div className="flex flex-col gap-2">
        {!nextScreen ? (
          <>
            <GBLabel
              {...{
                label: primaryContext.title,
                desc: primaryContext.description,
                styleLabel:
                  "text-[24px] font-normal font-denton leading-[36px]",
                styleDesc:
                  "font-dmSans text-[14px] leading-[21px] text-GrayishBrown",
              }}
            />
            <LanguageSection
              handleFun={changeLanguage}
              selectedLang={selectedLang}
            />
          </>
        ) : (
          <>
            <GBLabel
              {...{
                label: scondaryContext.title,
                desc: scondaryContext.description,
                styleLabel:
                  "text-[24px] font-normal font-denton leading-[36px]",
                styleDesc:
                  "font-dmSans text-[14px] leading-[21px] text-GrayishBrown",
              }}
            />

            <LanguageSection
              selectedLang={selectedLangArr}
              handleFun={handleSecondaryLanguage}
              disabledLanguage={selectedLang}
            />
            {errorText && (
              <div className="error text-Error px-2">{errorText}</div>
            )}
          </>
        )}
        <div className="flex w-full justify-between py-2">
          <GBButton
            className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full gap-1 w-[149px] h-[52px]"
            handleClick={() => handleCancel()}
          >
            <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
              {nextScreen ? commonContext.back : commonContext.cancel}
            </span>
          </GBButton>
          <GBButton
            className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full gap-1 w-[149px] h-[52px]"
            handleClick={() =>
              !nextScreen ? setNextScreen(true) : handleClick()
            }
            disabled={selectedLangArr.length === 0}
          >
            <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
              {!nextScreen ? commonContext.next : commonContext.apply}{" "}
              <FaArrowRightLong className="ml-1" />
            </span>
          </GBButton>
        </div>
      </div>
    </div>
  );
}

export default PrimaryLanguage;
