import React, { useEffect } from "react";
import GBButton from "./GBButton";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useCommonStore from "../store/CommonStore";

function LanguageSection({ selectedLang = ["en"], handleFun = () => {}, disabledLanguage }) {
  const { languages } = useCommonStore((state) => state);

  const handleClick = (value) => {
    handleFun(value);
  };

  return (
    <div className="flex flex-wrap gap-4">
      {languages?.map((language, index) => (
        <div
          key={index}
          data-tooltip-id={language.i18n}
          data-tooltip-content={language.name}
        >
          <GBButton
            {...{
              title: language.title,
              value: language.i18n,
              handleClick,
              isSelected: selectedLang.includes(language.i18n),
              disabled: disabledLanguage === language.i18n,
              className: `text-[12px] leading-[13px] px-[18px] py-[20px] rounded-full shadow-md ${
                disabledLanguage === language.i18n ? "opacity-50 cursor-not-allowed bg-gray-200" : ""
              }`,
            }}
          >
            {language.title}
          </GBButton>
          <ReactTooltip id={language.i18n} />
        </div>
      ))}
    </div>
  );
}

export default LanguageSection;
