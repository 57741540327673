import React, { useEffect, useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../Firebase"; // Import the initialized auth instance
import logo from "../assets/images/png/logo.png";
import GBButton from "../components/GBButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { CgSpinner } from "react-icons/cg";
import useAuthStore from "../store/UserStore";
import { StatefulPinInput } from "react-input-pin-code";
import { toast } from "react-toastify";

const Login = () => {
  const { getUser } = useAuthStore();
  const [isOtpScreen, setIsOtpScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => setResendTimer((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);

  const initializeRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("ReCAPTCHA verified: ", response);
          },
          "expired-callback": () => {
            toast.error("ReCAPTCHA expired. Please refresh and try again.");
          },
        }
      );
    }
  };

  const sendOtp = async () => {
    setIsDisabled(true);
    const number = phoneNumber.toString().replace(/[^+\d]+/g, "");
    if (!/^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/.test(number)) {
      toast.error("Please enter a valid phone number.");
      setIsDisabled(false);
      return;
    }
    initializeRecaptcha();
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier)
      .then((result) => {
        toast.success("OTP sent successfully.");
        setConfirmationResult(result);
        setIsOtpScreen(true);
        setIsDisabled(false);
        setResendTimer(30);
      })
      .catch((error) => {
        toast.error(`Failed to send OTP: ${error.message}`);
        console.error("Error sending OTP: ", error);
        setIsDisabled(false);
      });
  };

  const onOTPVerify = async () => {
    if (otp.some((digit) => digit.trim() === "")) {
      toast.error("Please fill in all OTP fields.");
      return;
    }
    const enteredOtp = otp.join("");

    try {
      const result = await confirmationResult.confirm(enteredOtp);
      await getUser(result.user.uid);
      // toast.success("Phone number verified successfully.");
      console.log("User signed in: ", result.user);
    } catch (error) {
      toast.error("Invalid OTP. Please try again.");
      console.error("Error verifying OTP: ", error);
      setIsDisabled(false);
    }
  };

  const resendOTP = async () => {
    if (resendTimer > 0) return;
    sendOtp();
  };

  return (
    <div className="login-container flex flex-col px-4 py-20 bg-Surface h-screen w-screen items-center sm:gap-y-14">
      <div className="flex flex-col max-sm:flex-grow justify-center items-center">
        <img
          src={logo}
          alt="Logo"
          className="logo w-[187px] sm:w-32 h-[187px] sm:h-32"
        />
        <h1 className="font-sans text-sm font-bold tracking-[3px] font-dmSans">
          GURU BASAVA
        </h1>
      </div>
      <div className="flex flex-col flex-grow justify-end sm:justify-start w-full gap-y-1 max-w-sm">
        <h1 className="font-medium text-2xl leading-9 font-denton">
          {isOtpScreen ? "Enter OTP" : "Log in"}
        </h1>
        {!isOtpScreen ? (
          <div className="flex flex-col gap-y-20 sm:gap-y-16">
            <div className="relative">
              <PhoneInput
                country={"in"}
                preferredCountries={["in"]}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                placeholder="Mobile no."
                enableSearch
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      sendOtp();
                    }
                  },
                }}
                searchStyle={{ width: "90%" }}
                inputStyle={{
                  width: "100%",
                  height: "45px",
                  borderRadius: "0.5rem",
                  fontSize: "1.2rem",
                }}
              />
            </div>
            <div className="flex flex-col sm:items-center">
              <GBButton
                title="Continue"
                handleClick={sendOtp}
                className="flex items-center justify-center rounded-full bg-Primary font-dmSans sm:text-sm sm:py-4 text-Surface px-4 py-[21px] min-w-32"
                disabled={isDisabled}
              >
                Continue
                {loading ? (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                ) : (
                  <FaArrowRightLong className="ml-1" />
                )}
              </GBButton>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-y-14 items-center">
            <div className="flex flex-col ">
              <StatefulPinInput
                values={otp}
                onChange={(value, index, values) => setOtp(values)}
                className="flex gap-2 justify-between"
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "0.5rem",
                  backgroundColor: "#fff",
                  border: "1px solid #ddd",
                  fontSize: "1.5rem",
                  textAlign: "center",
                  outline: "none",
                }}
                placeholder="0"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onOTPVerify();
                  }
                }}
              />

              <div className="flex justify-between">
                <div className="flex items-center justify-center rounded-full text-Primary">
                  {`${Math.floor(resendTimer / 60)}:${String(
                    resendTimer % 60
                  ).padStart(2, "0")}`}
                </div>
                <div
                  className={`flex items-center justify-center rounded-full ${
                    resendTimer > 0 ? "text-[#999999]" : "text-Primary"
                  }`}
                  onClick={resendTimer <= 0 && resendOTP}
                >
                  Resend OTP
                </div>
              </div>
              <div className="flex flex-col sm:items-center pt-10">
                <GBButton
                  title="Log in"
                  handleClick={onOTPVerify}
                  className="flex items-center justify-center rounded-full bg-Primary font-dmSans sm:text-sm sm:py-4 text-Surface px-4 py-[21px] min-w-32"
                  disabled={isDisabled}
                >
                  Log in
                  {loading ? (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  ) : (
                    <FaArrowRightLong className="ml-1" />
                  )}
                </GBButton>
              </div>
            </div>
          </div>
        )}
      </div>
      <div ref={(ref) => (window.recaptchaWrapperRef = ref)}>
        <div id="recaptcha-container" />
      </div>
    </div>
  );
};

export default Login;
