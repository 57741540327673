import React, { useEffect, useState } from "react";
import GBSearch from "../components/GBSearch";
import GBButton from "../components/GBButton";
import PickSharanas from "./PickSharanas";
import { useLocation, useNavigate } from "react-router-dom";
import { get, isEmpty, map } from "lodash";
import closeIcon from "../assets/images/svg/closeIcon.svg";
import logo from "../assets/images/png/Ellipse.png";
import VachanaList from "../components/VachanaList";
import { useTranslation } from "react-i18next";
import VachanaRightArrow from "../components/VachanaRightArrow";
import useSharanasStore from "../store/SharanasStore";
// import { itransliterate } from 'indic-transliteration';
import useVachanasStore from "../store/VachanasStore";
import GBScrollElement from "../components/GBScrollElement";
import SearchList from "../components/SearchList";

function SearchScreen() {
  const {
    setSelectedFilterSharanas,
    selectedFilterSharanas,
    loading: sharanaLoading,
    error: sharanaError,
  } = useSharanasStore((state) => state);
  const [selectedSharanas, setSelectedSharanas] = useState([]);
  const [isPickSharanas, setIsPickSharanas] = useState(false)
  const [searchText, setSearchText] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const kannadaText = itransliterate(englishInput, { to: 'kn' });

  const queryParams = new URLSearchParams(location.search);

  const context = t("searchScreen");
  const commonContext = t("common");
  const {
    getSearchedVachanas,
    getMoreSearchedVachanas,
    searchedVachanas,
    resetSearchedVachanas,
    loading,
    error,
  } = useVachanasStore((state) => state);

  const removeSelectSharanas = (sharana) => {
    setSelectedFilterSharanas(
      selectedFilterSharanas.filter((item) => item.id !== sharana.id)
    );
  };

  const handleSearch = (searchData) => {
    getSearchedVachanas({
      searchText: searchData.vTxt,
      selectedSharanas: selectedFilterSharanas.map((sharana) => sharana.id),
    });
  };

  const handleScroll = () => {
    getMoreSearchedVachanas();
  };

  useEffect(() => {
    setSearchText(searchedVachanas.searchedText)

    // return () => {
    //   resetSearchedVachanas(); // Cleanup on unmount
    //   setSelectedFilterSharanas([])
    // };
    
  }, []);

  const handleShow = () => {
    setIsPickSharanas(preState => !preState)
  }
  // if (sharanaLoading )
  //   return (
  //     <div className="flex justify-center items-center h-screen-90px sm:max-h-screen-3rem">
  //       Loading...
  //     </div>
  //   );
  if (sharanaError)
    return (
      <div className=" h-screen-90px sm:max-h-screen-3rem">
        Error: {sharanaError}
      </div>
    );

  return (
    <>
      {isPickSharanas ? (
        <PickSharanas handleShow={handleShow} />
      ) : (
        <div className="w-full h-screen-90px sm:h-screen-3rem overflow-y-scroll scrollbar-hide">          
            <div
              className={`flex flex-col p-8 gap-y-[22px] sticky top-0 bg-Surface ${
                isEmpty(searchedVachanas.data) ? "" : "border-b-2"
              }`}
            >
              <div className="w-full">
                <GBSearch
                  className="w-full max-w-[393px] relative flex"
                  onEnter={handleSearch}
                  value={searchedVachanas.searchedText ||searchText}
                  setValue={setSearchText}
                  isIcon={false}
                  isClear={true}
                  inputStyle="flex gap-x-2.5 px-[22px] py-4 h-[66px] bg-white w-full max-w-[393px] rounded-full text-sm focus:ring-1 focus:ring-Primary shadow-md"
                />
              </div>
              <div className="flex gap-x-3">
                <div>
                  <GBButton
                    className="px-[22px] py-4 font-dmSans bg-Surface rounded-full shadow-md w-max"
                    handleClick={handleShow}
                  >
                    {`${
                      isEmpty(selectedFilterSharanas)
                        ? ""
                        : selectedFilterSharanas?.length + " "
                    }${commonContext.sharana}`}
                  </GBButton>
                </div>

                {selectedFilterSharanas.length ? (
                  <div className="flex gap-x-1 overflow-x-scroll scrollbar-hide">
                    {map(selectedFilterSharanas, (item, index) => (
                      <div key={index} className="flex gap-x-1 items-center">
                        {index ? (
                          <hr className="w-px h-[22px] inline-block bg-Gainsboro" />
                        ) : null}
                        <div className="flex gap-x-2 p-2.5 items-center w-max">
                          <div className="flex gap-x-1 items-center">
                            <img
                              src={item.image || logo}
                              alt=""
                              className="w-[22px] h-[22px]"
                            />
                            <h3 className="font-dmSans text-xs font-medium">
                              {item.name}
                            </h3>
                          </div>
                          <div>
                            <img
                              src={closeIcon}
                              alt="closeBtn"
                              className="w-full h-full"
                              onClick={() => removeSelectSharanas(item)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="w-[263px] flex items-center">
                    <p className="font-dmSans text-xs font-normal text-GrayishBrown">
                      {context.description}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {loading ? <div className="flex justify-center items-center h-screen-90px sm:max-h-screen-3rem">
              Loading...
            </div> : !isEmpty(searchedVachanas) ? (
              <GBScrollElement
                handleScroll={handleScroll}
                isCompleted={
                  get(searchedVachanas, "data", []).length ===
                  get(searchedVachanas, "totalCount", 0)
                }
              >
                <div className="p-8">
                  {
                    // search happen
                    true ? (
                      <div className="flex flex-col pb-8 gap-y-2">
                        <p className="font-dmSans text-[10px] font-bold leading-4">
                          Results for you
                        </p>
                        <p className="font-denton text-xl font-medium">
                          {get(searchedVachanas, "totalCount", 0)} Vachanas
                        </p>
                        {/* replace 32 with data length */}
                      </div>
                    ) : null
                  }
                  <div className="w-full sm:w-fit">
                    {map(searchedVachanas.data, (vachanaItem, index) => (
                      <div key={index}>
                        {index ? <hr className="my-6" /> : null}

                        {/* <VachanaList key={index} {...vachanaItem} path={"/vachana"}> */}
                        <SearchList
                          key={index}
                          {...{
                            title: ``,
                            id: vachanaItem.id,
                            content: vachanaItem.translated?.txt,
                            author: vachanaItem.author?.name,
                            totalVachanas: vachanaItem.author?.vachanas,
                            image: vachanaItem.author?.image || logo,
                            toRefer: 'search',
                            searchTerm: searchedVachanas.searchedText
                          }}
                          path={"/vachana"}
                        >
                          <VachanaRightArrow
                            path="/vachana"
                            id={vachanaItem.title}
                          />
                        </SearchList>
                      </div>
                    ))}
                  </div>
                </div>
              </GBScrollElement>
            ) : null}
        </div>
      )}
    </>
  );
}

export default SearchScreen;
