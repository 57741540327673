import React, { useState } from "react";
import GBSearch from "../components/GBSearch";
import { isEmpty, map } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/png/Ellipse.png";
import { BackArrowIcon } from "../assets/icons/CustomIcons";
import VachanaList from "../components/VachanaList";
import VachanaRightArrow from "../components/VachanaRightArrow";
import useVachanasStore from "../store/VachanasStore";
import ComingSoon from "../views/comingSoon";

function Dictionary() {
  const [searchData, setSearchData] = useState([]);
  const [wordData, setWordData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    getSearchedVachanas,
    getMoreSearchedVachanas,
    searchedVachanas,
    loading,
    error,
  } = useVachanasStore((state) => state);

  const queryParams = new URLSearchParams(location.search);

  const context = t("searchScreen");
  const commonContext = t("common");

  const tempData = [
    {
      title: "24 Vachnana",
      content: `Angadoḷagaṇa aṣṭamada mōhini gaḷanāranū kāṇe.
          Bhaṅgiya soppu tinda baḷḷu uḷḷiṭṭu odaruvaṃdadi 
          dēha ātmada talegēri,
          baride vēdaśāstrapurāṇaveṃdōdi 
          ballavarenisikombaru lajjabhaṃdaru.
          Nudiyante naḍeyalarayada jaḍadēhigaḷa meccuvane
          namma paramaguru paḍuviḍi siddhamallināthaprabhuve.`,
      author: "GuruBasvsnna",
      totalVachanas: 2100,
      image: logo,
    },
    {
      title: "24 Vachnana",
      content: `Angadoḷagaṇa aṣṭamada mōhini gaḷanāranū kāṇe.
          Bhaṅgiya soppu tinda baḷḷu uḷḷiṭṭu odaruvaṃdadi 
          dēha ātmada talegēri,
          baride vēdaśāstrapurāṇaveṃdōdi 
          ballavarenisikombaru lajjabhaṃdaru.
          Nudiyante naḍeyalarayada jaḍadēhigaḷa meccuvane
          namma paramaguru paḍuviḍi siddhamallināthaprabhuve.`,
      author: "GuruBasvsnna",
      totalVachanas: 2100,
      image: logo,
    },
  ];

  const handleBack = () => {
    navigate(-1); // This navigates back to the previous page
  };

  const handleSearch = (searchData) => {
    getSearchedVachanas({
      searchText: searchData.vTxt,
      selectedSharanas: [],
    });
    setSearchData([...tempData]);
    setWordData({
      mean: `I see not the eight prideful deluding women within me.
                Like the creeping plant that consumes the leaves yet leaves behind the thorn,
                The body and soul clash.
                Reading the Vedas, scriptures, and Puranas, they pose as knowledgeable,
                But they are nothing but shameless fools.
                Claiming to know but failing to act, the ignorant souls are praised.
                Our supreme guru, Siddhamallinath Prabhu, please grant your grace.`,
    });
  };

  return (
    <ComingSoon />
    // <div className='w-full sm:w-screen-13rem h-screen sm:h-screen-3rem overflow-y-scroll scrollbar-hide'>
    //   <div className={`flex flex-col p-8 sticky top-0 bg-Surface ${isEmpty(searchData) ? '' : 'border-b-2'}`}>
    //     <div className='w-full py-[14px]'>
    //       <div onClick={handleBack} className='flex items-center gap-1.5' >
    //         <BackArrowIcon />
    //         <h1 className='font-denton font-medium text-[26px] leading-[38px]'>Dictionary</h1>
    //       </div>
    //       <div className='font-dmSans text-sm font-medium text-[#545045] pt-2 ps-5'>
    //         Enter the word to search for its meaning
    //       </div>
    //     </div>
    //     <div className=''>

    //       <GBSearch
    //         className='w-full flex text-[#545045]'
    //         isIcon={false}
    //         onEnter={handleSearch}
    //         placeholder='Enter here'
    //         inputStyle='flex font-dmSans gap-x-2.5 px-[22px] py-[15px] min-h-[66px] bg-white w-full max-w-[393px] rounded-full text-sm focus:ring-1 focus:ring-Primary shadow-md'
    //       />
    //     </div>
    //     <div className='relative max-sm:mt-6 mt-7'>
    //       {
    //         isEmpty(wordData) || loading ? null : (
    //           <div>
    //             <h3 className='font-dmSans text-xs tracking-[16%] font-bold uppercase'>Meaning</h3>
    //             <div className='font-dmSans text-base font-normal'>

    //               {
    //                 map(wordData.mean.split('\n'), (text, index) => <p key={index}>{text}</p>)
    //               }
    //             </div>
    //           </div>
    //         )
    //       }
    //     </div>

    //   </div>
    //   {
    //     !(isEmpty(searchData) || loading) ? <div className='p-8'>
    //       { // search happen
    //         true ? <div className='flex flex-col pb-8 gap-y-2'>
    //           <p className='font-dmSans text-[10px] font-bold leading-4 uppercase tracking-[16%]'>Related vachana for you</p>
    //           <p className='font-denton text-xl font-medium'>{searchedVachanas.totalCount} Vachanas</p>
    //           {/* replace 32 with data length */}
    //         </div> : null
    //       }
    //       <div className='w-full sm:w-fit'>
    //         {
    //           map(searchedVachanas.data, (vachanaItem, index) => (
    //             <div key={index}>
    //               {index ? <hr className='my-6' /> : null}

    //               <VachanaList
    //                 key={index}
    //                 {...{
    //                   title: ``,
    //                   id: vachanaItem.id,
    //                   content: vachanaItem.transliterated.txt,
    //                   author: vachanaItem.author.name,
    //                   totalVachanas: vachanaItem.author.vachanas,
    //                   image: vachanaItem.author.image || logo,
    //                 }}
    //                 path={"/vachana"}
    //               >
    //                 <VachanaRightArrow path='/vachana' id={vachanaItem.title} />
    //               </VachanaList>
    //             </div>
    //           ))
    //         }
    //       </div>
    //     </div> : null
    //   }

    // </div>
  );
}

export default Dictionary;
