import { db } from "../Firebase";
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import useAuthStore from "../store/UserStore";
import { orderBy } from "lodash";
import i18n from "i18next";
import { toast } from "react-toastify";

const currentLang = i18n.language;
export const addToBookmark = async (uid, vachanaId) => {
  const userRef = doc(db, "users", uid, "blogPins", vachanaId);
  try {
    await setDoc(userRef, { on: new Date() }, { merge: true });
    toast("Added to bookmarks", { type: "success" });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const removeFromBookmark = async (uid, vachanaId) => {
  const userRef = doc(db, "users", uid, "blogPins", vachanaId);
  try {
    await deleteDoc(userRef);
    toast("Removed from bookmarks", { type: "success" });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAllBlogPins = async (uid) => {
  const user = useAuthStore.getState().user;
  try {
    // Fetch user-pinned vachana IDs
    const userPinsRef = collection(db, "users", uid, "blogPins");
    const userPinsSnapshot = await getDocs(userPinsRef);

    const vachanaIds = userPinsSnapshot.docs.map((doc) => doc.id);

    // Return early if no pins exist
    if (!vachanaIds.length) {
      return [];
    }

    // Fetch all vachanas with a single query
    const vachanasRef = collection(db, "vachanas", currentLang, "vTxt");
    const vachanasQuery = query(
      vachanasRef,
      where(documentId(), "in", vachanaIds)
    );
    const vachanasSnapshot = await getDocs(vachanasQuery);
    const fetchPromises = vachanasSnapshot.docs.map((docRef) => {
      const vachanaData = docRef.data();
      const authorDocRef = vachanaData.author; // Assuming author is a DocumentReference

      if (authorDocRef) {
        return Promise.all([getDoc(authorDocRef)]).then(async ([authorDoc]) => {
          let authorData = null;
          if (authorDoc.exists()) {
            const authorNameDocRef = doc(
              db,
              `sharanas/${currentLang}/vTxt/${authorDoc.id}`
            );
            const authorNameDocSnap = await getDoc(authorNameDocRef);

            authorData = {
              ...(authorNameDocSnap.exists() ? authorNameDocSnap.data() : {}),
            };
          }

          return {
            id: docRef.id,
            ...vachanaData,
            author: authorData, // Include author details
          };
        });
      }

      return Promise.resolve({
        id: docRef.id,
        ...vachanaData,
        author: null, // No author reference
      });
    });

    return await Promise.all(fetchPromises);
    // Map and return vachanas
  } catch (error) {
    console.error("Error fetching blog pins:", error);
    return [];
  }
};

export const getUserRecentActivities = async (
  lang = currentLang,
  limitCount = 20
) => {
  const user = useAuthStore.getState().user;
  try {
    const recentActivityRef = collection(
      db,
      "users",
      user.id,
      "recentActivities"
    );
    const recentActivitySnapshot = await getDocs(
      query(recentActivityRef, orderBy("on", "desc"), limit(limitCount))
    );
    const vachanaIds = recentActivitySnapshot.docs.map((doc) => doc.id);
    // Return early if no pins exist
    if (!vachanaIds.length) {
      return [];
    }

    // Fetch all vachanas with a single query
    const vachanasRef = collection(db, "vachanas", lang, "vTxt");
    const vachanasQuery = query(
      vachanasRef,
      where(documentId(), "in", vachanaIds)
    );
    const vachanasSnapshot = await getDocs(vachanasQuery);

    const fetchPromises = vachanasSnapshot.docs.map((docRef) => {
      const vachanaData = docRef.data();
      const authorDocRef = vachanaData.author; // Assuming author is a DocumentReference

      if (authorDocRef) {
        return Promise.all([getDoc(authorDocRef)]).then(async ([authorDoc]) => {
          let authorData = null;
          if (authorDoc.exists()) {
            const authorNameDocRef = doc(
              db,
              `sharanas/${lang}/vTxt/${authorDoc.id}`
            );
            const authorNameDocSnap = await getDoc(authorNameDocRef);

            authorData = {
              ...(authorNameDocSnap.exists() ? authorNameDocSnap.data() : {}),
            };
          }

          return {
            id: docRef.id,
            ...vachanaData,
            author: authorData, // Include author details
          };
        });
      }

      return Promise.resolve({
        id: docRef.id,
        ...vachanaData,
        author: null, // No author reference
      });
    });

    return await Promise.all(fetchPromises);
  } catch (error) {
    console.error("Error fetching recent activity:", error);
    return [];
  }
};

export const addRecentActivitiesWithVachanaId = async (vachanaId) => {
  const user = useAuthStore.getState().user;
  const userRef = doc(db, "users", user.id, "recentActivities", vachanaId);
  try {
    await setDoc(userRef, { on: new Date() }, { merge: true });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getVachana = async (id, lang = currentLang) => {
  const vachanasRef = collection(db, "vachanas", lang, "vTxt");
  const vachanaDocRef = doc(vachanasRef, id);
  try {
    const vachanaDocSnap = await getDoc(vachanaDocRef);
    if (vachanaDocSnap.exists()) {
      const vachanaData = vachanaDocSnap.data();
      return {
        id,
        ...vachanaData,
      };
    }

    return null;
  } catch (error) {
    console.error("Error fetching vachana by ID:", error);
    return null;
  }
};
