// src/commonStore.js
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { db } from "../Firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

const useCommonStore = create(
  persist(
    (set, get) => ({
      languages: [],
      error: null,
      loading: false,
      getAllLanguages: async () => {
        try {
          set({ loading: true });
          const querySnapshot = await getDocs(
            query(
              collection(db, "languages"),
              where("status", "==", true),
              orderBy("order", "asc")
            )
          );
          const languages = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          set((state) => ({
            languages,
            loading: false,
          }));
        } catch (error) {
          console.error("Error getting languages: ", error);
          set((state) => ({
            error: error.message,
            loading: false,
          }));
        }
      },
      reset: () => set({ languages: [], error: null, loading: false }),
    }),
    {
      name: "common-storage",
      getStorage: () => localStorage,
    }
  )
);

export default useCommonStore;
