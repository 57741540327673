import { map } from 'lodash';
import React from 'react';
import MediaPlayer from './MediaPlayer';
import { useNavigate } from 'react-router-dom';
import { BackArrowIcon } from '../assets/icons/CustomIcons';


function ActivityInstruction() {
    const data = [
        {
            title: 'Find a Comfortable Position',
            description: 'Sit comfortably with your spine straight and hands resting gently on your lap or knees. Close your eyes and take a few deep breaths. Inhale deeply through your nose, and exhale slowly through your mouth.',
            time:'15'
        },
        {
            title: 'Settle Your Breath',
            description: 'Begin to breathe naturally. Let your breath be calm and steady. Focus on the rhythm of your breathing as you prepare to start chanting.',
            time:'2'
        },
        {
            title: 'Settle Your Breath',
            description: 'Begin to breathe naturally. Let your breath be calm and steady. Focus on the rhythm of your breathing as you prepare to start chanting.',
            time:'2'
        },
        {
            title: 'Settle Your Breath',
            description: 'Begin to breathe naturally. Let your breath be calm and steady. Focus on the rhythm of your breathing as you prepare to start chanting.',
            time:'2'
        },
        {
            title: 'Settle Your Breath',
            description: 'Begin to breathe naturally. Let your breath be calm and steady. Focus on the rhythm of your breathing as you prepare to start chanting.',
            time:'2'
        }
    ]
  return (
 
    <div className='flex flex-col gap-y-12 w-full max-w-[298px] sm:max-w-[412px]'>
            {
                map(data, (instruction, index)=>(
                    <div key={index} className='flex flex-col gap-y-2 items-center text-center'>
                        <div className='py-1.5 px-2.5 bg-LightGray rounded-full'>
                            <h2 className='font-denton text-base leading-4 tracking-[2%]'>
                                {index+1}
                            </h2>
                        </div>
                        <div className='flex flex-col gap-y-4'>
                            <div className='font-dmSans font-medium text-[10px] tracking-[16%] leading-4'>
                                {instruction.time} MINUTES
                            </div>
                            <div className='flex flex-col gap-y-4'>
                                <h2 className='text-base leading-4 tracking-[-4%] font-semibold'>
                                    {instruction.title}
                                </h2>
                                <p className='font-dmSans text-sm tracking-[-2%] text-GrayishBrown'>
                                    {instruction.description}
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
        
  )
}

export default ActivityInstruction;