import React, { useEffect, useState } from "react";
import logo from "../assets/images/png/Ellipse.png";
import { isEmpty, map } from "lodash";
import LetterSelector from "../components/LetterSelector";
import { BackArrowIcon } from "../assets/icons/CustomIcons";
import { useLocation, useNavigate } from "react-router-dom";
import GBButton from "../components/GBButton";
import closeIcon from "../assets/images/svg/closeIcon.svg";
import { useTranslation } from "react-i18next";
import useSharanasStore from "../store/SharanasStore";

function PickSharanas({handleShow}) {
  const {
    getAllSharanas,
    setSelectedFilterSharanas,
    selectedSharana,
    selectedFilterSharanas,
    sharanas,
    error,
    loading,
  } = useSharanasStore((state) => state);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedSharanas, setSelectedSharanas] = useState([]);
  const [selectedSharanasId, setSelectedSharanasId] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const commonContext = t("common");

  const handleBack = () => {
    navigate(-1); // This navigates back to the previous page
  };

  const handleClick = (e) => {
    setSelectedItem(e.target.textContent);
  };

  const handleUnselectAllSharanas = () => {
    setSelectedSharanas([]);
    setSelectedSharanasId([]);
  };

  const handleSelectSharanas = (data) => {
    console.log(selectedSharanasId);
    if (selectedSharanasId.includes(data.id)) {
      console.log("remove");
      setSelectedSharanasId([
        ...selectedSharanasId.filter((item) => item !== data.id),
      ]);
      setSelectedSharanas([
        ...selectedSharanas.filter((item) => item.id !== data.id),
      ]);
    } else {
      console.log("add");
      setSelectedSharanasId([...selectedSharanasId, data.id]);
      setSelectedSharanas([...selectedSharanas, data]);
    }
  };

  const onSubmit = () => {
    setSelectedFilterSharanas(selectedSharanas);
    // navigate("/search");
    handleShow()
  };
  useEffect(() => {
    if (isEmpty(sharanas.data)) getAllSharanas();
  }, []);

  useEffect(() => {
    setSelectedSharanas(selectedFilterSharanas);
    setSelectedSharanasId([...selectedFilterSharanas.map((item) => item.id)]);
  }, [selectedSharana]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen-90px sm:max-h-screen-3rem">
        Loading...
      </div>
    );
  if (error)
    return (
      <div className=" h-screen-90px sm:max-h-screen-3rem">Error: {error}</div>
    );

  return (
    <div className="relative bg-Surface overflow-y-scroll scrollbar-hide h-screen sm:max-h-screen-3rem sm:w-screen-13rem z-50">
      <div className="p-8 pb-4 gap-[26px] flex items-center">
        <div className="flex gap-1.5" onClick={handleShow}>
          <BackArrowIcon />
        </div>
        <h1 className="text-[26px] font-normal font-denton leading-[38px]">
          {commonContext.pickSharanas}
        </h1>
      </div>
      <div className=" bg-Surface flex flex-col sticky top-0 z-10 ">
        {/* chnage top-[-1px] */}
        {selectedSharanas.length ? (
          <div className="flex gap-x-1 overflow-x-scroll scrollbar-hide px-8 py-4 ">
            {map(selectedSharanas, (item, index) => (
              <div key={index} className="flex gap-x-1 items-center">
                {index ? (
                  <hr className="w-px h-[22px] inline-block bg-Gainsboro" />
                ) : null}
                <div className="flex gap-x-2 p-2.5 items-center w-max">
                  <div className="flex gap-x-1 items-center">
                    <img
                      src={item.image || logo}
                      alt=""
                      className="w-[22px] h-[22px]"
                    />
                    <h3 className="font-dmSans text-xs font-medium">
                      {item.name}
                    </h3>
                  </div>
                  <div>
                    <img
                      src={closeIcon}
                      alt="closeBtn"
                      className="w-full h-full"
                      onClick={() => handleSelectSharanas(item)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        <LetterSelector
          id="picksharanas"
          data={sharanas?.char || "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")}
          handleClick={handleClick}
          selectedChar={selectedItem || sharanas?.char[0]}
          className="px-8 py-2.5 border-y-2 bg-white h-14 sm:w-screen-13rem w-screen"
        />
      </div>
      <div className="relative">
        {map(sharanas?.char, (chr, key) => (
          <div
            key={key + "_vachanas"}
            className=" flex w-full max-sm:flex-col p-8 pt-0 gap-x-14 max-sm:relative"
          >
            <div
              id={`picksharanas_${chr}`}
              className={`relative max-sm:sticky ${
                selectedSharanas.length ? "max-sm:top-24" : "max-sm:top-10"
              } max-sm:bg-Surface pt-8`}
            >
              <h2
                className={`text-[32px] w-8 font-medium font-denton text-GoldenBrown sticky ${
                  selectedSharanas.length ? "top-32" : "top-20"
                }`}
              >
                {chr}
              </h2>
            </div>
            <div className="pt-8 flex flex-col gap-y-9">
              {sharanas?.data[chr]?.map((sharanasItem, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-2.5 lg:w-[500px] w-3/4 max-sm:w-full"
                  onClick={() => handleSelectSharanas(sharanasItem)}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex gap-x-2.5">
                      <img
                        src={sharanasItem.image || logo}
                        alt={sharanasItem.name}
                        className="w-9 h-9 bg-Surface rounded-full"
                      />
                      <div className="flex flex-col gap-x-2">
                        <h3 className="font-denton font-medium text-base">
                          {sharanasItem.name}
                        </h3>
                        <p className="font-dmSans font-medium text-xs">{`${sharanasItem.vachanas} ${commonContext.vachanas}`}</p>
                      </div>
                    </div>

                    <div>
                      <input
                        id="sharana-checkbox"
                        type="checkbox"
                        checked={selectedSharanasId.includes(sharanasItem.id)}
                        onChange={() => handleSelectSharanas(sharanasItem)}
                        className="checked-icon w-6 h-6 rounded-full align-middle border border-LightTeal appearance-none outline-none cursor-pointer relative hover:bg-white checked:bg-white checked:border-Primary"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="sticky bottom-[-1px]">
        {/*change  bottom-[-2px]*/}
        <div className="bg-gradient-to-t from-Surface h-10 w-full"></div>
        <div className="flex w-full justify-between bg-Surface p-8 pt-0 sm:pr-[89px]">
          <GBButton
            handleClick={handleUnselectAllSharanas}
            className="px-2.5 py-[17px] font-dmSans font-medium text-Primary text-sm w-[125px]"
          >
            {commonContext.clearAll}
          </GBButton>
          <GBButton
            handleClick={onSubmit}
            className="px-2.5 py-[17px] bg-Secoundary rounded-full font-dmSans font-medium text-EerieBlack text-sm w-[125px]"
          >
            {commonContext.apply}
          </GBButton>
        </div>
      </div>
    </div>
  );
}

export default PickSharanas;
