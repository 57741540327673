import React from "react";
import useAuthStore from "../store/UserStore";
import Details from "../components/Details";
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
  const navigate = useNavigate();
  const { updateUser, user } = useAuthStore((state) => state);
  const handleFormData = (data) => {
    updateUser(user.id, { ...data });
    navigate("/profile");
  };
  return (
    <div className="flex sticky flex-col justify-between h-screen  px-4 max-sm:py-6 pb-[4.5rem]">
      <Details
        title="Edit"
        handleFormData={handleFormData}
        handleBackClick={() => navigate("/profile")}
      />
    </div>
  );
};

export default EditProfile;
