import React from 'react';

import img from '../assets/images/png/Rectangle 34625349.png'
import img1 from '../assets/images/png/Rectangle 34625349 (1).png'
import img2 from '../assets/images/png/Rectangle 34625349 (2).png'
import img3 from '../assets/images/png/Rectangle 34625349 (3).png'
import img4 from '../assets/images/png/Rectangle 34625349 (4).png'
import img5 from '../assets/images/png/Rectangle 34625349 (5).png'
import img6 from '../assets/images/png/Rectangle 34625349 (6).png'
import img7 from '../assets/images/png/Rectangle 34625349 (7).png'
import Activities from '../components/Activities';

function ActivitySuggestions() {
    const data = [
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img
        },
        {
            label:'Mindfulness',
            description:"15 minutes",
            image: img1
        },
        {
            label:'Mindfulness',
            description:"15 minutes",
            image: img2
        },
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img3
        },
        {
            label:'Breathing Exercises',
            description:"15 minutes",
            image:img4
        },
        {
            label:'Mindfulness',
            description:"15 minutes",
            image: img5
        },
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img6
        },
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img7
        },
        {
            label:'Mindfulness',
            description:"15 minutes",
            image: img2
        },
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img3
        },
        {
            label:'Breathing Exercises',
            description:"15 minutes",
            image:img4
        },
        {
            label:'Mindfulness',
            description:"15 minutes",
            image: img5
        },
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img6
        },
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img7
        },
        {
            label:'Mindfulness',
            description:"15 minutes",
            image: img2
        },
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img3
        },
        {
            label:'Breathing Exercises',
            description:"15 minutes",
            image:img4
        },
        {
            label:'Mindfulness',
            description:"15 minutes",
            image: img5
        },
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img6
        },
        {
            label:'Mantra Repetition',
            description:"15 minutes",
            image: img7
        },
    ]
  return (
    <div className=' w-full flex flex-col justify-center gap-y-6'>
        <div className='px-8 sm:px-11'>
            <h1 className='font-denton font-medium text-xl'> More for you</h1>
        </div>
        <div className='px-8 sm:px-11 flex flex-col items-center '>
            <Activities data={data} />
        </div>
        
    </div>
  )
}

export default ActivitySuggestions;