import React from 'react'
import { useNavigate } from 'react-router-dom'

function ImageCard({label='', description='', image='', path='/meditationActivity'}) {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(path)
    }
  return (
    <div onClick={handleClick} className='relative 2xl:w-fit'>
        <img src={image} alt="" className='w-full sm:w-44 sm:h-52 xl:w-full 2xl:w-56 xl:h-auto rounded-[14px] z-0' />
        <div className='text-Surface absolute left-4 bottom-5 sm:bottom-4 z-20'>
            <h2 className='font-denton text-base font-medium'>
                {label}
            </h2>
            <p className='font-dmSans text-xs leading-[18px] tracking-[-1%]'>
                {
                    description
                }
            </p>
        </div>
        <div className='bg-gradient-to-t from-EerieBlack h-3/5 w-full absolute z-10 bottom-0 rounded-[14px]'></div>
        
    </div>
  )
}

export default ImageCard

//  Audito src
// https://stream.mux.com/O4h5z00885HEucNNa1rV02wZapcGp01FXXoJd35AHmGX7g/audio.m4a

