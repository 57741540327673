import React, { useEffect, useState } from "react";
import useAuthStore from "../store/UserStore";
import Logo from "../assets/images/png/logo.png";
import { RxAvatar } from "react-icons/rx";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import commonData from "../commonData";
import { CiGlobe } from "react-icons/ci";
import profilePic from "../assets/navBarIcons/userProfile.png";
import { toTitleCase } from "../services/utils";
import CustomMusicPlayer from "./CustomMusicPlayer";
import useAudioStore from "../store/audioStore";
import { isEmpty } from "lodash";

const defaultIcons = {
  home: require("../assets/navBarIcons/default/Home.png"),
  vachanas: require("../assets/navBarIcons/default/Vachana.png"),
  meditation: require("../assets/navBarIcons/default/Meditation.png"),
  music: require("../assets/navBarIcons/default/Music.png"),
  dictionary: require("../assets/navBarIcons/default/Dictionary.png"),
  search: require("../assets/navBarIcons/default/Search.png"),
  musicSearch: require("../assets/navBarIcons/default/Search.png"),
  sharanas: require("../assets/navBarIcons/default/Sharana.png"),
  returnHome: require("../assets/navBarIcons/default/ReturnHome.png"),
  stats: require("../assets/navBarIcons/default/stats.png"),
};
const selectedIcons = {
  home: require("../assets/navBarIcons/clicked/Home.png"),
  vachanas: require("../assets/navBarIcons/clicked/Vachana.png"),
  meditation: require("../assets/navBarIcons/clicked/Meditation.png"),
  music: require("../assets/navBarIcons/clicked/Music.png"),
  dictionary: require("../assets/navBarIcons/clicked/Dictionary.png"),
  search: require("../assets/navBarIcons/clicked/Search.png"),
  musicSearch: require("../assets/navBarIcons/clicked/Search.png"),
  sharanas: require("../assets/navBarIcons/clicked/Sharana.png"),
  returnHome: require("../assets/navBarIcons/clicked/ReturnHome.png"),
  stats: require("../assets/navBarIcons/clicked/stats.png"),
};

const Header = ({ component }) => {
  const home =
    window.location.pathname === "/" ||
    window.location.pathname.split("/")[1] === "home";
  const user = useAuthStore((state) => state.user);
  const { currentAudio } = useAudioStore((state) => state);
  const [selectedSidebarTab, setSelectedSidebarTab] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const commonContext = t("common");

  useEffect(() => {
    if (home) {
      setSelectedSidebarTab("home");
    } else {
      setSelectedSidebarTab(location.pathname.split("/")[1]);
    }
  }, [location]);

  const changeLanguage = () => {
    navigate("/languageChange");
  };

  const navHeader = () => {
    return (
      <nav
        className={`fixed top-0 z-50 w-full border-0 max-sm:pt-2 sm:border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 bg-[#FBFAF3]`}
      >
        <div className="px-2 py-1 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between ">
            <div className="flex items-center justify-start">
              <a href="/" className="flex ml-2 md:mr-24">
                <img src={Logo} className="h-10 mr-3" alt="Logo" />
              </a>
            </div>

            <div className="flex items-center gap-4">
              <div
                className="flex items-center border border-gray-400 rounded-lg px-2 py-1 text-[#0B6062]"
                onClick={changeLanguage}
              >
                <CiGlobe className="w-6 h-6 mr-2" />
                {toTitleCase(user?.primary_language || "")}
              </div>
              <div className="relative group">
                <button
                  type="button"
                  onClick={() => navigate("/profile")}
                  className="flex items-center text-sm text-gray-900 dark:text-white focus:outline-none"
                  style={{ right: 0, left: "auto" }}
                >
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="w-8 h-8 rounded-full"
                    src={user?.photo ? user.photo : profilePic}
                    alt="user"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  };
  const rightSideHeader = (selectedItem) => {
    return (
      <ol className="flex sm:block max-sm:justify-between w-full sm:space-y-6">
        {commonData[selectedItem]?.fields.map((field, index) => (
          <li className={`p-1 max-sm:w-full max-sm:py-2`} key={index}>
            <Link to={field.link} className="flex row items-center">
              {field.link ? (
                <>
                  <div
                    className={`flex sm:gap-3 items-center max-sm:flex-col w-full ${
                      selectedSidebarTab.includes(field.path)
                        ? "max-sm:border-r-2 border-dashed border-GrayishBrown"
                        : ""
                    }`}
                  >
                    <img
                      src={
                        selectedSidebarTab === field.icon
                          ? selectedIcons[field.icon]
                          : defaultIcons[field.icon]
                      }
                      className="w-6 h-6"
                    />
                    <span
                      className={`font-dmSans text-xs ${
                        selectedSidebarTab === field.icon ? "text-Primary" : ""
                      }`}
                    >
                      {commonContext[field.name]}
                    </span>
                  </div>
                  <hr
                    className={`${
                      field.icon === "returnHome"
                        ? "sm:hidden xs:hidden"
                        : "hidden"
                    } h-[40px] w-0.25 border-l-2 border-dashed ml-auto`}
                  />
                </>
              ) : (
                <div className="flex w-full items-center justify-between">
                  <div className={`font-dmSans text-xs`}>
                    {commonContext[field.name]}
                  </div>
                  <hr className="w-[150px] h-0.25 border-t-2 ml-auto" />
                </div>
              )}
            </Link>
          </li>
        ))}
      </ol>
    );
  };
  return (
    <div className="h-full">
      <div className="sm:hidden xs:hidden">{home && navHeader()}</div>
      <div className="hidden sm:block">{navHeader()}</div>
      <aside
        className={`fixed  bottom-0 bg-[#FBFAF3] sm:left-0 z-40 sm:w-64  max-sm:w-full sm:h-screen sm:pt-14 max-sm:border-t sm:border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between`}
      >
        <div className={`sm:hidden xs:hidden w-full sm:pt-8 px-5`}>
          {rightSideHeader(selectedSidebarTab)}
        </div>
        <div className={`hidden sm:block w-full sm:pt-8 px-5`}>
          {rightSideHeader("all")}
        </div>
      </aside>
      <div className={`sm:hidden xs:hidden sm:ml-64 h-full relative ${home && "mt-14"}`}>
        {component}
      </div>
      <div className="hidden sm:block sm:ml-64 mt-14">{component}</div>
      {!isEmpty(currentAudio) && <CustomMusicPlayer />}
    </div>
  );
};

export default Header;
