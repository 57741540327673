import React, { useEffect, useState } from 'react'
import { SearchIcon } from '../assets/icons/CustomIcons'
import Sanscript from '@indic-transliteration/sanscript';
import useCommonStore from "../store/CommonStore";
import { useTranslation } from "react-i18next";
import closeIcon from "../assets/images/svg/closeIcon.svg";



function GBSearch( {className='', isIcon=true, inputStyle='', isClear=false, value='', setValue=(val)=>console.log(val), placeholder='Search vachana here', onEnter=(values)=>{console.log(values)}}) {
  const [searchVal, setSearchVal] = useState(value)
  const [searchTxt, setSearchTxt] = useState(value)
  const [errorTxt, setErrorTxt] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const { languages } = useCommonStore((state) => state);
  const { t, i18n } = useTranslation();

  const handleClear = () => {
    setErrorTxt('')
    setSearchTxt('')
    setSearchVal('')
  }
  
  const handleChange = (event) => {
    const inputText = event.target.value.toLowerCase();    
    const isEnText = 'abcdefghijklmnopqrstuvwxyz'.includes(inputText.trim().slice(-1)||''.toLowerCase())
    
    let vText = '';
    if (i18n.language.includes('en')) {      
      vText = inputText
      setSearchTxt(inputText)
    } else if (searchVal.length < inputText.length && isEnText) {  // Check if text is increasing in length (i.e., typing a new character)    
      // Update the search text progressively
      setSearchTxt(prev => prev + inputText.slice(searchVal.length));
        
      // Perform transliteration from ITRANS to Kannada (not Devanagari)
      vText = Sanscript.t(searchTxt + inputText.slice(searchVal.length), 'itrans', selectedLanguage.script).replace(/्/g, ''); // 'kn' for Kannada
    } else if (searchVal.slice(0,-1) === inputText) {
      vText = Sanscript.t(searchTxt.slice(0,-1), 'itrans', selectedLanguage.script).replace(/्/g, '');
      setSearchTxt( pre => pre.slice(0,-1))
    }else {
      // For text reduction (backspace or delete)
      setSearchTxt(Sanscript.t(inputText, selectedLanguage.script, 'itrans'))
      vText = inputText
    }  
    // Update state with the translated text
    setSearchVal(vText);
    setValue(vText)
  };

  const handleKeyPress = (event) => {    
    if (event.keyCode === 13) {

      if (searchTxt.length>=3){
        setErrorTxt('')
        onEnter({
          originalTxt: searchTxt,
          vTxt: searchVal
        });
      } else {
        setErrorTxt("Minimum 3 letters required");
      }
    }
  };

  useEffect(() => {
      setSelectedLanguage(languages.find(language => language.i18n === i18n.language))
  }, [i18n, i18n.language]);
  
  return (
    <div>

      <div className={className}>
        {isIcon?<SearchIcon width={22} height={22}/>:null }
        <input
        className={`h-full w-full border-0 focus:ring-0 focus:outline-none text-sm placeholder-opacity-100 ${inputStyle}`}
        type="text" 
        lang="hi"
        value={searchVal}       
        name="search" 
        id="search" 
        onKeyDown={handleKeyPress}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {
        (isClear && searchTxt)? <div onClick={handleClear}>

          <img className='absolute px-[22px] right-0 py-4' src={closeIcon} alt="close" />
        </div>
        :null
      }
      </div>
      {errorTxt?<p className='px-6 py-1 text-Error'>{errorTxt}</p>:null}
    </div>
  )
}

export default GBSearch
