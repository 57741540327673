import React from "react";

function Error404() {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen space-y-4">
      <div>
        <h1 className="text-6xl font-bold mb-2">404</h1>
      </div>
      <div>
        <h2 className="text-4xl font-semibold">Page Not Found</h2>
      </div>
    </div>
  );
}

export default Error404;
