import React, { useState } from 'react';
import { BackArrowIcon } from '../assets/icons/CustomIcons';
import { useNavigate, useLocation } from 'react-router-dom';
import MediaPlayer from '../components/MediaPlayer';
import { useTranslation } from 'react-i18next';
import bookIcon from '../assets/images/svg/bookIcon.svg';
import { ToastContainer } from 'react-toastify';
import logo1 from '../assets/images/svg/logo1.svg';
import useAudioStore from '../store/audioStore';

function VachanaAudio() {
    const navigate = useNavigate();
    const location = useLocation();
    const [audioTime, setAudioTime] = useState(0);
    const { currentAudio, currentTime } = useAudioStore();
    const isPlaying = useAudioStore((state) => state.isPlaying);
    const updateAudioState = useAudioStore((state) => state.updateAudioState)

    const { t } = useTranslation();
    const commonContext = t('common');

    const handleBack = () => {
        navigate(-1, { state: { x: audioTime } });
        updateAudioState(currentAudio,audioTime,isPlaying)
        // console.log('Navigating back with audioTime:', updateAudioState);
    };

    return (
        <div className='flex flex-col items-center w-full h-screen overflow-y-scroll scrollbar-hide gap-y-6'>
            <div className=' max-sm:fixed bg-Surface w-full px-8 py-[14px]'>
                <div onClick={() => handleBack()} className='flex gap-1.5'>
                    <BackArrowIcon />
                </div>
            </div>
            <div className="px-8 pb-[246px] max-sm:pt-[53px] scrool overflow-y-auto h-full">
                <div className='sm:w-full sm:max-w-xl flex flex-col gap-6'>
                    <div className='font-dmSans text-base'>
                        {currentAudio.lyrics.split('\n').map((text, index) => (
                            <p key={index} id={'text_' + index}>{text}</p>
                        ))}
                    </div>
                    <div className='flex gap-y-2.5 gap-x-1.5 items-center'>
                        <div className='w-[20px] h-[20px]'>
                            <img src={bookIcon} alt="bookIcon" />
                        </div>
                        <div className='font-dmSans text-xs items-center'>
                            <p>{commonContext.referedBook} : <span>{commonContext.vachanas}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-4 lg:left-64 w-[calc(100%-16rem)] z-30 max-sm:w-full">
                <div className='bg-white lg:flex lg:items-center justify-center relative lg:pt-6 lg:ps-8 lg:pe-12'>
                    <div className='flex gap-y-2  py-6 px-8 lg:p-0'>
                        <img src={logo1} className="w-14 h-14 sm:w-12 sm:h-12 object-cover rounded-md mr-2" />
                        <div className='flex flex-col'>
                            <h3 className='font-dmSans font-normal text-xs leading-4 tracking-[-4%]'>{currentAudio?.title || 'Default Title'}</h3>
                            <p className='font-dmSans font-medium text-xs'>{currentAudio?.author || 'Default Author'}</p>
                        </div>
                    </div>
                    <MediaPlayer audioSrc={currentAudio?.audioUrl} autoPlay={isPlaying} currentTime={currentTime} onTimeUpdate={setAudioTime} />
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default VachanaAudio;

