import React, { useEffect, useState, useRef } from "react";
import logo from "../assets/images/png/Ellipse.png";
import { get, isEmpty, map } from "lodash";
import LetterSelector from "../components/LetterSelector";
import SharanaList from "../components/SharanaList";
import { useTranslation } from "react-i18next";
import useSharanasStore from "../store/SharanasStore";

function Sharanas() {
  // const [isObserve, setIsObserve] = useState(true)
  const itemRefs = useRef({});

  const { getAllSharanas, sharanas, error, loading } = useSharanasStore(
    (state) => state
  );
  const language = useTranslation().i18n.language;
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedChar, setSelectedChar] = useState(0);

  const { t } = useTranslation();
  const commonContext = t("common");
  // const observerRef = useRef(); // Store observer instance

  const rangeSize = 0;
  const charRef =
    rangeSize > 1
      ? sharanas?.char.reduce((result, chr, num) => {
          const rangeStart = Math.floor(num / rangeSize) * rangeSize;
          const rangeEnd = rangeStart + rangeSize - 1;
          const rangeLabel = `${sharanas?.char[rangeStart]}-${
            sharanas?.char[Math.min(rangeEnd, sharanas?.char.length - 1)]
          }`;
          if (!result.includes(rangeLabel)) result.push(rangeLabel);
          return result;
        }, [])
      : [];

  const handleClick = (e) => {
    setSelectedItem(e.target.textContent);
  };

  const handleChar = (e) => {
    // setIsObserve(false)
    
      setSelectedChar(charRef.indexOf(e.target.textContent));
      setSelectedItem(e.target.textContent[0]);
    
    // setTimeout(()=>setIsObserve(true),3000)
  };

  useEffect(() => {
    getAllSharanas();
    // if (isEmpty(sharanas)) dispatch(fetchSharanas());
  }, [language]);

  // Observe each "sharanas_d" section to log active position
  // useEffect(() => {
  //   if (isObserve) {
  //   const options = {
  //     root: null,
  //     threshold: [
  //       0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6,
  //       0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1,
  //     ],
  //   };

  //   const observerCallback = (entries) => {
  //     entries.forEach((entry) => {
  //       // Check if the element is partially visible or intersecting at all
  //       if (entry.intersectionRatio > 0.3 && entry.isIntersecting) {
  //         // Further restrict by boundingClientRect to refine the logging criteria
  //         if (entry.boundingClientRect.y < 150) {
  //           const id = entry.target.id;
  //           // Update selected item and char based on the current entry
  //           setSelectedItem(id.split("_")[1]);
  //           if (rangeSize > 1) {
  //             setSelectedChar(
  //               Math.floor(sharanas?.char.indexOf(id.split("_")[1]) / rangeSize)
  //             );
  //           }
  //         }
  //       }
  //     });
  //   };

  //     observerRef.current = new IntersectionObserver(observerCallback, options);
  
  //     // Select and observe each section with an ID that starts with "sharanas_"
  //     const sections = document.querySelectorAll('[id^="sharanas_"]');
  //     sections.forEach((section) => observerRef.current.observe(section));
      
  //     // Cleanup observer on component unmount
  //     return () => observerRef.current.disconnect();
  //   }
  // }, [sharanas.char]);

  // useEffect(()=>{
  //   console.log(selectedChar,selectedItem);
  //   setSelectedItem(s)
    
  // },[selectedChar,sharanas.char])
  useEffect(() => {
    // Scroll the selected character into view
    const timer = setTimeout(() => {
      
      if (selectedItem && itemRefs.current[selectedItem]) {
        itemRefs.current[selectedItem].scrollIntoView({
          behavior: "smooth",
          block: 'start',
          inline: "start",
        });
      }
    }, 0);

    return () => clearTimeout(timer)
  }, [selectedItem]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen-90px sm:max-h-screen-3rem">
        Loading...
      </div>
    );
  if (error)
    return (
      <div className="h-screen-90px sm:max-h-screen-3rem">Error: {error}</div>
    );

  return (
    <div className="relative overflow-y-scroll scrollbar-hide h-screen-90px sm:max-h-screen-3rem">
      <div className="py-4 px-6  gap-[26px] flex flex-col ">
        <h1 className="text-[26px] font-normal font-denton leading-[38px]">
          {commonContext.exploreSharanas}
        </h1>
      </div>
      <div className="sticky top-0 z-10">
        {rangeSize > 1 ? (
          <LetterSelector
            id="sharanas"
            data={charRef || "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")}
            handleClick={handleChar}
            selectedChar={selectedChar===null?'':charRef[selectedChar]}
            className="px-8 py-2.5 border-y-2 bg-white h-14  w-full"
          />
        ) : null}
        <LetterSelector
          id="sharanas"
          data={
            rangeSize > 1
              ? sharanas?.char.slice(
                  selectedChar * rangeSize,
                  (selectedChar + 1) * rangeSize
                )
              : sharanas?.char
          }
          handleClick={handleClick}
          selectedChar={
            selectedItem===null?'': selectedItem || (sharanas?.char ? sharanas.char[0] : "")
          }
          className="px-8 py-2.5 border-y-2 bg-white h-14 w-full"
        />
      </div>
      <div className="px-6">
        {map(sharanas?.char, (chr, key) => (
          <div
            key={chr + "_sharanas"}
            className="flex w-full max-sm:flex-col pt-0 gap-x-14 max-sm:relative"
            >
            <div
              id={`sharanas_${chr}`}
              className={`relative max-sm:sticky ${
                rangeSize > 1 ? "max-sm:top-20" : "max-sm:top-10"
              } max-sm:bg-Surface pt-8`}
            >
              <h2
                ref={(el) => (itemRefs.current[chr] = el)}
                className={`text-[32px] w-8 font-medium font-denton text-GoldenBrown sticky ${
                  rangeSize > 1 ? "top-32" : "top-20"
                } uppercase`}
              >
                {chr}
              </h2>
            </div>
            <div className="pt-8 w-full">
              {sharanas?.data[chr]?.map((sharanaItem, index) => (
                <div key={index}>
                  {index ? <hr className="my-6 max-w-[500px]" /> : null}
                  <SharanaList
                    key={index}
                    {...{
                      id: sharanaItem.id,
                      author: sharanaItem.name,
                      totalVachanas: sharanaItem.vachanas,
                      image: sharanaItem.image || logo,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="sticky bottom-[-2px] bg-gradient-to-t from-Surface h-[76px] w-full"></div>
    </div>
  );
}

export default Sharanas;
