import { get, map } from 'lodash';
import React, { useState } from 'react';
import MediaPlayer from '../components/MediaPlayer';
import { useNavigate } from 'react-router-dom';
import { BackArrowIcon, BookmarkIcon } from '../assets/icons/CustomIcons';
import ActivityInstruction from '../components/ActivityInstruction';
import ActivitySuggestions from '../components/ActivitySuggestions';


function MeditationActivity() {
    const [mediaPlayerData, setMediaPlayerData] = useState({})
    
    const audioData = {
        title:'Mantra repetition',
        descripation: 'Focus on the universal mantra ‘Om’ to align mind, body, and soul.'
    }

    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1); // This navigates back to the previous page
      };

    const mediaPlayerHandler = (mediaPlayerInfo) => {
        console.log(mediaPlayerInfo, 'mediaPlayer');
        setMediaPlayerData({...mediaPlayerInfo})
    }
  return (
    <div className='flex flex-col  items-center w-full h-screen sm:h-screen-3rem overflow-y-scroll scrollbar-hide gap-y-4'>
        <div className='w-full px-8 py-[14px]'>
            <div onClick={handleBack} className='flex gap-1.5' >
                <BackArrowIcon />
            </div>
        </div>
        <div className='mb-32'>

            {
                get(mediaPlayerData, 'isPlaying')?<ActivityInstruction/>:<ActivitySuggestions/>
            }
        </div>
        <div className='fixed bottom-0 left-0 sm:left-auto right-0 sm:w-screen-13rem z-30'>
            {/* change  bottom-[-2px]*/}
            <div className='w-full h-40 bg-gradient-to-t from-Surface'></div>
            <div className='bg-white lg:flex lg:items-center justify-center relative lg:pt-6'>
                <div className='flex flex-col gap-y-2 w-3/4 lg:w-1/4 xl:w-[330px] p-7 lg:absolute lg:left-0 lg:top-0'>
                    <h3 className='font-dmSans font-semibold text-base leading-4 tracking-[-4%]'>{audioData.title}</h3>
                    <p className='font-dmSans font-normal text-xs'>{audioData.descripation}</p>
                </div>
                <div className='absolute right-8 top-12'>  
                    <BookmarkIcon />
                </div>
                <MediaPlayer handler={mediaPlayerHandler}/>
            </div>
        </div>
    </div>
  )
}

export default MeditationActivity;