import React, { useEffect, useRef } from "react";

function LetterSelector({
  handleClick,
  className = "",
  data = [],
  selectedChar = "A",
  id = "vachanas",
}) {
  const containerRef = useRef(null);
  const itemRefs = useRef({});

  useEffect(() => {
    // Scroll the selected character into view
    const timer = setTimeout(() => {
      
      if (selectedChar && itemRefs.current[selectedChar]) {
        itemRefs.current[selectedChar].scrollIntoView({
          behavior: "smooth",
          // block: "start",
          inline: "center",
        });
      }
    }, 0);

    return () => clearTimeout(timer)
  }, [selectedChar]);

  return (
    <div className={`relative ${className}`} ref={containerRef}>
      <div className="absolute top-0 left-0 h-14 w-14 linearGradient-to-r"></div>
      <div className="flex whitespace-nowrap space-x-1 sm:space-x-1 overflow-x-scroll scrollbar-hide sm:max-w-screen-14rem">
        <div className="w-14 p-1 opacity-0">-</div>
        {data.map((item, index) => (
          <a
            key={index}
            href={`#${id}_${item.includes("-") ? item.split("-")[0] : item}`}
          >
            <h2
              className={`${
                item === selectedChar ? "bg-Gainsboro rounded-full" : ""
              } hover:z-20 text-xs font-dmSans leading-[18px] px-[14px] py-[9px] h-fit uppercase`}
              ref={(el) => (itemRefs.current[item] = el)} // Store ref for each item
              onClick={handleClick}
            >
              {item}
            </h2>
          </a>
        ))}
        <div className="w-14 p-1 opacity-0">-</div>
      </div>

      <div className="absolute top-0 right-0 linearGradient-to-l h-14 w-14"></div>
    </div>
  );
}

export default LetterSelector;
