import React, { useState, useRef, useEffect } from 'react';
import pauseIcon from '../assets/images/svg/audioPauseIcon.svg';
import playIcon from '../assets/images/svg/audioPlayIcon.svg';
import forward5Icon from '../assets/images/svg/forward5SecIcon.svg';
import backward5Icon from '../assets/images/svg/backward5SecIcon.svg';
import previousSong from '../assets/images/svg/previousSong.svg';
import nextSong from '../assets/images/svg/nextSong.svg';
import useAudioStore from '../store/audioStore';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

const MediaPlayer = ({
  audioSrc,
  autoPlay = '',
  onPlayPauseToggle = () => {},
  onTimeUpdate
}) => {
  const audioRef = useRef(null);
  const isPlaying = useAudioStore((state) => state.isPlaying);
  const setIsPlaying = useAudioStore((state) => state.setIsPlaying);
  const songList = useAudioStore((state) => state.songList);
  const currentAudio = useAudioStore((state) => state.currentAudio);
  const currentTime = useAudioStore((state) => state.currentTime);
  const updateAudioState = useAudioStore((state) => state.updateAudioState);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const togglePlayPause = async () => {
    try {
      if (!audioRef.current) return;
      
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        await audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } catch (error) {
      console.error("Playback error:", error);
      setIsPlaying(false);
    }
  };

  const handleNextSong = () => {
    if (currentAudio?.id && songList.length > 0) {
      const currentIndex = songList.findIndex(song => song.id === currentAudio.id);
      if (currentIndex !== -1) {
        const nextSong = currentIndex === songList.length - 1 
          ? songList[0] 
          : songList[currentIndex + 1];
        updateAudioState(nextSong, 0, isPlaying);  
      }
    }
  };
  
  const handlePreviousSong = () => {
    if (currentAudio?.id && songList.length > 0) {
      const currentIndex = songList.findIndex(song => song.id === currentAudio.id);
      if (currentIndex !== -1) {
        const previousSong = currentIndex === 0 
          ? songList[songList.length - 1] 
          : songList[currentIndex - 1];
        updateAudioState(previousSong, 0, isPlaying);  
      }
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio || !audioSrc) return;

    const handleProgress = () => {
      if (!audio.duration) return;
      const currentProgress = (audio.currentTime / audio.duration) * 100;
      setProgress(currentProgress);
      if (onTimeUpdate) {
        onTimeUpdate(audio.currentTime);
      }
    };

    const handleLoadedData = () => {
      setDuration(audio.duration);
      if (currentTime > 0) {
        audio.currentTime = currentTime;
      }
    };

    const handleEnded = () => {
      setIsPlaying(false);
      handleNextSong();
    };

    // Set up audio
    audio.src = audioSrc;
    audio.currentTime = currentTime || 0;
    
    // Add event listeners
    audio.addEventListener('timeupdate', handleProgress);
    audio.addEventListener('loadeddata', handleLoadedData);
    audio.addEventListener('ended', handleEnded);

    // Initial play state
    if (!isPlaying) {
      audio.play().catch(error => {
        console.error("Playback failed:", error);
        setIsPlaying(false);
      });
    }

    return () => {
      audio.pause();
      audio.removeEventListener('timeupdate', handleProgress);
      audio.removeEventListener('loadeddata', handleLoadedData);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [audioSrc]);
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    if (!isPlaying) {
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error("Playback failed:", error);
          setIsPlaying(false);
        });
      }
    } else {
      audio.pause();
    }
  }, [isPlaying]);

  return (
    <div className="flex flex-col items-center w-full pt-4 lg:pt-0 px-8 pb-11">
      <audio ref={audioRef} src={audioSrc} />
      <div className='w-[100%]' >
        <div className="progress-bar h-1 bg-LightTeal">
          <div className={`progress h-1 bg-Primary`} style={{ width: `${progress}%` }} />
        </div>
        <div className='flex justify-between w-full'>
          <p>{formatTime(audioRef.current?.currentTime || 0)}</p>
          <p>{formatTime(duration)}</p>
        </div>
      </div>
      <div className="flex w-44 justify-between">
        <button className="pr-5" onClick={handlePreviousSong}>
          <img src={previousSong} />
        </button>
        <button onClick={() => audioRef.current.currentTime -= 5}>
          <img src={backward5Icon} alt="backward" />
        </button>
        <button onClick={togglePlayPause}>
          <img src={isPlaying ? pauseIcon : playIcon} alt="pause/play" />
        </button>
        <button className="pr-5" onClick={() => audioRef.current.currentTime += 5}>
          <img src={forward5Icon} alt="forward" />
        </button>
        <button onClick={handleNextSong}>
          <img src={nextSong} />
        </button>
      </div>
    </div>
  );
};

export default MediaPlayer;
