import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import en from './assets/locales/en';
// import hi from './assets/locales/hi';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector/cjs";
import languages from "./assets/locales/languages.json";
// console.log(languages);

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    returnObjects: true,
    resources: languages,
    debug: true,
    // lng: "kn", // Default language
    fallbackLng: "kn", // Fallback language if the selected one is missing
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
