// src/commonStore.js
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { db } from "../Firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import i18next from "i18next";
import { orderBy } from "lodash";

const useSharanasStore = create(
  persist(
    (set) => ({
      sharanas: { data: [], char: [] },
      selectedSharana: {},
      selectedFilterSharanas: [],
      vachanas: [],
      error: null,
      loading: false,
      getAllSharanas: async function () {
        try {
          set({ loading: true });
          const currentLang = i18next.language; // Get current language from i18n
          const querySnapshot = await getDocs(
            collection(db, `sharanas/${currentLang}/vTxt/`)
          );
          const data = [];
          const fetchPromises = querySnapshot.docs.map((docRef) => {
            return {
              id: docRef.id,
              ...docRef.data(),
            };
          });

          // Wait for all fetch requests to complete
          const results = await Promise.all(fetchPromises);

          // Push the fetched data into the final array
          data.push(
            results.reduce((obj, cur) => {
              // console.log(obj, cur.name[0], cur.name[0] in obj);

              if (cur.name[0] in obj) {
                obj[cur.name[0]].push(cur);
              } else {
                obj[cur.name[0]] = [cur];
              }
              return obj;
            }, {})
          );

          // Dispatch success action with the fetched data
          set({
            sharanas: { data: data[0], char: Object.keys(data[0]).sort() },
            error: null,
            loading: false,
          });
        } catch (error) {
          // Dispatch failure action if the request fails
          set({
            sharanas: { data: [], char: [] },
            error: null,
            loading: false,
          });
        }
      },
      setSelectedSharana: (selectedSharana) =>
        set({ selectedSharana, error: null }),
      setSelectedFilterSharanas: (selectedSharana) =>
        set({ selectedFilterSharanas: selectedSharana, error: null }),
      getVachanasBySharanaId: async (sharanaId) => {
        try {
          const currentLang = i18next.language; // Get current language from i18n

          set({ loading: true });
          const sharanaDoc = await getDoc(
            doc(db, `sharanas/${currentLang}/vTxt/${sharanaId}`)
          );
          const docsQuery = query(
            collection(db, `vachanas/${currentLang}/vTxt`),
            orderBy("transliterated.txt", "asc"),
            where(
              "author",
              "==",
              doc(db, `/sharanas/${currentLang}/vTxt/${sharanaId}`)
            )
          );
          const querySnapshot = await getDocs(docsQuery);

          const vachanas = querySnapshot.docs.map((docRef) => {
            
            return {
              id: docRef.id,
              ...docRef.data(),
              author: {
                ...sharanaDoc.data(),
              },
            };
          });

          const results = 
          await Promise.all(vachanas);
          results.sort((a, b) => {
            const textA = a.transliterated.txt.toLowerCase();
            const textB = b.transliterated.txt.toLowerCase();
            if (textA < textB) return -1; // Sort `a` before `b`
            if (textA > textB) return 1;  // Sort `b` before `a`
            return 0; // Keep the order if equal
        });

          const organizedData = results.reduce((obj, cur) => {
            const firstLetter = cur.transliterated?.txt?.[0] || "_";            
            if (!obj[firstLetter]) {
              obj[firstLetter] = [];
            }
            obj[firstLetter].push(cur);
            return obj;
          }, {});

          set(() => ({
            vachanas: {
              data: organizedData,
              char: Object.keys(organizedData).sort(),
              count: results.length,
              author: {
                id: sharanaDoc.id,
                ...sharanaDoc.data(),
              },
            },
            loading: false,
          }));
        } catch (error) {
          set({
            vachanas: [],
            error: null,
            loading: false,
          });
        }
      },
      reset: () => set({ sharanas: { data: [], char: [] }, error: null }),
    }),
    {
      name: "sharanas-storage",
      getStorage: () => localStorage,
    }
  )
);

export default useSharanasStore;
