import React, { useEffect, useState } from "react";
import GBLabel from "./GBLabel";
import LanguageSection from "./LanguageSection";
import { useTranslation } from "react-i18next";
import currentLang from "../i18n";
import GBButton from "./GBButton";
import { FaArrowRightLong } from "react-icons/fa6";
import useCommonStore from "../store/CommonStore";

function PrimaryLanguage({ handleNextClick, handleFormData }) {
  const { getAllLanguages } = useCommonStore((state) => state);
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(currentLang.language);

  const context = t("primaryLanguage");

  const commonContext = t("common");

  const changeLanguage = (value) => {
    setSelectedLang(value);
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
    document.documentElement.lang = i18n.language;
  }, [i18n, i18n.language]);

  const handleClick = () => {
    if (selectedLang.length === 0) {
      return;
    }
    handleFormData({ primary_language: selectedLang });
    handleNextClick();
  };

  useEffect(() => {
    getAllLanguages();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2">
        <GBLabel
          {...{
            label: context.title,
            desc: context.description,
            styleLabel: "text-[24px] font-normal font-denton leading-[36px]",
            styleDesc:
              "font-dmSans text-[14px] leading-[21px] text-GrayishBrown w-4/5",
          }}
        />
      </div>
      <div>
        <LanguageSection
          handleFun={changeLanguage}
          selectedLang={selectedLang}
        />
      </div>
      <div className="flex w-full justify-end">
        <GBButton
          className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full gap-1 w-[149px] h-[52px]"
          handleClick={handleClick}
        >
          <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
            {commonContext.next} <FaArrowRightLong className="ml-1" />
          </span>
        </GBButton>
      </div>
    </>
  );
}

export default PrimaryLanguage;
