// CustomMusicPlayer.js
import React, { useRef, useState, useEffect } from "react";
import useAudioStore from "../store/audioStore";
import pauseInSong from "../assets/images/svg/pauseInSong.svg";
import playbackInSong from "../assets/images/svg/playbackInsong.svg";
import forward5Icon from "../assets/images/svg/forward5SecIcon.svg";
import backward5Icon from "../assets/images/svg/backward5SecIcon.svg";
import previousSong from "../assets/images/svg/previousSong.svg";
import nextSong from "../assets/images/svg/nextSong.svg";
import bookIcon from '../assets/images/svg/bookIcon.svg';
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const CustomMusicPlayer = () => {
  const { currentAudio, updateAudioState, isPlaying: storeIsPlaying, setIsPlaying: setStoreIsPlaying, songList } = useAudioStore();
  // console.log('currentAudio:', currentAudio);
  const audioRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  let isPlayingActionInProgress = false;

  let playPauseTimeout;

  const { t } = useTranslation();
  const commonContext = t('common');

  const togglePlayPause = async () => {
    if (!audioRef.current) {
      console.error('Audio element not found');
      return;
    }

    if (isPlayingActionInProgress) {
      return;
    }

    isPlayingActionInProgress = true;
    try {
      if (!storeIsPlaying) {
        const allAudioElements = document.getElementsByTagName('audio');
        for (let audioElement of allAudioElements) {
          if (audioElement !== audioRef.current) {
            audioElement.pause();
          }
        }

        await audioRef.current.play().then(() => {
          setStoreIsPlaying(true);
        }).catch((error) => {
          console.error('Error playing audio, retrying:', error);
          setTimeout(() => {
            audioRef.current.play().then(() => {
              setStoreIsPlaying(true);
            }).catch((retryError) => {
              console.error('Retry failed:', retryError);
            });
          }, 500);
        });
      } else {
        audioRef.current.pause();
        setStoreIsPlaying(false);
      }
    } catch (error) {
      console.error('Playback error:', error);
      setStoreIsPlaying(false);
    } finally {
      isPlayingActionInProgress = false;
    }
  };
  

  const handleMediaPlayerClicked = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleNextSong = () => {
    if (currentAudio?.id && songList.length > 0) {
      const currentIndex = songList.findIndex(song => song.id === currentAudio.id);
      if (currentIndex !== -1) {
        const nextSong = currentIndex === songList.length - 1
          ? songList[0]
          : songList[currentIndex + 1];
        updateAudioState(nextSong, 0, true);
        if (audioRef.current) {
          audioRef.current.src = nextSong.url; 
          audioRef.current.currentTime = 0; 
          audioRef.current.play().catch(error => {
            console.error('Error playing audio:', error);
          });
          setStoreIsPlaying(true);
        } else {
          console.error('Audio element not initialized');
        }
      } else {
        console.error('Current audio not found in song list');
      }
    } else {
      console.error('No current audio or song list is empty');
    }
  };

  const handlePreviousSong = () => {
    if (currentAudio?.id && songList.length > 0) {
      const currentIndex = songList.findIndex(song => song.id === currentAudio.id);
      if (currentIndex !== -1) {
        const previousSong = currentIndex === 0
          ? songList[songList.length - 1]
          : songList[currentIndex - 1];
        updateAudioState(previousSong, 0, true);
        if (audioRef.current) {
          audioRef.current.src = previousSong.url; 
          audioRef.current.currentTime = 0; 
          audioRef.current.play().catch(error => {
            console.error('Error playing audio:', error);
          });
          setStoreIsPlaying(true);
        } else {
          console.error('Audio element not initialized');
        }
      } else {
        console.error('Current audio not found in song list');
      }
    } else {
      console.error('No current audio or song list is empty');
    }
  };

  const handleForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 5;
    }
  };

  const handleBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 5;
    }
  };

  const handleProgressBarClick = (event) => {
    const progressBar = event.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const clickPosition = event.clientX - rect.left;
    const percentage = (clickPosition / rect.width);

    if (audioRef.current && audioRef.current.duration) {
      const newTime = percentage * audioRef.current.duration;
      audioRef.current.currentTime = newTime;
      setProgress(percentage * 100);
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) {
      console.error('Audio element not found');
      return;
    }

    const handleLoadedMetadata = () => {
      // console.log('Audio metadata loaded:', audio.duration);
    };

    const handleTimeUpdate = () => {
      // console.log('Current time:', audio.currentTime);
      setProgress((audio.currentTime / audio.duration) * 100);
    };

    const handleEnded = () => {
      console.log('Current song ended, checking for next song...');
      if (currentAudio?.id && songList.length > 0) {
          const currentIndex = songList.findIndex(song => song.id === currentAudio.id);
          console.log('Current Index:', currentIndex);
          if (currentIndex !== -1) {
              const nextSong = currentIndex === songList.length - 1
                  ? songList[0]
                  : songList[currentIndex + 1];
              console.log('Next song:', nextSong);
              updateAudioState(nextSong, 0, true);
              audioRef.current.src = nextSong.url; 
              audioRef.current.currentTime = 0; 
              audioRef.current.play().then(() => {
                  console.log('Playing next song:', nextSong.name);
              }).catch(error => {
                  console.error('Error playing next song:', error);
              });
              setStoreIsPlaying(true);
          } else {
              console.error('Current audio not found in song list');
          }
      } else {
          console.error('No current audio or song list is empty');
      }
  };

    // console.log('Audio element ready, isPlaying:', storeIsPlaying);
    if (storeIsPlaying) {
      audio.play().catch(error => console.error('Error during play:', error));
    } else {
      audio.pause();
    }

    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("ended", handleEnded);
    };
  }, [storeIsPlaying, currentAudio, songList]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60) || 0;
    const secs = Math.floor(seconds % 60) || 0;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  if (!currentAudio?.url) return null;


  const fullScreenView = (
    <div className="fixed inset-0 bg-[#FBFAF3] z-50 flex flex-col">
      <div className="flex justify-between items-center p-4 border-b">
        <button onClick={() => setIsFullScreen(false)} className="text-[#0B6062]">
          <IoClose className="w-6 h-6" />
        </button>
      </div>

      <div className="flex-1 overflow-y-auto p-4">
        <div className="max-w-2xl mx-auto">
          {currentAudio?.desc && (
            <div className="mb-8 text-lg whitespace-pre-wrap p-4">
              {currentAudio.desc}
              <div className='flex gap-y-2.5 gap-x-1.5 items-center pt-2'>
                <div className='w-[20px] h-[20px]'>
                  <img src={bookIcon} alt="bookIcon" />
                </div>
                <div className='font-dmSans text-xs items-center'>
                  <p>{commonContext.referedBook} : <span>{currentAudio.name}</span></p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="border-t bg-white p-4">
        <div className="max-w-2xl mx-auto">
          <div className="flex items-center mb-4">
            <img
              src={currentAudio.albumArt}
              alt="logo"
              className="w-16 h-16 rounded-md mr-4"
            />
            <div>
              <p className="text-lg font-semibold line-clamp-1">
                {currentAudio.name}
              </p>
              <p className="text-base text-gray-600">
                {currentAudio.artist}
              </p>
            </div>
          </div>
          <div
            className="bg-[#B1CCCD] h-1 w-full cursor-pointer relative"
            onClick={handleProgressBarClick}
          >
            <div
              className="bg-[#0B6062] h-1 absolute top-0 left-0"
              style={{
                width: `${progress}%`,
              }}
            >
              <div className="absolute top-[-4px] right-[-4px] h-3 w-3 rounded-full bg-[#0B6062]" />
            </div>
          </div>
          <div className="flex justify-between text-sm text-gray-600 mt-1 mb-4">
            <span>{formatTime(audioRef.current?.currentTime)}</span>
            <span>{formatTime(audioRef.current?.duration)}</span>
          </div>

          <div className="flex justify-center items-center gap-6">
            <button onClick={handlePreviousSong}>
              <img src={previousSong} alt="Previous" className="w-8 h-8" />
            </button>
            <button onClick={handleBackward}>
              <img src={backward5Icon} alt="Backward 5s" className="w-8 h-8" />
            </button>
            <button
              onClick={togglePlayPause}
              className="w-14 h-14 rounded-full flex items-center justify-center"
            >
              <img
                src={storeIsPlaying ? pauseInSong : playbackInSong}
                alt="Play/Pause"
                className="w-8 h-8"
              />
            </button>
            <button onClick={handleForward}>
              <img src={forward5Icon} alt="Forward 5s" className="w-8 h-8" />
            </button>
            <button onClick={handleNextSong}>
              <img src={nextSong} alt="Next" className="w-8 h-8" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <audio ref={audioRef} src={currentAudio?.url}>
        Your browser does not support the audio element.
      </audio>
      {isFullScreen ? (
        fullScreenView
      ) : (
        <div className="fixed max-sm:bottom-14 bottom-0 left-0 right-0 bg-white shadow-lg border-t">
          <button
            className="absolute top-[-35px] right-0 bg-white text-[#0B6062] rounded-md"
            onClick={() => {
              setStoreIsPlaying(false);
              updateAudioState(null, 0, false);
            }}
          >
            <IoClose className="w-[40px] h-[40px]" />
          </button>
          <div className="sm:hidden xs:hidden">
            <div
              className="bg-[#B1CCCD] h-1 w-full cursor-pointer relative"
              onClick={handleProgressBarClick}
            >
              <div
                className="bg-[#0B6062] h-1 absolute top-0 left-0"
                style={{
                  width: `${progress}%`,
                }}
              />
            </div>
            <div
              className="flex justify-around px-2 py-2 cursor-pointer"
              onClick={handleMediaPlayerClicked}
            >
              <img
                src={currentAudio.albumArt}
                alt={"logo"}
                className="w-10 h-10 sm:w-12 sm:h-12 object-cover rounded-md mr-4"
              />
              <div>
                <p className="text-sm font-normal line-clamp-1">
                  {currentAudio.name}
                </p>
                <p className="text-sm mt-1 font-dmSans font-medium">
                  {currentAudio.artist}
                </p>
              </div>

              <img
                onClick={(e) => {
                  e.stopPropagation();
                  togglePlayPause();
                }}
                src={storeIsPlaying ? pauseInSong : playbackInSong}
                alt="Play/Pause"
                className="w-5 h-5 mt-3 mr-3"
              />
            </div>
          </div>
          <div className="hidden md:flex justify-between py-4 px-4 ml-64">
            <div className="w-[40%] flex items-center" onClick={handleMediaPlayerClicked}>
              <img src={currentAudio.albumArt} alt={"logo"} className="w-10 h-10 rounded-md mr-4 " />
              <div>
                <p className="text-sm font-normal line-clamp-1">
                  {currentAudio.name}
                </p>
                <p className="text-sm mt-1 font-dmSans font-medium">
                  {currentAudio.artist}
                </p>
              </div>
            </div>
            <div className="w-[60%] w-full">
              <div className="relative mx-10">
                <div
                  className="bg-[#B1CCCD] h-1 w-full cursor-pointer relative"
                  onClick={handleProgressBarClick}
                >
                  <div
                    className="bg-[#0B6062] h-1 absolute top-0 left-0"
                    style={{
                      width: `${progress}%`,
                    }}
                  >
                    <div className="absolute top-[-4px] h-3 w-3 rounded-full bg-[#0B6062] left-[calc(100%-10px)]" />
                  </div>
                </div>
                <div className="flex justify-between text-xs text-gray-600 mt-1">
                  <span>{formatTime(audioRef.current?.currentTime)}</span>
                  <span>{formatTime(audioRef.current?.duration)}</span>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <button
                  className="px-4 py-2 bg-gray-200 rounded"
                onClick={handlePreviousSong}
                >
                  <img src={previousSong} alt="Previous"/>
                </button>
                <button
                  className="px-4 py-2 bg-gray-200 rounded"
                  onClick={handleBackward}
                >
                  <img src={backward5Icon} alt="Backward 5s" />
                </button>
                <button
                  className="px-4 py-2 bg-blue-500 rounded"
                  onClick={togglePlayPause}
                >
                  <img
                    src={storeIsPlaying ? pauseInSong : playbackInSong}
                    alt="Play/Pause"
                    className="w-6 h-6"
                  />
                </button>
                <button
                  className="px-4 py-2 bg-gray-200 rounded"
                  onClick={handleForward}
                >
                  <img src={forward5Icon} alt="Forward 5s" />
                </button>
                <button
                  className="px-4 py-2 bg-gray-200 rounded"
                  onClick={handleNextSong}
                >
                  <img src={nextSong} alt="Next" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomMusicPlayer;
