const algoliasearch = require('algoliasearch');
const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);
const algoliaVachanas = algoliaClient.initIndex("vachanas");

function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function findDataById (data, targetId) {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const found = data[key].find(item => item.id === targetId);
      if (found) {
        return found; 
      }
    }
  }
  return null; 
};

export { toTitleCase, findDataById, algoliaVachanas };
