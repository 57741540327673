// src/VachanasStore.jsx
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { db } from "../Firebase";
import {
  collection,
  doc,
  endAt,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import i18n from "i18next";
import Sharana from "../views/Sharana";
import { filter, isEmpty } from "lodash";
import { findDataById } from "../services/utils";
import { algoliaVachanas } from "../services/utils";

const useVachanasStore = create(
  persist(
    (set, get) => ({
      vachanas: {},
      selectedVachana: {},
      searchedVachanas: {},
      todayVachana: {},
      bookMarkedVachanas: [],
      textSize: {
        preSize: "Small",
        size: "Small",
      },
      error: null,
      loading: false,
      getAllVachanas: async () => {
        try {
          set({ loading: true });
          const currentLang = i18n.language;
          const vachanas = doc(db, `vachanas/${currentLang}`);
          const vachanasSnapshot = await getDoc(vachanas);
          const docsQuery = query(
            collection(db, `vachanas/${currentLang}/vTxt`),
            orderBy("transliterated.txt", "asc"),
            limit(20) // Fetch 20 documents
          );

          const querySnapshot = await getDocs(docsQuery);

          // Fetch all necessary details for each document
          const fetchPromises = querySnapshot.docs.map((docRef, index) => {
            const vachanaData = docRef.data();
            const authorDocRef = vachanaData.author; // Assuming author is a DocumentReference

            if (authorDocRef) {
              return Promise.all([getDoc(authorDocRef)]).then(
                async ([authorDoc]) => {
                  let authorData = null;
                  if (authorDoc.exists()) {
                    const authorNameDocRef = doc(
                      db,
                      `sharanas/${currentLang}/vTxt/${authorDoc.id}`
                    );
                    const authorNameDocSnap = await getDoc(authorNameDocRef);

                    authorData = {
                      ...(authorNameDocSnap.exists()
                        ? authorNameDocSnap.data()
                        : {}),
                    };
                  }

                  return {
                    id: docRef.id,
                    vachanaNumber: index + 1,
                    ...vachanaData,
                    author: authorData, // Include author details
                  };
                }
              );
            }

            return Promise.resolve({
              id: docRef.id,
              ...vachanaData,
              author: null, // No author reference
            });
          });

          const results = await Promise.all(fetchPromises);

          // Organize data by the first letter of `transliterated.txt`
          const organizedData = results.reduce((obj, cur) => {
            const firstLetter =
              cur.transliterated?.txt?.[0]?.toUpperCase() || "_";
            if (!obj[firstLetter]) {
              obj[firstLetter] = [];
            }
            obj[firstLetter].push(cur);
            return obj;
          }, {});

          // Update the state with organized data
          set(() => ({
            vachanas: {
              ...vachanasSnapshot.data(),
              data: organizedData,
              char: Object.keys(organizedData).sort(),
              count: results.length,
              inProgress: false
            },
            loading: false,
          }));
        } catch (error) {
          console.error("Error fetching Vachanas:", error);
          set({ error, loading: false });
        }
      },
      getMoreVachanas: async (action, firstLetter) => {
        console.log(action, firstLetter, "<------- firstlatter with get more vachanas");
        const { vachanas } = get();
        console.log(vachanas.inProgress);

        if (vachanas.inProgress) {
          console.warn("Your request is already in progress.");
          return;
        }
        set(state => ({ vachanas: { ...state.vachanas, inProgress: true } }));

        try {
          const currentLang = i18n.language;
          const lastDoc = vachanas.data[firstLetter.toUpperCase()]?.at(-1) || null;
          const startIndex = lastDoc ? await getDoc(doc(db, `vachanas/${currentLang}/vTxt/${lastDoc.id}`)) : null

          const docsQuery = startIndex ?
            query(
              collection(db, `vachanas/${currentLang}/vTxt`),
              orderBy("transliterated.txt", "asc"),
              startAfter(startIndex), // Start after the last document from the previous query
              where("transliterated.txt", ">=", `${firstLetter}`),
              limit(20) // Fetch only 10 documents
            ) :
            query(
              collection(db, `vachanas/${currentLang}/vTxt`),
              orderBy("transliterated.txt", "asc"),
              where("transliterated.txt", ">=", `${firstLetter}`),
              limit(20) // Fetch only 10 documents
            );

          const querySnapshot = await getDocs(docsQuery);
          console.log(querySnapshot.docs.map(item => item.id));

          // Fetch details for each document
          const fetchPromises = querySnapshot.docs
            .slice(startIndex)
            .map((docRef, index) => {
              const vachanaData = docRef.data();
              const authorDocRef = vachanaData.author; // Reference to the author

              if (authorDocRef) {
                return Promise.all([getDoc(authorDocRef)]).then(
                  async ([authorDoc]) => {
                    let authorData = null;
                    if (authorDoc.exists()) {
                      const authorNameDocRef = doc(
                        db,
                        `sharanas/${currentLang}/vTxt/${authorDoc.id}`
                      );
                      const authorNameDocSnap = await getDoc(authorNameDocRef);

                      authorData = {
                        ...(authorNameDocSnap.exists()
                          ? authorNameDocSnap.data()
                          : {}),
                      };
                    }

                    return {
                      id: docRef.id,
                      vachanaNumber: vachanas.count + index + 1,
                      ...vachanaData,
                      author: authorData, // Add author details
                    };
                  }
                );
              }

              return Promise.resolve({
                id: docRef.id,
                ...vachanaData,
                author: null, // No author reference
              });
            });

          const results = await Promise.all(fetchPromises);

          // Organize the fetched data by transliterated text's first letter
          const organizedData = results.reduce(
            (obj, cur) => {
              const firstLetter =
                cur.transliterated?.txt?.[0]?.toUpperCase() || "_"; // Fallback to '_'
              if (!obj[firstLetter]) {
                obj[firstLetter] = [];
              }
              obj[firstLetter].push(cur);
              return obj;
            },
            { ...vachanas.data }
          );

          set((state) => ({
            vachanas: {
              ...state.vachanas,
              data: {
                ...organizedData, // Merge new data into the existing state
              },
              char: [
                ...new Set([
                  // ...state.vachanas.char,
                  ...Object.keys(organizedData),
                ]),
              ].sort(), // Update and sort character keys
              count: action + results.length,
              inProgress: false
              // lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Update the lastDoc reference
            },
            // loading: false,
          }));
        } catch (error) {
          console.error("Error fetching more Vachanas:", error);
          set({ error, loading: false });
        }
      },
      getMoreVachanasByChar: async (firstLetter) => {
        const { vachanas } = get();
        console.log(vachanas.inProgress);

        if (vachanas.inProgress) {
          console.warn("Your request is already in progress.");
          return;
        }
        set(state => ({ vachanas: { ...state.vachanas, inProgress: true } }));
        try {
          const currentLang = i18n.language;
          // return
          const lastDoc = vachanas.data[firstLetter.toUpperCase()]?.at(-1) || null;
          const startIndex = await getDoc(doc(db, `vachanas/${currentLang}/vTxt/${lastDoc.id}`))
          const docsQuery = query(
            collection(db, `vachanas/${currentLang}/vTxt`),
            orderBy("transliterated.txt", "asc"),
            startAfter(startIndex), // Start after the last document from the previous query
            where("transliterated.txt", ">=", `${firstLetter}`),
            where("transliterated.txt", "<", `${String.fromCharCode(firstLetter.charCodeAt(0) + 1)}`),
            limit(20) // Fetch only 10 documents
          );
          const querySnapshot = await getDocs(docsQuery);
          console.log(querySnapshot.docs.map(item => item.id));

          // Fetch details for each document
          const fetchPromises = querySnapshot.docs
            .slice(startIndex)
            .map((docRef, index) => {
              const vachanaData = docRef.data();
              const authorDocRef = vachanaData.author; // Reference to the author

              if (authorDocRef) {
                return Promise.all([getDoc(authorDocRef)]).then(
                  async ([authorDoc]) => {
                    let authorData = null;
                    if (authorDoc.exists()) {
                      const authorNameDocRef = doc(
                        db,
                        `sharanas/${currentLang}/vTxt/${authorDoc.id}`
                      );
                      const authorNameDocSnap = await getDoc(authorNameDocRef);

                      authorData = {
                        ...(authorNameDocSnap.exists()
                          ? authorNameDocSnap.data()
                          : {}),
                      };
                    }

                    return {
                      id: docRef.id,
                      vachanaNumber: vachanas.count + index + 1,
                      ...vachanaData,
                      author: authorData, // Add author details
                    };
                  }
                );
              }

              return Promise.resolve({
                id: docRef.id,
                ...vachanaData,
                author: null, // No author reference
              });
            });

          const results = await Promise.all(fetchPromises);

          // Organize the fetched data by transliterated text's first letter
          const organizedData = results.reduce(
            (obj, cur) => {
              const firstLetter =
                cur.transliterated?.txt?.[0]?.toUpperCase() || "_"; // Fallback to '_'
              if (!obj[firstLetter]) {
                obj[firstLetter] = [];
              }
              obj[firstLetter].push(cur);
              return obj;
            },
            { ...vachanas.data }
          );

          set((state) => ({
            vachanas: {
              ...state.vachanas,
              data: {
                ...organizedData, // Merge new data into the existing state
              },
              char: [
                ...new Set([
                  ...Object.keys(organizedData),
                ]),
              ].sort(), // Update and sort character keys
              count: state.vachanas.count + results.length,
              inProgress: false
            },
          }));
        } catch (error) {
          console.error("Error fetching more Vachanas:", error);
          set({ error, loading: false });
        }
      },
      getVachanaById: async (action) => {
        try {
          set({ loading: true });
          const currentLang = i18n.language;
          const id = action.id;
          const type = action.type;

          const { vachanas, selectedVachana } = get();


          const lastKey = Object.keys(vachanas.data).sort().at(-1); // Directly fetch the last key

          const foundObject =
            vachanas.data[lastKey].at(-1).id !== id
              ? findDataById(vachanas.data, id)
              : null;
          if (foundObject) {
            let flatData = [];
            Object.values(vachanas.data).forEach((arr) => {
              flatData = flatData.concat(arr);
            });

            const index = flatData.findIndex((item) => item.id === id);
            const previousId = index > 0 ? flatData[index - 1].id : null;
            const nextId =
              index < flatData.length - 1 ? flatData[index + 1].id : null;

            return set({
              selectedVachana: {
                ...foundObject,
                previous: previousId,
                next: nextId,
                totalVachanas: 20280,
              },
              loading: false,
              error: null,
            });
          }


          // Fetch the Vachana document from the new structure
          const docRef = doc(db, `vachanas/${currentLang}/vTxt`, id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const vachanaData = docSnap.data();
            let authorData = null;

            // Fetch author details if `author` is a DocumentReference
            if (vachanaData.author) {
              const authorDocRef = vachanaData.author; // Reference to the author
              const authorDocSnap = await getDoc(authorDocRef);

              if (authorDocSnap.exists()) {
                const authorNameDocRef = doc(
                  db,
                  `sharanas/${currentLang}/vTxt/${authorDocSnap.id}`
                );
                const authorNameDocSnap = await getDoc(authorNameDocRef);
                authorData = {
                  ...(authorNameDocSnap.exists()
                    ? {
                      authorId: authorDocSnap.id,
                      ...authorNameDocSnap.data(),
                    }
                    : {}),
                };
              }
            }

            const preVachana = await getDocs(
              query(
                collection(db, `vachanas/${currentLang}/vTxt`),
                orderBy("transliterated.txt", "desc"),
                startAfter(docSnap),
                limit(1)
              )
            )
            const NextVachana = await getDocs(
              query(
                collection(db, `vachanas/${currentLang}/vTxt`),
                orderBy("transliterated.txt", "asc"),
                startAfter(docSnap),
                limit(1)
              )
            )


            // let vachanaCount;
            // if (selectedVachana.next === id) {
            //   console.log('next count');

            //   vachanaCount = selectedVachana.vachanaNumber + 1
            // } else if (selectedVachana.previous === id){
            //   console.log(' pre count');

            //   vachanaCount = selectedVachana.vachanaNumber - 1
            // } else {
            //   console.log('fetch count');

            //   let docsCount = await getDocs(
            //     query(
            //       collection(db, `vachanas/${currentLang}/vTxt`),
            //       orderBy("transliterated.txt", "desc"),
            //       startAt(docSnap),
            //     )
            //   );
            //   vachanaCount = docsCount.docs.length
            // }

            const data = {
              id: docSnap.id,
              // vachanaNumber: vachanaCount,
              totalVachanas: 20280,
              ...vachanaData,
              author: authorData, // Include author details
              previous: preVachana.docs[0]?.id,
              next: NextVachana.docs[0]?.id,
            };

            set({
              selectedVachana: data,
              loading: false,
              error: null,
            });
          } else {
            throw new Error("No document found with the given ID");
          }
        } catch (error) {
          console.error("Error fetching Vachana:", error);
          set({ error, loading: false });
        }
      },
      getSharanasVachanaById: async (action) => {
        try {
          set({ loading: true });
          const currentLang = i18n.language;
          const id = action.id;
          const { selectedVachana } = get();

          // Fetch the Vachana document from the new structure
          const docRef = doc(db, `vachanas/${currentLang}/vTxt`, id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const vachanaData = docSnap.data();
            let authorData = null;

            // Fetch author details if `author` is a DocumentReference
            if (vachanaData.author) {
              const authorDocRef = vachanaData.author; // Reference to the author
              const authorDocSnap = await getDoc(authorDocRef);

              if (authorDocSnap.exists()) {
                const authorNameDocRef = doc(
                  db,
                  `sharanas/${currentLang}/vTxt/${authorDocSnap.id}`
                );
                const authorNameDocSnap = await getDoc(authorNameDocRef);
                authorData = {
                  ...(authorNameDocSnap.exists()
                    ? {
                      authorId: authorDocSnap.id,
                      ...authorNameDocSnap.data(),
                    }
                    : {}),
                };
              }
            }


            const preVachana = await getDocs(
              query(
                collection(db, `vachanas/${currentLang}/vTxt`),
                orderBy("transliterated.txt", "desc"),
                where(
                  "author",
                  "==",
                  doc(db, `/sharanas/${currentLang}/vTxt/${authorData.authorId}`)
                ),
                startAfter(docSnap),
                limit(1)
              )
            )
            const NextVachana = await getDocs(
              query(
                collection(db, `vachanas/${currentLang}/vTxt`),
                orderBy("transliterated.txt", "asc"),
                where(
                  "author",
                  "==",
                  doc(db, `/sharanas/${currentLang}/vTxt/${authorData.authorId}`)
                ),
                startAfter(docSnap),
                limit(1)
              )
            )


            let vachanaCount;
            if (selectedVachana.next === id) {
              console.log('next count');

              vachanaCount = selectedVachana.vachanaNumber + 1
            } else if (selectedVachana.previous === id) {
              console.log(' pre count');

              vachanaCount = selectedVachana.vachanaNumber - 1
            } else {
              console.log('fetch count');

              let docsCount = await getDocs(
                query(
                  collection(db, `vachanas/${currentLang}/vTxt`),
                  where(
                    "author",
                    "==",
                    doc(db, `/sharanas/${currentLang}/vTxt/${authorData.authorId}`)
                  ),
                  orderBy("transliterated.txt", "desc"),
                  startAt(docSnap),
                )
              );
              vachanaCount = docsCount.docs.length
            }

            const data = {
              id: docSnap.id,
              vachanaNumber: vachanaCount,
              totalVachanas: authorData.vachanas,
              ...vachanaData,
              author: authorData, // Include author details
              previous: preVachana.docs[0]?.id,
              next: NextVachana.docs[0]?.id,
            };

            set({
              selectedVachana: data,
              loading: false,
              error: null,
            });
          } else {
            throw new Error("No document found with the given ID");
          }
        } catch (error) {
          console.error("Error fetching Vachana:", error);
          set({ error, loading: false });
        }
      },

      getRandomVachana: async (todayVachana) => {
        try {
          set({ loading: true });
          const currentLang = i18n.language;
          const today = new Date().toISOString().slice(0, 10); // e.g., "2024-11-28"
          let vachanaIdToFetch;
      
          // Check if today's Vachana is already available
          if (todayVachana && todayVachana.today === today) {
            console.log("Using existing today's Vachana ID:", todayVachana.id);
            vachanaIdToFetch = todayVachana.id;
          } else {
            // Fetch all documents in `todaysVachana` to get a random Vachana ID
            const vachanaRootRef = collection(db, "todaysVachana");
            const vachanaRootSnapshot = await getDocs(vachanaRootRef);
      
            if (vachanaRootSnapshot.empty) {
              throw new Error("No subfolders found in todaysVachana.");
            }
      
            let allVachanaIds = [];
      
            // Collect Vachana IDs from Firestore
            vachanaRootSnapshot.docs.forEach((docSnapshot) => {
              const data = docSnapshot.data()?.Ids;
      
              if (data) {
                allVachanaIds = allVachanaIds.concat(data);
              }
            });
      
            if (allVachanaIds.length === 0) {
              throw new Error("No valid Vachana IDs found after merging.");
            }
      
            // Select a random ID from the merged array
            const randomIndex = Math.floor(Math.random() * allVachanaIds.length);
            vachanaIdToFetch = allVachanaIds[randomIndex];
            console.log("Randomly selected Vachana ID:", vachanaIdToFetch);
          }
      
          // Fetch Vachana data using the determined ID for the current language
          const vachanaRef = doc(db, `vachanas/${currentLang}/vTxt/${vachanaIdToFetch}`);
          const vachanaSnapshot = await getDoc(vachanaRef);
      
          if (!vachanaSnapshot.exists()) {
            throw new Error(`Vachana with ID ${vachanaIdToFetch} not found.`);
          }
      
          const vachanaData = { id: vachanaIdToFetch, ...vachanaSnapshot.data() };
      
          // Fetch author data if available
          const authorDocRef = vachanaData?.author;
          let authorData = null;
      
          if (authorDocRef) {
            const authorDocSnap = await getDoc(authorDocRef);
      
            if (authorDocSnap.exists()) {
              const authorNameDocRef = doc(
                db,
                `sharanas/${currentLang}/vTxt/${authorDocSnap.id}`
              );
              const authorNameDocSnap = await getDoc(authorNameDocRef);
      
              authorData = {
                ...(authorNameDocSnap.exists() ? authorNameDocSnap.data() : {}),
              };
            }
          }
      
          // Update state with the fetched Vachana and author data
          set({
            todayVachana: {
              ...vachanaData,
              today: today,
              author: authorData,
            },
            loading: false,
            error: null,
          });
        } catch (error) {
          console.error("Error fetching random Vachanas:", error);
          set({ error, loading: false });
        }
      },
      getSearchedVachanas: async (action) => {
        try {
          const { searchText, selectedSharanas } = action;
          set({ loading: true });
          const currentLang = i18n.language;

          // Build search filters
          const authorFilters = selectedSharanas
            .map((id) => `author.id:${id.trim()}`)
            .join(" OR ");

          const filters = selectedSharanas.length
            ? `(${authorFilters}) AND i18n: ${currentLang}`
            : `i18n: ${currentLang}`;

          const response = await algoliaVachanas.search(searchText, {
            filters: filters
          })

          const hits = response.hits;
          const nbHits = response.nbHits;

          const results = hits.map((hit, index) => ({
            id: hit.objectID,
            ...hit,
            vachanaNumber: index + 1,
            totalVachanas: nbHits,
          }));

          set({
            searchedVachanas: {
              data: results,
              totalCount: nbHits,
              searchedText: searchText,
              selectedSharanas,
              inProgress: false,
            },
            loading: false,
            error: null,
          });
        } catch (error) {
          console.error("Error fetching Vachanas:", error);
          set({ error, loading: false });
        }
      },
      
      getMoreSearchedVachanas: async () => {
        try {
          const { searchedVachanas } = get();
          const currentLang = i18n.language;
          const { searchedText, data, selectedSharanas } = searchedVachanas;
          const page = Math.ceil(data.length / 20); // Calculate the current page

          if (searchedVachanas.inProgress) {
            console.warn("Your request is already in progress.");
            return;
          }

          set((state) => ({
            searchedVachanas: {
              ...state.searchedVachanas,
              inProgress: true,
            },
            loading: false,
          }));
          const authorFilters = selectedSharanas
            .map((id) => `author.id:${id.trim()}`)
            .join(" OR ");

          const filters = selectedSharanas.length
            ? `(${authorFilters}) AND i18n: ${currentLang}`
            : `i18n: ${currentLang}`;

          const response = await algoliaVachanas.search(searchedText, {
            filters: filters,
            page
          })

          const hits = response.hits;
          const nbHits = response.nbHits;

          const results = hits.map((hit, index) => ({
            id: hit.objectID,
            ...hit,
            vachanaNumber: data.length + index + 1,
            totalVachanas: nbHits,
          }));

          set((state) => ({
            searchedVachanas: {
              ...state.searchedVachanas,
              data: [...state.searchedVachanas.data, ...results],
              inProgress: false,
            },
            loading: false,
          }));
        } catch (error) {
          console.error("Error fetching more Vachanas:", error);
          set((state) => ({
            searchedVachanas: {
              ...state.searchedVachanas,
              inProgress: false,
            },
            loading: false,
            error,
          }));
        }
      },

      checkUserBlog: async (userId) => {
        const userRef = collection(db, "users", userId, "blogPins");
        try {
          const querySnapshot = await getDocs(userRef);
          const vachanaIds = querySnapshot.docs.map((doc) => doc.id);
          set({ bookMarkedVachanas: vachanaIds });
        } catch (error) {
          console.error("Error fetching user blog:", error);
        }
      },

      setTextSize: (textSize) =>
        set((state) => ({
          textSize: { ...state.textSize, ...textSize },
          error: null,
        })),
      resetSearchedVachanas: () =>
        set({
          searchedVachanas: {},
        }),
      reset: () =>
        set({
          vachanas: [],
          todayVachana: {},
          bookMarkedVachanas: [],
          loading: false,
          error: null,
        }),
    }),
    {
      name: "vachanas-storage",
      getStorage: () => localStorage,
    }
  )
);

export default useVachanasStore;
