import React, { useCallback, useEffect, useState, useRef } from "react";
import VachanaList from "../components/VachanaList";
import logo from "../assets/images/png/Ellipse.png";
import { map } from "lodash";
import LetterSelector from "../components/LetterSelector";
import { useTranslation } from "react-i18next";
import VachanaRightArrow from "../components/VachanaRightArrow";
import GBScrollElement from "../components/GBScrollElement";
import useVachanasStore from "../store/VachanasStore";
import Bookmarked from "./Bookmarked";
import Activity from "../components/Activity";
import TodayVachana from "../components/TodayVachana";
import GBButton from "../components/GBButton";
import { useLocation } from "react-router-dom";

function Vachanas() {
  const language = useTranslation().i18n.language;
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const itemRefs = useRef({});
  const location = useLocation();
  const { state } = location || {};

  const { getAllVachanas, vachanas, getMoreVachanas, getMoreVachanasByChar } = useVachanasStore(
    (state) => state
  );

  // Add touch handling states
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const tabsRef = useRef(null);

  // Minimum swipe distance (in pixels)
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe && selectedTab < 3) {
      setSelectedTab(prev => prev + 1);
    }
    if (isRightSwipe && selectedTab > 0) {
      setSelectedTab(prev => prev - 1);
    } 
  };
  useEffect(() => {
    // Scroll the selected character into view
    const timer = setTimeout(() => {
      
      if (selectedItem && itemRefs.current[selectedItem]) {
        itemRefs.current[selectedItem].scrollIntoView({
          behavior: "smooth",
          block: 'start',
          inline: "start",
        });
      }
    }, 100);

    return () => clearTimeout(timer)
  }, [selectedItem]);

  // Add wheel event handler for horizontal scrolling
  const handleWheel = (e) => {
    if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
      if (e.deltaX > minSwipeDistance && selectedTab < 3) {
        setSelectedTab(prev => prev + 1);
      } else if (e.deltaX < -minSwipeDistance && selectedTab > 0) {
        setSelectedTab(prev => prev - 1);
      }
    }
  };

  useEffect(() => {
    const tabsElement = tabsRef.current;
    if (tabsElement) {
      tabsElement.addEventListener('wheel', handleWheel, { passive: true });
    }
    return () => {
      if (tabsElement) {
        tabsElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [selectedTab]);

  const { t } = useTranslation();
  const commonContext = t("common");
  const [dataLength, setDataLength] = useState(10);

  const handleMore = (char) => {    
    getMoreVachanasByChar(char?.toLowerCase())
  }

  const handleClick = (e) => {
    setSelectedItem(e.target.textContent);
    getMoreVachanas(vachanas?.count,e.target.textContent);
  };

  const handleScroll = useCallback(() => {
    getMoreVachanas(vachanas?.count, vachanas.char.at(-1)?.toLowerCase());
    setDataLength((pre) => pre + 10);
  }, [vachanas]);

  useEffect(() => {
    getAllVachanas(selectedItem);
    setSelectedTab(state?.selectedTab || 0)
  }, [language]);

  

  return (
    <div 
      className="relative overflow-y-scroll scrollbar-hide px-6 h-screen-90px sm:h-screen-3rem"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      ref={tabsRef}
    >
      <GBScrollElement
        handleScroll={handleScroll}
        isCompleted={vachanas.inProgress||(selectedTab === 0 ? false : true)}
      >
        <div className="pt-4 gap-[26px] flex flex-col">
          <h1 className="text-[26px] font-normal font-denton leading-[38px]">
            {commonContext.exploreVachanas}
          </h1>
        </div>
        <div className="sticky bg-Surface top-[-1px] z-10 border-none">
          <div className="pt-4">
            <div className="flex gap-4 overflow-y-auto scrollbar-hide border-b-2 " >
              <nav className="flex space-x-4" aria-label="Tabs">
                {[commonContext.all, commonContext.todayVachana, commonContext.activity, commonContext.bookmark].map((menuItem, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedTab(index)}
                    className={`font-dmSans text-sm font-medium whitespace-nowrap  ${
                      selectedTab === index
                        ? "border-b-2 border-[#4B7576]"
                        : "border-transparent"
                    } hover:border-gray-400 focus:outline-none focus:border-gray-500`}
                  >
                    {menuItem}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          {
            selectedTab === 0? <LetterSelector
            data={vachanas?.allVachanasFirstLetter?.sort()}
            handleClick={handleClick}
            selectedChar={selectedItem }
            className="px-8 py-2.5 border-b-2 bg-white h-14 w-full"
          />:null
          }
        </div>
        {selectedTab === 0 && (
          
            
           map(vachanas?.char, (chr, key) => (
              <div
                key={key + "_vachanas"}
                className=" flex w-full max-sm:flex-col pt-0 gap-x-14 py-6 max-sm:relative"
              >
                <div
                  id={`vachanas${chr}`}
                  className="relative max-sm:sticky top-20 sm:top-12 max-sm:bg-Surface pt-4"
                >
                  <h2 
                    ref={(el) => (itemRefs.current[chr] = el)}

                  className="text-[32px] w-8 font-medium font-denton text-GoldenBrown sticky sm:top-32">
                    {chr}
                  </h2>
                </div>
                <div className="pt-8">
                  {vachanas?.data[chr]?.map((vachanaItem, index) => (
                    <div key={index}>
                      {index ? <hr className="my-6" /> : null}
                      <VachanaList
                        key={index}
                        {...{
                          title: ``,
                          id: vachanaItem.id,
                          content: vachanaItem.transliterated.txt,
                          author: vachanaItem.author?.name,
                          totalVachanas: vachanaItem.author?.vachanas,
                          image: vachanaItem.author?.image || logo,
                        }}
                        path={"/vachana"}
                      />
                      <div className="flex justify-end mt-[-20px]">
                        <VachanaRightArrow
                          path="/vachana"
                          id={vachanaItem.title}
                        />
                      </div>
                    </div>
                  ))}
                  {vachanas.char.at(-1) !== chr?<div className="w-full flex justify-center items-center gap-4 p-4">
                    <hr  className="flex-grow h-1"/>
                  <GBButton
                    className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full gap-1 h-6"
                    value={chr}
                    handleClick={handleMore}
                  >
                    <span className="flex items-center gap-1 font-dmSans text-xs font-medium leading-[20px]">
                      {'More'}
                    </span>
                  </GBButton>
                  <hr className="flex-grow h-1"/>
                  </div>:null}
                </div>
                {
                  vachanas.allVachanasFirstLetter?.map((letter, index)=>vachanas.char.includes(letter)?null:<div ref={(el) => (itemRefs.current[letter] = el)} key={index} id={letter + "_vachanas"}></div>)
                }
              </div>
            ))
            
         
        )}
        {selectedTab === 1 && (
          <div className="max-w-xl py-1">
            <TodayVachana />
          </div>
        )}
        {selectedTab === 2 && (
          <div className="max-w-xl py-3">
            <Activity />
          </div>
        )}
        {selectedTab === 3 && (
          <div className="max-w-xl py-3">
            <Bookmarked />
          </div>
        )}
      </GBScrollElement>
      <div className="sticky bottom-[-1px] bg-gradient-to-t from-Surface h-[76px] w-full"></div>
    </div>
  );
}

export default Vachanas;
