import React from 'react'
import { ArrowSquareRightIcon } from '../assets/icons/CustomIcons'
import { useNavigate } from 'react-router-dom';

function VachanaRightArrow({path="/vachana",className='flex items-end', id=''}) {
    const navigate = useNavigate();
  return (
    <div className={`${className}`}>
      <ArrowSquareRightIcon onClick={()=>{navigate(path)}} />
    </div>
  )
}

export default VachanaRightArrow
