import React from 'react'

function GBButton({handleClick =() => {}, isSelected=false, value='', className='', type='button', children, disabled=false}) {
    const onClickHandler = () => {
        if (!disabled) {
            handleClick(value)
        }
    }
  return (
    <button
      type={type}
      disabled={disabled}
      className={`${!disabled && isSelected ? "bg-Secoundary" : ""} ${className}`}
      onClick={onClickHandler}
    >
        {children}
    </button>
  )
}

export default GBButton;
