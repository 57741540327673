import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get, map } from "lodash";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { BackArrowIcon, SettingIcon } from "../assets/icons/CustomIcons";
import RemoveIcon from "../assets/icons/remove.png";
import { GrFormEdit } from "react-icons/gr";
import logo from "../assets/images/png/Ellipse.png";
import VachanaList from "../components/VachanaList";
import GBButton from "../components/GBButton";
import VachanaRightArrow from "../components/VachanaRightArrow";
import useAuthStore from "../store/UserStore";
import { storage } from "../Firebase";
import {
  getAllBlogPins,
  getUserRecentActivities,
  removeFromBookmark,
} from "../services/apis";
import Bookmarked from "./Bookmarked";

const Profile = () => {
  const [selectedTab, setSelectedTab] = useState("bookmark");
  const [profilePic, setProfilePic] = useState(null);
  const [blogPins, setBlogPins] = useState([]);
  const [recentActivities, setRecentActivities] = useState([]);
  const { user, updateUser, getUser } = useAuthStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const commonContext = t("common");

  useEffect(() => {
    const fetchData = async () => {
      await getUser(user.id);
      setRecentActivities(await getUserRecentActivities());
      setBlogPins(await getAllBlogPins(user.id));
    };
    fetchData();
  }, [getUser, user.id]);

  const handleLogoUpload = async (file) => {
    const storageRef = ref(storage, `profile-pics/${user.uid}-${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    updateUser(user.id, { photo: downloadURL });
  };

  const handleAddProfilePic = (event) => {
    const file = event.target.files[0];
    setProfilePic(file);
    handleLogoUpload(file);
  };

  return (
    <div className="max-w-xl mx-auto">
      <div className="relative overflow-y-scroll scrollbar-hide px-5">
        <div className="flex flex-col gap-[22px]  justify-center items-center">
          <div className="flex justify-between w-full">
            <div
              onClick={() => navigate("/home")}
              className="flex gap-1.5 left-8 py-3.5"
            >
              <BackArrowIcon />
            </div>
            <Link to={"/settings"} className="flex gap-1.5 left-8 py-3.5">
              <SettingIcon />
            </Link>
          </div>
          <div className="flex flex-col gap-[22px] w-fit">
            <div className="flex flex-col gap-4 items-center">
              <div className="relative">
                <div
                  className="w-[80px] h-[80px] rounded-full overflow-hidden"
                  style={{ backgroundColor: "#E5E5E5" }}
                >
                  <img
                    src={
                      profilePic
                        ? URL.createObjectURL(profilePic)
                        : user?.photo
                    }
                    className="w-full h-full object-cover"
                    alt="Profile"
                  />
                </div>
                <div className="absolute top-0 right-0">
                  <GrFormEdit
                    className="w-[24px] h-[24px] text-Primary"
                    onClick={() =>
                      document.getElementById("profile-pic-input").click()
                    }
                  />
                </div>
              </div>
              <h1 className="text-2xl leading-[22px] font-denton font-medium">
                {user?.name}
              </h1>
            </div>
            <GBButton
              className="flex items-center justify-center border-Primary border-2 text-Primary px-3 py-2.5 rounded-full gap-1"
              handleClick={() => navigate("/editProfile")}
            >
              <span className="flex items-center gap-1 font-dmSans text-xs font-normal leading-[13px]">
                {"Edit profile"}
              </span>
            </GBButton>
          </div>
          <ol className="flex w-full gap-4 justify-center">
            {map(["bookmark", "activity"], (tab) => (
              <li
                key={tab}
                className={`flex gap-1.5 py-3.5 font-dmSans font-medium text-sm leading-[14px] ${
                  tab === selectedTab
                    ? "text-Primary border-b-Primary border-b-2"
                    : ""
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {commonContext[tab]}
              </li>
            ))}
          </ol>
          <input
            type="file"
            id="profile-pic-input"
            accept=".png, .jpg, .jpeg"
            onChange={handleAddProfilePic}
            style={{ display: "none" }}
          />
        </div>
        <div className="border-t-2 py-5">
          {selectedTab === "bookmark" && <Bookmarked />}
          {selectedTab === "activity" && (
            <div className="flex flex-col gap-3">
              {map(recentActivities, (vachana, index) => (
                <div key={index} className="flex flex-col gap-6">
                  {index ? <hr className="w-full" /> : null}
                  <VachanaList
                    key={index}
                    id={vachana.id}
                    content={vachana.transliterated.txt}
                    author={vachana.author.name}
                    totalVachanas={vachana.author.vachanas}
                    image={vachana.author.image || logo}
                    path={"/vachana"}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
