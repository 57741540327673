import React, { useState } from "react";
import GBLabel from "./GBLabel";
import { map } from "lodash";
import GBButton from "./GBButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

function TimeSelection({ handleBackClick, handleNextClick, handleFormData }) {
  const [selectedTime, setSelectedTime] = useState(10);
  const { t } = useTranslation();
  const commonContext = t("common");
  const handleClick = (value) => {
    handleFormData({ readingTime: value });
    setSelectedTime(value);
  };
  return (
    <div className=" flex flex-col justify-center sm:items-center gap-y-4">
      <div className="flex flex-col gap-2 sm:w-80">
        <GBLabel
          {...{
            label: `How much time would you like to read every day.`,
            styleLabel: "text-2xl font-medium font-denton w-full",
          }}
        />
      </div>
      <div className="flex flex-col gap-y-6 sm:w-80">
        {map([10, 15, 20, 30], (item, key) => (
          // <GBButton />
          <GBButton
            key={key}
            {...{
              title: `${item} min/day`,
              value: item,
              handleClick,
              isSelected: selectedTime === item,
              className: `text-sm px-[18px] py-[20px] rounded-xl shadow-md h-24 ${
                selectedTime !== item ? "bg-white" : ""
              }`,
            }}
          >
            {`${item} min/day`}
          </GBButton>
        ))}
      </div>
      <div className="flex w-full justify-between">
        <GBButton
          className="flex items-center justify-center text-Primary px-2 py-1  border border-Primary rounded-full gap-1 max-sm:w-[120px] w-[149px] h-[52px]"
          handleClick={handleBackClick}
        >
          <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
            <FaArrowRightLong className="mr-1 rotate-180" />{" "}
            {commonContext.back}
          </span>
        </GBButton>

        <GBButton
          className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full gap-1 w-[149px] h-[52px]"
          handleClick={handleNextClick}
        >
          <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
            {commonContext.next} <FaArrowRightLong className="ml-1" />
          </span>
        </GBButton>
      </div>
    </div>
  );
}

export default TimeSelection;
