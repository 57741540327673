import React from "react";
import { BackArrowIcon } from "../assets/icons/CustomIcons";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../store/UserStore";
import { IoIosArrowForward } from "react-icons/io";
import useCommonStore from "../store/CommonStore";

const Settings = () => {
  const { user, logout } = useAuthStore((state) => state);
  const { languages } = useCommonStore((state) => state);
  const navigate = useNavigate();
  const language = languages?.find(
    (lang) => lang.i18n === user?.primary_language
  );

  return (
    <div className="max-w-xl mx-auto flex items-center justify-center">
      <div className="w-full flex-col">
        <div className="flex items-center justify-start p-4 ">
          <button onClick={() => navigate(-1)} className="flex items-center">
            <BackArrowIcon />
            <span className="sr-only">Back</span>
          </button>
          <h1 className="pl-4 text-xl font-bold">Settings</h1>
        </div>
        <hr className="my-1 border-t-2 border-gray-700 dark:border-gray-300" />
        <div className="p-4 space-y-4">
          <h2 className="text-lg font-semibold">Language Preferences</h2>
          <div className="flex items-center justify-between">
            <p className="text-md">Primary Language</p>
            <button
              onClick={() => navigate("/languageChange")}
              className="flex items-center"
            >
              <span className="mr-2 border border-gray-400 rounded-md px-2">
                {language?.title || "Not set"}
              </span>
              <IoIosArrowForward />
            </button>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-md">Secondary Language</p>
            <button
              onClick={() => navigate("/languageChange")}
              className="flex items-center"
            >
              <div className="flex items-center gap-1">
                {user?.secondary_languages
                  ? user?.secondary_languages.map((lang) => (
                      <div
                        key={lang}
                        className="border border-gray-400 rounded-md px-2 py-0.5"
                      >
                        {languages?.find((l) => l.i18n === lang)?.title}
                      </div>
                    ))
                  : "Not set"}
              </div>
              <IoIosArrowForward />
            </button>
          </div>
          <hr className="my-4 border-t-2 border-gray-700 dark:border-gray-300" />
          <h2 className="text-lg font-semibold">Account</h2>

          <div className="flex items-center justify-between">
            <p className="text-md">Terms & Conditions</p>
            <IoIosArrowForward />
          </div>
          <div className="flex items-center justify-between" onClick={logout}>
            <p className="text-md text-[#FF0000]">Logout</p>
            <IoIosArrowForward />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
