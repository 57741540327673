import React, { useEffect, useState } from "react";
import VachanaList from "../components/VachanaList";
import logo from "../assets/images/png/Ellipse.png";
import { map } from "lodash";
import useAuthStore from "../store/UserStore";
import { getUserRecentActivities } from "../services/apis";

function Activity() {
  const [recentActivities, setRecentActivities] = useState([]);
  const { user, getUser } = useAuthStore();

  useEffect(() => {
    const fetchData = async () => {
      getUser(user.id);
      setRecentActivities(await getUserRecentActivities());
    };
    fetchData();
  }, [getUser, user.id]);

  return (
    <div className="flex flex-col gap-2 py-4">
      {map(recentActivities, (vachana, index) => (
        <div key={index} className="flex flex-col gap-6">
          {index ? <hr className="w-full " /> : null}
          <VachanaList
            key={index}
            id={vachana.id}
            content={vachana.transliterated.txt}
            author={vachana.author.name}
            totalVachanas={vachana.author.vachanas}
            image={vachana.author.image || logo}
            path={"/vachana"}
          />
        </div>
      ))}
    </div>
  );
}

export default Activity;
