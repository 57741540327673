import React from 'react'
import { useNavigate } from 'react-router-dom';
import { AddCircleIcon, BackArrowIcon } from '../assets/icons/CustomIcons';
import GBButton from '../components/GBButton';
import { get, map } from 'lodash';
import VachanaRightArrow from '../components/VachanaRightArrow';


function PlayList() {

    const data = [
        {
            title: 'Basavanna songs'
        },
        {
            title: 'Guru wisdom'
        }
    ]
    const navigate = useNavigate()
    const handleBack = () => {
        navigate(-1); // This navigates back to the previous page
      };

    return (
        <div className='flex flex-col gap-y-3 absolute sm:static bg-Surface h-full w-full p-8'>
          <div className='py-4 gap-4 flex flex-col w-full text-EerieBlack'> 
                <div onClick={handleBack} className='flex gap-1.5 w-6 h-6' >
                    <BackArrowIcon />
                </div>
                <div className='flex justify-between'>
                    <h1 className='text-2xl leading-[38px] font-denton font-medium'>{
                    // sharanasObj.A[0].author
                    'Your playlist'
                    }</h1>
                    <GBButton className='flex gap-1 items-center p-1.5 rounded-full bg-Primary text-sm tracking-[4%] text-Surface font-dmSans font-medium'>
                       <AddCircleIcon /> Create
                    </GBButton>
                </div>              
                                              
          </div>
          <div className='flex flex-col gap-y-4 w-full'>
              {
                map(data, (playList, index)=> (
                    <div className='flex justify-between bg-white p-6 rounded-2xl shadow-md'>
                        <h2 className='text-base leading-[18px] font-dmSans font-medium tracking-[-4%]'>{get(playList, 'title','')}</h2>
                        <VachanaRightArrow path='/#' id={playList.title} />
                    </div>
                ))
              }
          </div>
          
        </div>
      )
}

export default PlayList;