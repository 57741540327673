const footerData = {
  all: {
    fields: [
      {
        name: "home",
        link: "/home",
        icon: "home",
      },
      {
        name: "read",
        link: "",
        icon: "",
      },
      {
        name: "search",
        link: "/search",
        icon: "search",
      },
      {
        name: "vachanas",
        link: "/vachanas",
        icon: "vachanas",
      },
      {
        name: "sharanas",
        link: "/sharanas",
        icon: "sharanas",
      },
      {
        name: "listen",
        link: "",
        icon: "",
      },
      {
        name: "search",
        link: "/musicSearch",
        icon: "musicSearch",
      },
      {
        name: "music",
        link: "/music",
        icon: "music",
      },
      {
        name: "calm",
        link: "",
        icon: "",
      },
      {
        name: "meditation",
        link: "/meditation",
        icon: "meditation",
      },
      {
        name: "stats",
        link: "/stats",
        icon: "stats",
      },
      {
        name: "find",
        link: "",
        icon: "",
      },
      {
        name: "dictionary",
        link: "/dictionary",
        icon: "dictionary",
      },
    ],
  },
  home: {
    fields: [
      {
        name: "home",
        link: "/home",
        icon: "home",
      },
      {
        name: "vachanas",
        link: "/vachanas",
        icon: "vachanas",
      },
      {
        name: "meditation",
        link: "/meditation",
        icon: "meditation",
      },
      {
        name: "music",
        link: "/music",
        icon: "music",
      },
      {
        name: "dictionary",
        link: "/dictionary",
        icon: "dictionary",
      },
    ],
  },
  vachanas: {
    fields: [
      {
        name: "home",
        link: "/home",
        icon: "returnHome",
      },
      {
        name: "search",
        link: "/search",
        icon: "search",
      },
      {
        name: "vachanas",
        link: "/vachanas",
        icon: "vachanas",
      },
      {
        name: "sharanas",
        link: "/sharanas",
        icon: "sharanas",
      },
    ],
  },
  search: {
    fields: [
      {
        name: "home",
        link: "/home",
        icon: "returnHome",
      },
      {
        name: "search",
        link: "/search",
        icon: "search",
      },
      {
        name: "vachanas",
        link: "/vachanas",
        icon: "vachanas",
      },
      {
        name: "sharanas",
        link: "/sharanas",
        icon: "sharanas",
      },
    ],
  },
  sharanas: {
    fields: [
      {
        name: "home",
        link: "/home",
        icon: "returnHome",
      },
      {
        name: "search",
        link: "/search",
        icon: "search",
      },
      {
        name: "vachanas",
        link: "/vachanas",
        icon: "vachanas",
      },
      {
        name: "sharanas",
        link: "/sharanas",
        icon: "sharanas",
      },
    ],
  },
  music: {
    fields: [
      {
        name: "home",
        link: "/home",
        icon: "returnHome",
      },
      {
        name: "search",
        link: "/musicSearch",
        icon: "musicSearch",
      },
      {
        name: "music",
        link: "/music",
        icon: "music",
      },
    ],
  },
  meditation: {
    fields: [
      {
        name: "home",
        link: "/home",
        icon: "returnHome",
      },
      {
        name: "meditation",
        link: "/meditation",
        icon: "meditation",
      },
      {
        name: "stats",
        link: "/stats",
        icon: "stats",
      },
    ],
  },
};

export default footerData;
