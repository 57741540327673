import React, { useEffect, useState } from "react";
import logo from "../assets/images/png/Ellipse.png";
import VachanaList from "../components/VachanaList";
import TodayVachana from "../components/TodayVachana";
import GBSearch from "../components/GBSearch";
import { get, map } from "lodash";
import VachanaRightArrow from "../components/VachanaRightArrow";
import useAuthStore from "../store/UserStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { use } from "i18next";
import { getUserRecentActivities } from "../services/apis";
import useVachanasStore from "../store/VachanasStore";
import useSharanasStore from "../store/SharanasStore";

function Home() {
  const { user } = useAuthStore();
  const { t } = useTranslation();
  const commonContext = t("common", { returnObjects: true });
  const [recentActivities, setRecentActivities] = useState([]);
  const navigate = useNavigate();
  const language = useTranslation().i18n.language;
  const { resetSearchedVachanas, getRandomVachana, todayVachana } =
    useVachanasStore((state) => state);
  const { setSelectedFilterSharanas } = useSharanasStore((state) => state);

  const getRecentActivitiesList = async () => {
    try {
      getRandomVachana(todayVachana);
      setRecentActivities(await getUserRecentActivities(language, 5));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRecentActivitiesList();
    resetSearchedVachanas(); // Cleanup on unmount
    setSelectedFilterSharanas([]);
  }, [language]);

  return (
    <div className="flex px-4 max-sm:pb-20 flex-col lg:flex-row overflow-y-scroll scrollbar-hide h-screen sm:max-h-screen-3rem w-full">
      <div className="flex flex-col ">
        <div className="flex flex-col py-2  gap-6 home-bg sm:bg-right-80px bg-top bg-428px_488px sm:!bg-none">
          <h1 className="text-2xl font-denton font-medium">
            Welcome <span>{user?.name}</span>, Today's vachana read for you.
          </h1>
          <TodayVachana home={true} />
        </div>
        {/* <div className="sm:flex-col ">
          <div>
            <h2 className="font-denton text-xl pb-3 leading-[30px] font-medium text-EerieBlack">
              {commonContext.dictionary}
            </h2>
          </div>
          <div className="flex flex-col p-4 gap-5 bg-white rounded-2xl shadow-md">
            <p className="flex gap-1.5 font-dmSans font-medium text-sm tracking-[-4%] text-GrayishBrown">
              Quick search for meaning
            </p>
            <div
              onClick={() => {
                // navigate("/search");
              }}
            >
              <GBSearch
                inputStyle="p-3 rounded-full font-dmSans text-sm font-medium placeholder-dmSans"
                isIcon={false}
                placeholder="Enter here"
                className="border-2 rounded-full"
              />
            </div>
          </div>
        </div> */}
        <div className="flex flex-col">
          <h2 className="font-denton py-6 font-medium text-xl leading-[30px]">
            What can we help you to find
          </h2>
          <div className="flex gap-[14px] flex-wrap">
            {map(
              [
                {
                  title: commonContext.vachanas,
                  count: 20280,
                  toRef: '/vachanas'
                },
                {
                  title: commonContext.sharanas,
                  count: 224,
                  toRef: '/sharanas'
                },
                {
                  title: commonContext.music,
                  count: 72,
                  toRef: '/music'
                }
              ],
              (item, key) => (
                <div
                  key={key}
                  className="flex flex-col gap-1 p-[22px] pr-[auto] border-2 rounded-2xl sm:min-w-32"
                  onClick={()=> navigate(item.toRef)}
                >
                  {item.count ? (
                    <p className="font-dmSans font-bold text-xs tracking-[20%] text-GrayishBrown">
                      {item.count}+
                    </p>
                  ) : null}
                  <h3 className="font-denton font-medium text-base">
                    {item.title}
                  </h3>
                </div>
              )
            )}
            {/* <div
                  className="flex flex-col items-center gap-1 p-[22px] pr-[auto] border-2 rounded-2xl sm:min-w-32"
                >
                  <h3 className="font-denton font-medium text-base">
                    {commonContext.more}...
                  </h3>
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex lg:px-4 flex-col max-sm:pr-0 gap-6  py-6">
        <div className="flex gap-1  justify-between">
          <div className="flex flex-col gap-1">
            <h2 className="font-denton font-medium text-xl leading-[30px]">
              Recent Activities
            </h2>
            <p className="font-dmSans font-medium text-sm trachking-[-4%] text-GrayishBrown">
              Start reading where you left
            </p>
          </div>
          <div
            className="text-Primary font-dmSans font-medium cursor-pointer underline"
            onClick={() => navigate("/vachanas", { state: { selectedTab: 2 } })}
          >
            View all
          </div>
        </div>
        <div className="flex sm:flex-col gap-[10px] h-full overflow-x-auto max-sm:pr-9">
          {map(recentActivities, (activity, key) => (
            <div
              className="flex justify-between w-full border-2 rounded-xl p-4 relative"
              key={key}
            >
              <VachanaList
                key={key}
                {...{
                  id: activity.id,
                  content: activity.transliterated.txt,
                  author: activity.author.name,
                  totalVachanas: activity.author.vachanas,
                  image: activity.author.image || logo,
                  className:"max-sm:w-[250px]",
                }}
              />
              {/* <div className="flex justify-end mt-[40px]"> */}
                <VachanaRightArrow path="/vachana" className='absolute right-0 bottom-4' id={activity.id} />
              {/* </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
