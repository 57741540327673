import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/images/png/Ellipse.png";
import textBlockIcon from "../assets/images/svg/textBlockIcon.svg";
import {
  BackArrowIcon,
  BookmarkIcon,
  PlayIcon,
  PauseIcon,
  ArrowSquareLeftFillIcon,
  ArrowSquareRightFillIcon,
} from "../assets/icons/CustomIcons";
import BookmarkFilled from "../assets/icons/BookmarkFilled.png";
import GBShare from "../components/GBShare";
import bookIcon from "../assets/images/svg/bookIcon.svg";
import Drawer from "./Drawer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import useVachanasStore from "../store/VachanasStore";
import useAuthStore from "../store/UserStore";
import {
  addRecentActivitiesWithVachanaId,
  addToBookmark,
  getVachana,
  removeFromBookmark,
} from "../services/apis";

function Vachana() {
  const { id } = useParams();
  const language = useTranslation().i18n.language;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const swipeRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [drawerAction, setDrawerAction] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioInstance] = useState(new Audio());
  const { user } = useAuthStore((state) => state);
  const {
    getVachanaById,
    getSharanasVachanaById,
    textSize,
    setTextSize,
    selectedVachana,
    loading,
    bookMarkedVachanas,
    checkUserBlog,
  } = useVachanasStore((state) => state);
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [kannadaVachana, setKannadaVachana] = useState(selectedVachana);
  const commonContext = t("common", { returnObjects: true });
  const fontSizeClasses = {
    Small: "text-base",
    Medium: "text-xl",
    Large: "text-2xl",
  };
  const minSwipeDistance = 50;

  const navigate = useNavigate();
  const closeHandler = () => {
    setDrawerAction();
    setIsOpen((pre) => !pre);
  };

  const handleBack = () => {
    navigate(`/${queryParams.get("ref") || "vachanas"}`); // This navigates back to the previous page
  };

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const showHandler = (action) => {
    setDrawerAction(action);
    setIsOpen(true);
  };

  const fetchDefaultData = async () => {
    if (selectedVachana?.id) {
      try {
        const data = await getVachana(selectedVachana.id, "kn");
        setKannadaVachana(data);
      } catch (error) {
        console.error("Error fetching default data:", error);
      }
    }
  };

  useEffect(() => {
    const refValue = queryParams.get("ref") || "vachanas"; // Get the value of the "ref" parameter
    checkUserBlog(user?.id);
    addRecentActivitiesWithVachanaId(id);
    // console.log(refValue);

    if (refValue === "sharanas") {
      getSharanasVachanaById({ id });
    } else {
      getVachanaById({ id: id });
    }
  }, [language, location]);

  useEffect(() => {
    setTextSize({
      size: textSize.preSize,
    });
  }, [isOpen]);

  useEffect(() => {
    fetchDefaultData();
  }, [selectedVachana]);

  const handleAddBookmark = () => {
    addToBookmark(user?.id, id);
    checkUserBlog(user?.id);
  };
  const handleRemoveBookmark = () => {
    removeFromBookmark(user?.id, id);
    checkUserBlog(user?.id);
  };
  const handleNextVachana = async () => {
    setIsPlaying(false); // Reset play button state
    navigate(
      `/vachana/${get(selectedVachana, "next")}?ref=${queryParams.get("ref") || "vachanas"
      }&vachana=${get(selectedVachana, "vachanaNumber") + 1
      }&type=next&preId=${get(selectedVachana, "id")}`
    );
  };

  const handlePreviousVachana = async () => {
    setIsPlaying(false); // Reset play button state
    navigate(
      `/vachana/${get(selectedVachana, "previous")}?ref=${queryParams.get("ref") || "vachanas"
      }&vachana=${get(selectedVachana, "vachanaNumber") - 1}`
    );
  };

  useEffect(() => {
    if (kannadaVachana?.audioUrl) {
      audioInstance.src = kannadaVachana?.audioUrl;
    }
    return () => {
      audioInstance.pause();
      audioInstance.src = "";
    };
  }, [selectedVachana, kannadaVachana]);

  const handlePlay = () => {
    if (!kannadaVachana?.audioUrl) {
      alert("No audio available for this vachana");
      return;
    }

    if (isPlaying) {
      audioInstance.pause();
      setIsPlaying(false);
    } else {
      audioInstance.play();
      setIsPlaying(true);
    }
  };

  const handleWheel = (e) => {
    if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
      if (e.deltaX > minSwipeDistance && get(selectedVachana, "next")) {
        handleNextVachana();
      } else if (
        e.deltaX < -minSwipeDistance &&
        get(selectedVachana, "previous")
      ) {
        handlePreviousVachana();
      }
    }
  };
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe && get(selectedVachana, "next")) {
      handleNextVachana();
    }
    if (isRightSwipe && get(selectedVachana, "previous")) {
      handlePreviousVachana();
    }
  };

  useEffect(() => {
    const swipeElement = swipeRef.current;
    if (swipeElement) {
      swipeElement.addEventListener("wheel", handleWheel, { passive: true });
    }
    return () => {
      if (swipeElement) {
        swipeElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, [get(selectedVachana, "id")]);
  // Handle audio end
  useEffect(() => {
    const handleAudioEnd = () => {
      setIsPlaying(false);
    };
    audioInstance.addEventListener("ended", handleAudioEnd);
    return () => {
      audioInstance.removeEventListener("ended", handleAudioEnd);
    };
  }, [audioInstance]);

  return (
    <div className="flex px-6 justify-center h-screen-3rem">
      <div
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
        ref={swipeRef}
        className="relative w-full sm:max-w-xl flex flex-col py-4"
      >
        <div className="flex flex-col">
          <div className="flex justify-between items-center max-sm:gap-2.5 max-sm:pt-3.5 max-sm:pb-6">
            <div className="flex gap-x-2 sm:gap-x-3 items-center">
              <div className="flex gap-1.5" onClick={handleBack}>
                <BackArrowIcon />
              </div>
              {loading ? (
                <div className="flex flex-grow"></div>
              ) : (
                <div className={`flex gap-4 ${fontSizeClasses[textSize.size]} items-center`}>
                  <div className="sm:w-[42px] w-9 sm:h-[42px] h-9">
                    <img
                      src={get(selectedVachana, "author.image", "") || logo}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h2 className=" font-denton">
                      {get(selectedVachana, "author.name", "")}
                    </h2>
                    <p className="font-dmSans text-xs">
                      {get(selectedVachana, "author.vachanas", "")}{" "}
                      <span>{get(commonContext, "vachanas", "")}</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div
              className="flex gap-x-[21px]"
              onClick={() => showHandler("FONTSIZE")}
            >
              <img src={textBlockIcon} alt="textBlock" />
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex gap-1.5 sm:py-4 sm:border-y-2 font-denton justify-between items-center">
              <div className={`flex gap-1.5 text-GoldenBrown  text-[14px]`}>
                <h2 className=" font-medium pr-4">
                  {/* {get(selectedVachana, "vachanaNumber")} */}
                  Volume: {get(selectedVachana, "booksRef.0.within.volume")} - 
                  {get(selectedVachana, "booksRef.0.within.vachanaNumber")}
                  <span className="text-[10px] absolute p-1">th</span>
                </h2>
                <span>
                  {get(commonContext, "vachana", "")}
                </span>
              </div>
              <div className="flex gap-[18px] items-center">
                <div onClick={handlePlay} className="cursor-pointer">
                  {isPlaying ? (
                    <PauseIcon className="text-Primary" />
                  ) : (
                    <PlayIcon />
                  )}
                </div>
                <div className="cursor-pointer">
                  {bookMarkedVachanas.includes(get(selectedVachana, "id")) ? (
                    <img
                      onClick={handleRemoveBookmark}
                      src={BookmarkFilled}
                      alt="bookmarkIcon"
                      className="w-[25px] h-[25px]"
                    />
                  ) : (
                    <div onClick={handleAddBookmark}>
                      <BookmarkIcon />
                    </div>
                  )}
                </div>
                <div>
                  <GBShare />
                </div>
              </div>
            </div>
            {loading ? (
              <div className="flex flex-grow justify-center h-[50vh] items-center">
                {" "}
                Loading ...
              </div>
            ) : (
              <div className="flex flex-col gap-6">
                <div
                  className={`font-dmSans ${fontSizeClasses[textSize.size]
                    } max-h-[40vh] overflow-y-scroll scrollbar-hide`}
                >
                  {get(selectedVachana, "transliterated.txt", "")}
                </div>
                <div className="flex gap-y-2.5 gap-x-1.5 items-center">
                  <div className="w-[20px] h-[20px]">
                    <img src={bookIcon} alt="bookIcon" />
                  </div>
                  <div className="font-dmSans items-center">
                    <p>
                      {commonContext.referedBook} :{" "}
                      <span>{"Samagra Vachana Samputa"}</span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex absolute bottom-4 max-sm:flex-col max-sm:items-center gap-x-44 gap-4 sm:fixed sm:bottom-10 max-sm:mt-[42px] sm:justify-between sm:max-w-xl w-full">
          <div className="flex gap-x-3 items-center">
            {
              <div
                className={`w-[35px] h-[35px] ${!get(selectedVachana, "previous")
                    ? "pointer-events-none opacity-50"
                    : ""
                  }`}
                onClick={handlePreviousVachana}
              >
                <ArrowSquareLeftFillIcon className="fill-EerieBlack " />
              </div>
            }
            {/* <div className="text-xs font-medium flex font-dmSans">
              <p>
                <span>{get(selectedVachana, "vachanaNumber")}</span>
                &nbsp;{commonContext.of}&nbsp;
                <span>{get(selectedVachana, "totalVachanas")}</span>
              </p>
            </div> */}
            <div
              className={`w-[35px] h-[35px] ${!get(selectedVachana, "next")
                  ? "pointer-events-none opacity-50"
                  : ""
                }`}
              onClick={handleNextVachana}
            >
              <ArrowSquareRightFillIcon className="fill-EerieBlack" />
            </div>
          </div>
          <div className="sm:hidden font-dmSans text-xs">
            {commonContext.swipeNext}
          </div>
          <div className="flex gap-x-2.5">
            <div className="flex gap-x-1.5 px-[22px] py-[18px]  bg-Primary rounded-full text-white">
              <button
                onClick={() => showHandler("LANGUAGETOOL")}
                className={`font-dmSans ${fontSizeClasses[textSize.size]
                  } font-medium`}
              >
                {commonContext.tryAIAnalysis}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        isOpen={isOpen}
        closeHandler={closeHandler}
        drawerAction={drawerAction}
        kannadaVachana={kannadaVachana}
      />
    </div>
  );
}

export default Vachana;
