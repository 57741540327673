import React, { useState, useEffect, useRef, act } from "react";
import { useTranslation } from "react-i18next";
import useAudioStore from "../store/audioStore";
import logo1 from "../assets/images/svg/logo1.svg";
import pause from "../assets/images/svg/pause.svg";
import Playback from "../assets/images/svg/Playback.svg";
import CustomMusicPlayer from "../components/CustomMusicPlayer";
import { db } from "../Firebase"; // Import your Firebase configuration
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
} from "firebase/firestore";

function Music() {
  const {
    setCurrentAudio,
    setIsPlaying,
    setSongList
  } = useAudioStore();
  const scrollRef = useRef(null);
  const [records, setRecords] = useState([]);
  // console.log(records, 'overall records');
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const isPlaying = useAudioStore((state) => state.isPlaying);
  const playbackAudio = useAudioStore((state) => state.currentAudio);
  const [activeTab, setActiveTab] = useState("vachana");

  const handleSongClick = (item) => {
    if (playbackAudio?.id === item.id) {
      setCurrentAudio(item);
      setIsPlaying(!isPlaying);
    } else {
      setCurrentAudio(item);
      setIsPlaying(true);
    }
  };

  const handleTabClick = async (tabName) => {
    setActiveTab(tabName);
    fetchRecords(true, tabName);
  };

  const fetchRecords = async (isInitialLoad = false, tab = activeTab) => {
    // console.log("Fetching records...", tab);
    if (isInitialLoad) setRecords([]);
    try {
        const recordsRef = collection(db, "albums");
        const recordsQuery = isInitialLoad
            ? query(recordsRef, where("type", "array-contains", tab), limit(2))
            : query(
                recordsRef,
                where("type", "array-contains", tab),
                startAfter(lastVisible),
                limit(5)
            );

        const snapshot = await getDocs(recordsQuery);
        const newRecords = [];
        for (const doc of snapshot.docs) {
            const audioRef = collection(db, `albums/${doc.id}/audios`);
            const audioSnapshot = await getDocs(audioRef);
            const audios = audioSnapshot.docs.map((audioDoc) => ({
                id: audioDoc.id,
                ...audioDoc.data(),
            }));
            newRecords.push(...audios);
        }
      // console.log("Fetching records...", tab);
      // console.log("New records:", newRecords);
        setRecords((prev) => [...prev, ...newRecords]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setSongList(newRecords);
    } catch (error) {
        console.error("Error fetching records:", error);
    }

    setLoading(false);
};

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const handleScroll = debounce(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("Reached the end of the list");
        fetchRecords();
      }
    }
  });
  useEffect(() => {
    fetchRecords(true);
  }, []);

  return (
    <div className="relative h-screen bg-Surface">
      <div className="px-4 sm:px-8 pt-3.5 pb-3.5 w-full sticky top-0 z-20 bg-Surface">
        <div className="text-xl sm:text-2xl leading-[38px] flex justify-between items-center font-denton font-medium">
          <span>Music</span>
        </div>
      </div>

      <div className="ps-4 sm:ps-8 pt-0 sticky max-[768px]:top-[59px] max-[425px]:top-[66px] z-20 bg-Surface border-b-2">
        <ol className="flex gap-4 sm:gap-8">
          {[
            { id: "vachana", name: "Vachanas" },
            { id: "devotional", name: "Devotional" },
            { id: "pravachana", name: "Pravachanas" },
          ].map((tab) => (
            <li
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={`py-2 sm:py-4 cursor-pointer ${activeTab === tab.id
                ? "text-[#0B6062] border-b-2 border-[#0B6062]"
                : "text-black"
                }`}
            >
              {tab.name}
            </li>
          ))}
        </ol>
      </div>

      <div
        ref={scrollRef}
        onScroll={handleScroll}
        className="px-4 sm:px-8 bottom-4 pb-[140px] md:pb-[76px] lg:w-[496px] h-[calc(100%-120px)] overflow-auto scrollbar-hide"
      >
        {records.map((item, index) => (
          <div
            key={`${item.id}-${index}`}
            className={`flex items-center py-4 border-b border-gray-200 ${playbackAudio?.id === item.id ? "bg-gray-100 rounded-lg" : ""
              }`}
              onClick={() => handleSongClick(item)}
          >
            <img
              src={item.albumArt || logo1}
              alt={item.albumName}
              className="w-16 h-16 sm:w-20 sm:h-20 object-cover rounded-md mr-4"
            />
            <div className="flex-1">
              <p className="text-sm sm:text-base text-gray-700">{item.name}</p>
              <p className="text-xs text-gray-500">{item.albumName}</p>
              <p className="text-sm mt-1">{item.artist}</p>
            </div>
            <button
              onClick={() => handleSongClick(item)}
              className="w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center bg-[#0B6062] rounded-full"
            >
              <img
                src={
                  playbackAudio?.id === item.id && isPlaying ? pause : Playback
                }
                alt="Play/Pause"
                className="w-[2rem] h-[2rem] sm:w-[2rem] sm:h-[2rem]"
              />
            </button>
          </div>
        ))}
        {loading && <p>Loading...</p>}
      </div>
    </div>
  );
}

export default Music;
