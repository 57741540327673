import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackArrowIcon } from '../assets/icons/CustomIcons';
import logo1 from '../assets/images/svg/logo1.svg';
const ComingSoon = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className='flex flex-col w-full h-screen'>
            <div onClick={handleBack} className='hidden max-sm:block gap-1.5 h-16 items-center cursor-pointer p-4 shadow-sm'>
                <BackArrowIcon />
            </div>
            <div className='flex flex-col flex-1 items-center justify-center gap-2'>
                <img src={logo1} alt="Logo" className='h-[187px] pointer-events-none' />
                <h2 className='font-denton text-[26px] font-semibold text-[#0B6062] tracking-wider'>COMING SOON ...</h2>
            </div>
        </div>
    );
};

export default ComingSoon;