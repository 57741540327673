import React, { useEffect, useRef } from "react";
import useVachanasStore from "../store/VachanasStore";

function GBScrollElement({ handleScroll, isCompleted=false, children }) {
  const lastElementRef = useRef(null);
  const { vachanas } = useVachanasStore((state) => state);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('handle scroll');
            handleScroll();
          }
        });
      },
      { threshold: 1.0 } // Trigger when the element is fully visible
    );

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [vachanas]); // Added sharanas and vachanas

  return (
    <div>
      {children}
      {isCompleted? null :<div ref={lastElementRef} className="flex justify-center mb-10">
        loading...
      </div>}
    </div>
  );
}

export default GBScrollElement;
