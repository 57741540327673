import React, { useState } from "react";
import GBLabel from "./GBLabel";
import LanguageSection from "./LanguageSection";
import { useTranslation } from "react-i18next";
import GBButton from "./GBButton";
import { FaArrowRightLong } from "react-icons/fa6";

function SecondaryLanguage({
  handleBackClick,
  handleNextClick,
  handleFormData,
}) {
  const maxNumberOfLanguages = 5;
  const [selectedLangArr, setSelectedLangArr] = useState([]);
  const [errorText, setErrorText] = useState("");

  const { t } = useTranslation();
  const context = t("secondaryLanguage");
  const commonContext = t("common");

  const handleSecondaryLanguage = (value) => {
    if (selectedLangArr.includes(value)) {
      setSelectedLangArr((preState) =>
        preState.filter((lang) => lang !== value)
      );
    } else if (selectedLangArr.length < maxNumberOfLanguages) {
      setSelectedLangArr([...selectedLangArr, value]);
      setErrorText("");
    } else if (selectedLangArr.length === 0) {
      setErrorText("At least one language should be selected");
    } else {
      setErrorText(`You can select only ${maxNumberOfLanguages} languages`);
    }
  };

  const handleClick = () => {
    if (selectedLangArr.length === 0) {
      setErrorText("At least one language should be selected");
    } else {
      handleNextClick();
      handleFormData({ secondary_languages: selectedLangArr });
    }
  };
  return (
    <>
      <div className="flex flex-col gap-2">
        <GBLabel
          {...{
            label: context.title,
            desc: context.description,
            styleLabel:
              "text-[24px] font-normal font-denton leading-[36px]",
            styleDesc:
              "font-dmSans text-[14px] leading-[21px] text-GrayishBrown w-4/5",
          }}
        />
      </div>
      <div>
        <LanguageSection
          selectedLang={selectedLangArr}
          handleFun={handleSecondaryLanguage}
        />
      </div>
      {errorText && <div className="error text-Error px-2">{errorText}</div>}
      <div className="flex w-full justify-between">
        <GBButton
          className="flex items-center justify-center text-Primary px-2 py-1 rounded-full gap-1 h-[52px]"
          handleClick={handleBackClick}
        >
          <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
            <FaArrowRightLong className="mr-1 rotate-180" />{" "}
            {commonContext.back}
          </span>
        </GBButton>

        <GBButton
          className="flex items-center justify-center text-white bg-Primary px-2 py-1 rounded-full gap-1 w-[149px] h-[52px]"
          handleClick={handleClick}
          disabled={selectedLangArr.length === 0}
        >
          <span className="flex items-center gap-1 font-dmSans text-[14px] font-medium leading-[20px]">
            {commonContext.next} <FaArrowRightLong className="ml-1" />
          </span>
        </GBButton>
      </div>
    </>
  );
}

export default SecondaryLanguage;
