import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const LineChart = ({ className = '', dayData = [
    2, 3, 4, 5, 4, 5, 6, 7, 9, 10,
    9, 10, 11, 13, 15, 13.5, 14, 13, 9, 10,
    9, 11, 13, 15, 16, 17, 16, 14, 11, 12
  ]}) => {
  // X-axis labels for 30 days
  const labels = Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`);

  // Tailwind CSS success color
  const successColor = '#30B73D';

  // Create gradient
  const createGradient = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, `${successColor}75`);
    gradient.addColorStop(1, `${successColor}00`);

    return gradient;
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Hours Per Day (0-24)',
        data: dayData,
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          return createGradient(ctx);
        },
        borderWidth: 1, // Set line thickness to 1px
        borderColor: successColor,
        tension: 0.4, // Smoother curve
        pointRadius: 0, // Hide points
        pointHoverRadius: 5, // Size of point on hover
        pointHoverBackgroundColor: successColor, // Color of point on hover
        pointHoverBorderColor: `${successColor}cc`, // Border color of point on hover
        pointHoverBorderWidth: 2, // Border width of point on hover
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Disable maintaining aspect ratio
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: true,
        intersect: false,
        mode: 'nearest', // Focus on the nearest point
        callbacks: {
          label: (tooltipItem) => {
            return `Hours: ${tooltipItem.raw}`;
          },
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
    scales: {
      x: {
        display: false, // Hide the X-axis
      },
      y: {
        display: false, // Hide the Y-axis
        min: 0,
        max: 24,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className={className}  style={{ height: '333px', width: '974px' }}>
      <Line className='rounded-3xl' data={data} options={options} />
    </div>
  );
};

export default LineChart;
