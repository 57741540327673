// src/useAuthStore.js
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { signOut } from "firebase/auth";
import { auth, db } from "../Firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import useVachanasStore from "./VachanasStore";
import useCommonStore from "./CommonStore";
import useSharanasStore from "./SharanasStore";
import { toast } from "react-toastify";

const useAuthStore = create(
  persist(
    (set) => ({
      user: null,
      error: null,
      loading: false,
      isAuthenticated: false,
      getUser: async (uid) => {
        try {
          set({ loading: true });
          const userDoc = await getDoc(doc(db, "users", uid));
          if (!userDoc.exists()) {
            throw new Error(
              `User with uid ${uid} doesn't exists in users collection`
            );
          }
          set({
            user: { ...userDoc.data(), id: userDoc.id },
            error: null,
            loading: false,
            isAuthenticated: true,
          });
        } catch (error) {
          set({ error: error.message, loading: false });
          return error;
        }
      },
      createUser: async (data) => {
        const uid = auth.currentUser ? auth.currentUser.uid : null;
        try {
          set({ loading: true });
          const userRef = doc(db, "users", uid);
          await setDoc(
            userRef,
            {
              ...data,
              createdAt: Date.now(),
              phoneNumber: auth?.currentUser?.providerData[0]?.phoneNumber,
            },
            { merge: true }
          );
          const userDoc = await getDoc(userRef);
          set({
            user: { ...userDoc.data(), id: userDoc.id },
            error: null,
            loading: false,
            isAuthenticated: true,
          });
          toast.success("User created successfully");
        } catch (error) {
          set({ error: error.message, loading: false });
        }
      },
      updateUser: async (uid, data) => {
        console.log(uid, auth?.currentUser?.providerData[0]?.phoneNumber);
        try {
          set({ loading: true });
          const userDocRef = doc(db, "users", uid);
          await updateDoc(
            userDocRef,
            { ...data, updatedAt: Date.now() },
            { merge: true }
          );
          const updatedUserDoc = await getDoc(userDocRef);
          set({
            user: { ...updatedUserDoc.data(), id: updatedUserDoc.id },
            error: null,
            loading: false,
          });
          toast.success("User details updated successfully");
        } catch (error) {
          set({ error: error.message, loading: false });
        }
      },
      logout: async () => {
        try {
          set({ loading: true });
          await signOut(auth);
          set({
            user: null,
            error: null,
            loading: false,
            isAuthenticated: false,
          });
          window.location.href = "/";
          useVachanasStore.getState().reset();
          useCommonStore.getState().reset();
          useSharanasStore.getState().reset();
        } catch (error) {
          set({ error: error.message, loading: false });
        }
      },
    }),
    {
      name: "auth-storage", // unique name for the storage
      getStorage: () => localStorage, // use localStorage for persistence
    }
  )
);

export default useAuthStore;
