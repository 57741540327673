import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { getVachana } from "../services/apis";
import useVachanasStore from "../store/VachanasStore";
import langauges from "../assets/locales/languages.json";

function LanguageTool({ isDrawerExpand = false, defaultLang, kannadaVachana }) {
  const [selectedTab, setSelectedTab] = useState("translation");
  const [discription, setDiscription] = useState({});
  const { selectedVachana,textSize } = useVachanasStore((state) => state);
  const fontSizeClasses = {
    Small: "text-base",
    Medium: "text-xl",
    Large: "text-2xl",
  };
  const tabs = Object.keys(discription)
    .filter((key) => {
      if (key === "transliterated" && discription?.i18n !== "kn") return true;
      if (key === "translated") return true;
      if (key === "dictionary") return true;
      if (key === "explanation") return true;
      if (key === "i18n" && discription?.i18n !== "kn") return true;
      return false;
    })
    .sort((a, b) => {
      const order = {
        transliterated: 1,
        translated: 0,
        dictionary: 2,
        explanation: 3,
        i18n: 4,
      };
      return order[a] - order[b];
    })
    .map((key) => {
      switch (key) {
        case "transliterated":
          return "transliteration";
        case "translated":
          return "translation";
        case "dictionary":
          return "dictionary";
        case "explanation":
          return "explanation";
        case "i18n":
          return "kannada";
      }
    });

  const getSelectedTab = () => {
    switch (selectedTab) {
      case "transliteration":
        return discription.transliterated?.txt;
      case "translation":
        return discription?.translated?.txt;
      case "dictionary":
        if (typeof discription.dictionary === "string") {
          return discription.dictionary
            .split("\n")
            .filter((line) => line.trim() !== "")
            .map((line, index) => {
              if (line.startsWith("-")) {
                return (
                  <div key={index} className="ml-4">
                    <span className="font-semibold">{line.split(":")[0]}</span>:
                    {line.split(":")[1]}
                  </div>
                );
              }
              return (
                <div key={index}>
                  <span className="font-semibold">{line.split(":")[0]}</span>:
                  {line.split(":")[1]}
                </div>
              );
            });
        }
        return discription.dictionary;
      case "explanation":
        return discription.explanation;
      case "kannada":
        return kannadaVachana.transliterated?.txt;
      default:
        return "";
    }
  };

  useEffect(() => {
    const fetchVachana = async () => {
      setDiscription(
        (await getVachana(selectedVachana?.id, defaultLang)) || {}
      );
      setSelectedTab("translation");
    };
    fetchVachana();
  }, [defaultLang]);

  return (
    <div>
      <ul className="flex ">
        {map(tabs, (txt, index) => (
          <div
            key={index}
            className={`border-b-2 px-2 pb-1 w-full text-center ${
              txt === selectedTab
                ? "border-b-2 border-Primary text-Primary"
                : ""
            }`}
          >
            <li
              onClick={() => setSelectedTab(txt)}
              className={`flex gap-y-6 pt-[7px] pb-[5px] justify-center`}
            >
              <p className={`flex gap-x-[17px] font-dmSans font-normal`}>
                {langauges[defaultLang].translation.common[txt]}
              </p>
            </li>
          </div>
        ))}
      </ul>
      <div
        className={`flex flex-col whitespace-wrap overflow-y-scroll sm:max-h-[70vh] ${
          isDrawerExpand ? "max-sm:h-[58vh]" : "max-sm:h-[28vh]"
        }  scrollbar-hide ${fontSizeClasses[textSize.size]} mt-[22px]`}
      >
        {getSelectedTab()}
      </div>
    </div>
  );
}

export default LanguageTool;
