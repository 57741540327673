import { create } from "zustand";

const useAudioStore = create((set, get) => ({
  albums: [],
  audios: [],
  songList: [],
  currentAudio: null,
  currentTime: 0,
  isPlaying: false,
  error: null,
  loading: false,

  setCurrentAudio: (audio) => set({ currentAudio: audio }),
  setCurrentTime: (time) => set({ currentTime: time }),
  setIsPlaying: (status) => set({ isPlaying: status }),
  setSongList: (list) => set({ songList: list }),
  updateAudioState: (audio, time, playing) => {
    set({
      currentAudio: audio,
      currentTime: time,
      isPlaying: playing
    });
  },

  reset: () => set({
    albums: [],
    audios: [],
    songList: [],
    currentAudio: null,
    currentTime: 0,
    isPlaying: false,
    error: null,
    loading: false
  })
}));

export default useAudioStore;
