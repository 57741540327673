import React, { useEffect } from "react";
import logo from "../assets/images/png/Ellipse.png";
import { useTranslation } from "react-i18next";
import useVachanasStore from "../store/VachanasStore";
import { useNavigate } from "react-router-dom";
import { use } from "i18next";
import useAuthStore from "../store/UserStore";

function TodayVachana({ home }) {
  const { todayVachana, getAllVachanas, getRandomVachana } = useVachanasStore(
    (state) => state
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVachanas = () => {
      // getRandomVachana(todayVachana);
      getAllVachanas();
    };

    fetchVachanas();
  }, []);
  const commonContext = t("common", { returnObjects: true });

  return (
    <div
      className={`flex flex-col gap-4 ${
        home ? "bg-white rounded-2xl p-5 shadow-md" : "py-6"
      }`}
      onClick={() => home && navigate(`/vachana/${todayVachana.id}`)}
    >
      <div className="flex items-center gap-2">
        <img
          src={todayVachana?.author?.image || logo}
          alt={todayVachana?.author?.name}
          className="w-9 h-9 bg-Surface rounded-full"
        />
        <ul className="flex flex-col gap-2">
          <li className="flex justify-center items-center gap-x-2">
            <h3 className="font-dmSans font-medium text-base leading-[18px]">
              {todayVachana?.author?.name}
            </h3>
          </li>
          <li className="font-dmSans font-medium text-xs min-w-24 text-GrayishBrown">
            <p>
              {`${todayVachana?.author?.vachanas}th ${commonContext.vachanas}`}{" "}
            </p>
          </li>
        </ul>
      </div>
      <div>
        <div className={`${home ? "line-clamp-5" : ""}`}>
          {todayVachana?.transliterated?.txt}
        </div>
      </div>
    </div>
  );
}

export default TodayVachana;
