import React from 'react'

function Music24x7() {
  return (
    <div>
      Music 24x7
    </div>
  )
}

export default Music24x7
