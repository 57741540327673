import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useVachanasStore from '../store/VachanasStore';
import VachanaRightArrow from "./VachanaRightArrow";

function VachanaList({
  path = "/vachana",
  id = "",
  title = "",
  content = "",
  author = "",
  totalVachanas = 0,
  image = "",
  className = "lg:w-[500px] w-[3/4]",
  toRefer= "vachanas",
  children,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { textSize, setTextSize } = useVachanasStore((state) => state);
  const commonContext = t("common");
  const fontSizeClasses = {
    Small: "text-base",
    Medium: "text-xl",
    Large: "text-2xl",
  };
  return (
    <div className={`flex ${className}`}>
      <div
        onClick={() => {
          navigate(`${path}/${id}?ref=${toRefer}`);
        }}
        className="flex flex-col gap-3 w-full max-sm:w-full cursor-pointer"
      >
        <div className={`line-clamp-3 font-dmSans ${fontSizeClasses[textSize.size]}`}>{content}</div>
        <div className="flex items-center justify-between">
          <ul className="flex flex-row items-center gap-2">
            <li className="flex justify-center items-center gap-x-2">
              <img
                src={image}
                alt={author}
                className="w-6 h-6 bg-Surface rounded-full"
              />
              <h3 className="font-dmSans font-medium text-sm">{author}</h3>
            </li>
            <li className="font-dmSans font-medium text-sm min-w-24">
              {`${totalVachanas} ${commonContext.vachanas}`}{" "}
            </li>
          </ul>
          {/* <VachanaRightArrow path="/vachana" id={id} /> */}
        </div>
      </div>
      {children}
    </div>
  );
}

export default VachanaList;
