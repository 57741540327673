import React from 'react';
// import logo from '../assets/images/svg/logo.svg'
import logo from '../assets/images/png/logo.png'

function Landing() {
  return (
    <div className='flex justify-center items-center h-screen backGround'>
        <div className='flex flex-col items-center text-center h-[50vh] w-[75vw] p-2 gap-8'>
            <div >
                <img src={logo} className="h-[187px] pointer-events-none" alt="logo" />
                
            </div>
            <div className='flex flex-col gap-3.5'>
              <h1 className='font-sans text-sm font-bold leading-[18px] tracking-[3px] font-dmSans'>GURU BASAVA</h1>
              <h2 className='font-sans text-[22px] font-medium leading-[33px] font-denton' >Your gateway to daily wisdom, endless inspiration.</h2>

            </div>
            
        </div>
        
    </div>
  )
}

export default Landing;
